import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProvidersComponent } from './componentes/providers/providers.component';
import {ClientesComponent} from './componentes/clientes/clientes.component';
import { NovedadComponent } from './componentes/novedad/novedad.component';
import { UploadRutComponent } from './componentes/upload-rut/upload-rut.component';

const routes: Routes = [
  {
  	path:'upload/rut/:empresa/:id',
  	component:UploadRutComponent
  },
  {
  	path:'proveedores/:id/:empresa',
  	component:ProvidersComponent
  },
  {
  	path:'proveedores/:id/:empresa/:procedimiento',
  	component:ProvidersComponent
  },
  {
  	path:'clientes/:id/:empresa',
  	component:ClientesComponent
  },
  {
  	path:'clientes/:id/:empresa/:procedimiento',
  	component:ClientesComponent
  },

  {
  	path:'novedades/:id/:creado_por/:empresa',
  	component:NovedadComponent
  },
  {
  	path:'novedades/:id/:creado_por/:empresa/:procedimiento',
  	component:NovedadComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
