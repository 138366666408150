import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UploadRutService } from 'src/app/Servicios/provider/upload-rut.service';

@Component({
  selector: 'app-upload-rut',
  templateUrl: './upload-rut.component.html',
  styleUrls: ['./upload-rut.component.css']
})
export class UploadRutComponent implements OnInit {

  public archivos:any = [];
  public tributary_number:string = '';
  public id: any;

  constructor(private route: ActivatedRoute, private uploadRutService: UploadRutService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    console.log(this.id);
  }

  getFile(event:any){
    let claves = Object.values(event.target.files)
    for(let i = 0; i < claves.length; i++){
        let file = claves[i];
        this.archivos.push(file)
    }
  }

  sendFile(){
    console.log("archivos cargados", this.archivos);
    if (this.archivos.length == 0) {
      alert('Por favor seleccione un documento');
      return false;
    }

    if(this.archivos.length >= 1){

      const body = new FormData();
      this.archivos.forEach((archivo: any) => {
      body.append( "file",archivo)
      });
      body.append( "id", String(this.id))
      this.uploadRutService.guardarRut(body).subscribe(
        async (res: any) => {
          alert(res.message);
          console.log(res);
        },
        (error: any) => {
          alert(error.error.message);
          console.log(error);
          //this.toastFireError(error);
        }
      );

    }
  }
}
