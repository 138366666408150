<div class="card text-center">
  <div class="card-header">
    <h5 style="color: blue;">Adjuntar Registro Unico Tributario RUT</h5>
  </div>
  <div class="card-body">
    <p class="card-text">Asegurese de que el documento que suba sea en </p>
    <h4 style="color: blue;">formato .pdf</h4>
    <img src="../../../assets/img/pdf_icon.png" alt="" width="30" height="30">
    <div class="card-body">
        <input type="file" (change)="getFile($event)" id="files" name="files" accept=".pdf">
        <p *ngIf="archivos.length == 0" class="card-text"></p>
    </div>
      <button (click)="(sendFile())" type="button" class="btn-info" >Guardar PDF</button>
  </div>
  <div class="card-footer text-muted">
  </div>
</div>
