import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Clientes} from '../models/clientes';
import { global } from './global';


@Injectable()
export class ClientesService{
	public url: string;

	constructor(
	  	public _http: HttpClient
	){
		this.url = global.url;
	}

	create(Clientes, tipo_guardado ='registro',empresa ='brm',cod_pais,procedimiento,socios): Observable<any>{
		let json = JSON.stringify(Clientes);
		let sociosP = JSON.stringify(socios);
		let params = 'json='+json+'&tipo_guardado='+tipo_guardado+'&empresa='+empresa+'&cod_pais='+cod_pais+'&procedimiento='+procedimiento+'&socios='+sociosP;
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.post(this.url+'account',params, {headers: headers});
	}

	getListas(tipo,empresa): Observable<any>{
		//todos los listados los toma de BRM
      	empresa='brm';
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get(this.url+'account/listas/'+tipo+'/'+empresa, {headers: headers});
	}

	getLocations(modo): Observable<any[]>{
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get<any[]>(this.url+'account/cities/'+modo, {headers: headers});
    }
    getAccounts(id,empresa):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
      return this._http.get(this.url+'accountOne/'+id+'/'+empresa, {headers: headers});
    }

	subirDocumento(datos:any,nit,empresa):Observable<any>{
	   return this._http.post(this.url+'account/upload/'+nit+'/'+empresa, datos);
  	}

	validarCliente(nit, dig_verificacion,empresa): Observable<any>{
		let tributary_number_c = nit+'-'+dig_verificacion;
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get(this.url+'account/validar/'+tributary_number_c+'/'+empresa, {headers: headers});
  	}

 	obtenerUrlTratamientoDatos(empresa){
    	let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get(`${this.url}account/tratamientodatos/${empresa}`, {headers: headers});
    }

  	validarCodigoVerificacion(codigo_verificacion,empresa): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get(this.url+'account/validarCodigo/'+codigo_verificacion+'/'+empresa, {headers: headers});
	}

	eliminarSocio(socioID,empresa):Observable<any>{
		let params = 'socio_id='+socioID;
	   return this._http.post(this.url+'account/deleteS/'+socioID+'/'+empresa, params);
  	}

}



