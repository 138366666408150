export class Clientes{
  constructor(
    public id: string,
    public registro_actualizacion:string,
    public name:string,
    public billing_address_street:string,
    public billing_country_id:string,
    public billing_city_id:string,
    public phone_office:string,
    public phone_alternate:string,
    public oc_factura:string,
    public tributary_id_c:string,
    public tributary_number_c:number,
    public person_type_c:string,
    public regime_type_c:string,
    public autoholder_revenue_c:string,
    public autoholder_iva_c:string,
    public closed_reception_invoices_c:string,
    public rut_doc:string,
    public camara_comercio_doc:string,
    public nombres_localizacion:string,
    public email_contacto_pagaduria:string,
    public gran_contribuyente:string,
    public gran_contribuyente_ica:string,
    public conflicto_interes:string,
    public primer_nombre_rl:string,
    public segundo_nombre_rl:string,
    public primer_apellido_rl:string,
    public segundo_apellido_rl:string,
    public tipo_identificacion:string,
    public numero_identificacion:string,
    public codigo_verificacion:string,
    public digito_verificacion:string,
    public contacto_pagaduria:string,
    public contacto_contabilidad:string,
    public email_contacto_contabilidad:string,
    public phone_contabilidad:string,
    public phone_pagaduria:string,
    public quien_realiza_registro:string,
    public email_facturacion_electronica:string,
    public email_representante_legal:string,
    public pep_rl:string,
    public representante_legal_doc:string,
    public ejerce_cargo_publico_rl:string,
    public maneja_recurso_publico_rl:string,
    public cargo_publico_rl:string,
    public fecha_cargo_publico_rl:string,
    public cargo_recurso_publico_rl:string,
    public fecha_recurso_publico_rl:string,

  ){

  }
}
