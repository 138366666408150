import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Novedades } from 'src/app/models/novedades';
import { tributario } from '../../listas/tributarios';
import { eps } from '../../listas/tributarios';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NovedadesService } from 'src/app/Servicios/novedad.service';
import { Observable } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NgIf } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { global } from '../../Servicios/global';

declare var $: any;

@Component({
  selector: 'app-novedad',
  templateUrl: './novedad.component.html',
  styleUrls: ['./novedad.component.css'],
  providers: [NovedadesService]
})



export class NovedadComponent implements OnInit {
  @ViewChild("numero_tributario") numero_tributario: ElementRef;
  @ViewChild("novedad") novedad: ElementRef;
  @ViewChild("novedad2") novedad2: ElementRef;
  /* @ViewChild("cedula") cedula: ElementRef;*/


  public novedades: Novedades;
  public registro;
  public tipos: Array<tributario>;
  public loader_crt_nov: Boolean = false;
  public loader_crt_nov2: Boolean = false;
  public msn_crt_nov = 'Seleccione el Archivo';
  public msn_crt_nov2 = 'Seleccione el Archivo';
  public soporte_doc1 = '';
  public soporte_doc2 = '';
  public pureba = 'prueba';
  public success: boolean = false;
  public valuedocument = 'Digite número de documento de identificación';
  public data: Observable<any[]>;
  public keyword = 'cedula';
  public empresa = 'brm';
  public companias='';
  public value='brm';
  public nombre_empresa='BRM S.A';
  public tipo_eps: Array<eps>;
  public inicial_prorroga:any=[
    {value:'inicial', nombre:'Inicial'},
    {value:'prorroga', nombre:'Prorroga'}
  ]
 /* any = [
    { value: this.empresa, nombre: 'BRM S.A' },
    /* {value:'art', nombre:'ARTICULACION SAS'},
     {value:'deeploy',nombre:'DEEPLOY SAS'},
     {value:'heart',nombre:'HEART EMOTIONAL EXPERIENCES SAS'},
     {value:'dar',nombre:'GIVING S.A.S'},
     {value:'preferente',nombre:'PREFERENTE MP SAS'}
  ];*/
  public companiaLogo = '../../assets/img/company_logo.jpg';
  public solo_lectura=false;
  public creado_por = '';
  public consecutivo_soporte='';
  public enlace_soporte1='';
  public enlace_soporte2='';
  public texto_spinner = 'Cargando...';
  public url:string;
  public contratante_label='';
  public procedimiento='registrar';
  public ocultarBoton=false;
  public submi='Enviar';
  public color_boton_enviar='btn btn-danger';
  public consecutivo_novedad='';
  public estado_novedad='';
  public desha_consecutivo_estado=false;



  constructor(
    private spinner: NgxSpinnerService,
    private _novedadesService: NovedadesService,
    private renderer: Renderer2,
    private _route: ActivatedRoute,
    private _router: Router
  ) {

    this.url = global.url;
    /*this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);*/

    this.novedades = new Novedades('1', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '','','','','','','','','','','');
    this._route.params.subscribe(params => {
      this.empresa = params['empresa'];
      switch (this.empresa) {
        case 'dar': {
          this.companiaLogo = '../../assets/img/company_logo_dar.png'
          this.value='dar'
          break;
        }
        case 'art': {
          this.companiaLogo = '../../assets/img/company_logo_complement.png'
          this.value='art'
          break;
        }
        case 'heart': {
          this.companiaLogo = '../../assets/img/company_logo_heart.png'
          this.value='heart'
          break;
        }
        case 'deeploy': {
          this.companiaLogo = '../../assets/img/company_logo_deeploy.png'
          this.value='deeploy'
          break;
        }
        case 'preferente': {
          this.companiaLogo = '../../assets/img/company_logo_preferente.png'
          this.value='preferente'
          break;
        }
        default: {
          this.companiaLogo = '../../assets/img/company_logo.jpg';
          this.value='brm'
          break;
        }
      }
    });

    this._route.params.subscribe(params => {
      if (typeof params['procedimiento'] === "undefined") {
        // se mantiene procedimiento por defecto
      } else{
        this.procedimiento = params['procedimiento'];
        console.log(this.procedimiento);
      }
    });
  }


  ngOnInit(): void {
    $('body').bootstrapMaterialDesign();
    this.precargarNovedad('',this.empresa);
    this.getCedula();
    this.getListados();
    this.getListadoEps();
    this.obtenerConsecutivo();
    this.creadopor();
  }

  onSubmit(form) {
    console.log(this.novedades);
    this._novedadesService.create(this.novedades,'registro',this.empresa).subscribe(
      response => {
        console.log(response);
        if (response.status == 'success') {
          this.success = true;
        }

      },
      error => {
        console.log(<any>error.error.validacion);
        alert(<any>error.error.validacion);
        return false;
      }
    );
    //form.reset();

  }


  getListados() {
    this._novedadesService.getListas('tipo_novedad').subscribe(
      response => {
        this.tipos = response;
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getListadoEps() {
    this._novedadesService.getListas('eps').subscribe(
      response => {
        this.tipo_eps = response;
        console.log(response);
      },
      error => {
        console.log(<any>error);
      }
    );
  }


  obtenerConsecutivo() {
    this._novedadesService.getConsecutivo(this.empresa).subscribe(response => {
      if (this.novedades.consecutivo=='') {
        this.novedades.consecutivo = <any>response;
      }
    },
      error => {
        console.log(<any>error);
      }
    );

  }

  soporteNovedad(ev) {
    let doc: any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);
    if (sizekiloByte > 2048) {
      alert("No se permiten Archivos con tamaño superior a 2 MB");
      return false;
    }
    if (doc.files.length > 0) {
      this.loader_crt_nov = true;
      let form = new FormData();
      let consecutivo = this.novedades.consecutivo;
      form.append('file0', doc.files[0]);
      let novdoc: any = doc.files[0].name;
      this._novedadesService.subirDocumento(form, consecutivo,this.empresa).subscribe(
        resp => {
          this.loader_crt_nov = false;
          if (resp.status == 'success') {
            this.soporte_doc1 = resp.documento;
            this.novedades.soporte_novedad = resp.documento;
            this.msn_crt_nov = novdoc;
            /*this.renderer.removeClass(this.novedad.nativeElement, "decorator");
            this.renderer.addClass(this.novedad.nativeElement, "success");*/
          }
        },
        error => {
          this.loader_crt_nov = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }


  soporteNovedadAdicional(ev) {
    let doc: any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);
    if (sizekiloByte > 2048) {
      alert("No se permiten Archivos con tamaño superior a 2 MB");
      return false;
    }
    if (doc.files.length > 0) {
      this.loader_crt_nov2 = true;
      let form = new FormData();
      let consecutivo = this.novedades.consecutivo;
      form.append('file0', doc.files[0]);
      let novdoc2: any = doc.files[0].name;
      this._novedadesService.subirDocumento(form, consecutivo,this.empresa).subscribe(
        resp => {
          this.loader_crt_nov2 = false;
          if (resp.status == 'success') {
            this.soporte_doc2 = resp.documento;
            this.novedades.soporte_novedad_adicional = resp.documento;
            this.msn_crt_nov2 = novdoc2;
           /* this.renderer.removeClass(this.novedad2.nativeElement, "decorator");
            this.renderer.addClass(this.novedad2.nativeElement, "success");*/
          }
        },
        error => {
          this.loader_crt_nov2 = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        });
    }
  }


  getCedula() {
    this._novedadesService.getContratacion(this.empresa).subscribe(res => {
      this.data = <any>res;
    },
      error => {
        console.log(<any>error)
      });
  }

  selectEvent(event) {
    this._route.params.subscribe(params => {
    this.novedades.compania=params['empresa'];
    });
    this.novedades.estado_contratacion = event.estado_contratacion;
    this.novedades.contratacion = event.contratante;
    this.contratante_label = event.contratante_label;
    this.novedades.contratacion_id = event.contratacion_id;
    this.novedades.tipo_vinculacion = event.tipo_vinculacion;
    this.novedades.centro_costo=event.centrocosto;
    this.novedades.subcentro_costo=event.subcentrocosto;
   if(this.novedades.telefono=event.telefono){
    this.novedades.telefono=event.telefono
   }else{
     this.novedades.telefono='no aplica';
   }
   if(this.novedades.campania=event.campania){
    this.novedades.campania=event.campania;
    this.novedades.campania_id=event.campania_id;
   }else{
    this.novedades.campania='no aplica';
    this.novedades.campania_id='no aplica';
   }
  }



  precargarNovedad(pre_id = '', empresa = 'brm') {
    this._route.params.subscribe(params => {
      let id = '';
      if (pre_id == '') {
        id = params['id'];
      } else {
        id = pre_id;
      }
  console.log(id);
      if (id != 'registro') {
        this.spinner.show();
        this._novedadesService.getNovedad(id,empresa).subscribe(
          response => {
            console.log(response);
            this.estado_novedad=response.novedades.estado;
            this.consecutivo_novedad=response.novedades.consecutivo;
            if (response.status == 'success') {
              var resnovedad:any;
               resnovedad = response.novedades;
               if(this.procedimiento=='detalle'){
                this.solo_lectura=true;
               } else{
                if (response.novedades.estado!='borrador') {
                  this.ocultarBoton=true;
                  if(this.procedimiento=='edicion'){
                    this.submi='Editar';
                    this.color_boton_enviar='btn btn-info';
                    }
                 } else {

                  this.ocultarBoton=false;
                 }
                this.solo_lectura=false;
               /* if (response.novedades.estado=='borrador' || response.novedades.estado=='registro') {
                  this.solo_lectura=false;
                 } else {
                  this.solo_lectura=true;
                 }*/
               }
              console.log( this.novedades);
              this.novedades = new Novedades(
               resnovedad.id,
               resnovedad.compania,
               resnovedad.cedula,
               resnovedad.tipo_novedad,
               resnovedad.eps,
               resnovedad.numero_incapacidad,
               resnovedad.incapacidad_prorroga,
               resnovedad.numero_incapacidad_prorroga,
               resnovedad.inicio_novedad,
               resnovedad.final_novedad,
               resnovedad.radicacion_novedad,
               resnovedad.diagnostico,
               resnovedad.prorroga,
               resnovedad.registro_kactus,
               resnovedad.soporte_novedad,
               resnovedad.soporte_novedad_adicional,
               resnovedad.observaciones,
               resnovedad.contratacion_id,
               resnovedad.contratacion,
                //response.otros[0].contratante,
               resnovedad.estado_contratacion,
               response.otros[0].centrocosto,
               response.otros[0].subcentrocosto,
               response.otros[0].campania,
               resnovedad.campania_id,
               response.otros[0].telefono,
               response.vinculacion,
               resnovedad.consecutivo,
               this.creado_por,
               response.novedades.estado
              );
              this.contratante_label=response.contratante_actual_label;
              if(response.otros.campania==''){
                this.novedades.campania='no aplica',
                this.novedades.campania_id='no aplica'
              }
              this.valuedocument = response.otros[0].cedula;
              this.msn_crt_nov = response.informacion.name_doc_nov;

              if (response.informacion.name_doc_nov != '') {
                this.consecutivo_soporte=resnovedad.consecutivo;
                this.enlace_soporte1=`${this.url}novedades/${this.consecutivo_soporte}/${this.msn_crt_nov}`
               /* this.renderer.removeClass(this.novedad.nativeElement, "decorator");
                this.renderer.addClass(this.novedad.nativeElement, "success");*/
              }
              this.msn_crt_nov2 = response.informacion.name_doc_nov2;
              if (response.informacion.name_doc_nov2 != '') {
                this.consecutivo_soporte=resnovedad.consecutivo;
                this.enlace_soporte2=`${this.url}novedades/${this.consecutivo_soporte}/${this.msn_crt_nov2}`
               /* this.renderer.removeClass(this.novedad2.nativeElement, "decorator");
                this.renderer.addClass(this.novedad2.nativeElement, "success");*/

              }
              console.log(resnovedad.consecutivo);
              console.log(this.novedades);
              this.spinner.hide();
              //this.msn_crt_nov=response.informacion.name_doc_rut;
              // this.renderer.removeClass(this.novedad.nativeElement, "decorator");
              // this.renderer.addClass(this.novedad.nativeElement, "success");
              // this.renderer.setAttribute(this.cedula.nativeElement, "readonly", "true");
              // this.renderer.addClass(this.dig_verifica.nativeElement, "deshabilitarClick");
              //this.renderer.setAttribute(this.dig_verifica.nativeElement, "readonly", "true");
              // this.renderer.setStyle(this.div_proveedor.nativeElement, "display", "");
            } else {
              this.spinner.hide();
              this._router.navigate(['novedades/registro'+ this.empresa]);
            }
          },
          error => {
            //console.log(error.error);
            this.spinner.hide();
            if (error.error.status == 'unauthorized') {
              // this.renderer.removeAttribute(this.numero_tributario.nativeElement, "readonly");
              // this.renderer.removeClass(this.dig_verifica.nativeElement, "deshabilitarClick");
              // this.renderer.removeAttribute(this.dig_verifica.nativeElement, "readonly", "true");
              // this.renderer.setStyle(this.div_proveedor.nativeElement, "display", "none");
              // this.proveedor.tributary_number = 0;
              //this.proveedor.digito_verificacion = '';
              alert(error.error.message);
            } else {
              this._router.navigate(['novedades/registro'+ this.empresa]);
            }
          }

        );
      } else {
        this.desha_consecutivo_estado=true;
        //this.novedades= new Novedades('1','', '','','','', '','', '', '', '', '', '', '', '', '', '', );
      }

    });
  }
  guardarBorrador() {
    console.log(this.novedades);
    this._novedadesService.create(this.novedades, 'borrador',this.empresa).subscribe(
      response => {
        console.log(response);
        //console.log(response.status);
        if (response.status == 'success') {
          //console.log(response.message.id);
          this.novedades.id = response.message.id;
          this.novedades.consecutivo = response.message.consecutivo;
          /* this.renderer.addClass(this..nativeElement, "deshabilitarClick");
           this.renderer.setAttribute(this..nativeElement, "readonly", "true");*/
          alert('Borrador Guardado');
        }

      },
      error => {
        //console.log(<any>error.error.validacion);
        let message;
        if (<any>error.error.validacion == 'The compania field is required.') {
          message = 'Ingrese Nombre de la compañia donde Trabaja';
        } else {
          message = <any>error.error.validacion;
        }
        alert(message);
        return false;
      }
    );
    //form.reset();
  }

  validarNovedad() {
    if(this.novedades.tipo_novedad!="incapacidad_lm ||incapacidad_eg|| licencia_paternidad||accidente_de_trabajo"){
        this.novedades.eps='';
        this.novedades.numero_incapacidad='';
        this.novedades.incapacidad_prorroga='';
        this.novedades.numero_incapacidad_prorroga='';
    }
    if (this.novedades.contratacion_id == '') {
      alert('Por favor digite el Número  de Documento del Trabajador');
      return false;
    } else if (this.novedades.tipo_novedad == '') {
      alert('Por favor Ingrese tipo novedad');
      return false;
    } else {
      //this.renderer.setAttribute(this.tipo_novedad.nativeElement, "readonly", "true");
      this._novedadesService.validarNovedad(this.novedades.contratacion_id, this.novedades.tipo_novedad,this.empresa).subscribe(
        resp => {
          console.log(resp);
          if (resp.status == 'success') {
            this.precargarNovedad(resp.novedad_id,this.empresa);
          } else {
            console.log('no hay una novedad');
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

ValidarProrroga(){
  if(this.novedades.incapacidad_prorroga=='inicial'){
  this.novedades.numero_incapacidad_prorroga='';
  this.novedades.prorroga='0'
  }else{
    this.novedades.prorroga='1'

  }
}


ValidarFechaFinal(){

if(this.novedades.inicio_novedad==''){
  alert('Ingrese Fecha Inicial Novedad*');
  this.novedades.final_novedad='';
}
if( this.novedades.final_novedad < this.novedades.inicio_novedad){
  alert('Fecha Final Novedad* no puede ser menor a  Fecha Inicial Novedad*');
  this.novedades.final_novedad='';

}
}

ValidarFechaInicial(){

/*if(this.novedades.inicio_novedad!=''){
  var prueba=confirm('ya existe una novedad  con la misma fecha de inicio desea precargarla');
  if(prueba==true){
 alert('se precarga');
  }else{
    alert('no se precarga');
  }
}*/
  if(this.novedades.final_novedad !='' && this.novedades.inicio_novedad > this.novedades.final_novedad  ){
    alert(' Fecha Inicial Novedad* no puede ser Mayor a  Fecha Final Novedad* ');
    this.novedades.inicio_novedad='';

  }
  }


  selectCompania(event) {
    //this.empresa = event;
    //console.log(this.empresa);
    /*if(event=='brm'){
    this.companiaLogo='../../assets/img/company_logo.jpg';*/
   /*switch (event) {
      case 'dar': {
        this.companiaLogo = '../../assets/img/company_logo_dar.png'
        break;
      }
      case 'art': {
        this.companiaLogo = '../../assets/img/company_logo_complement.png'
        break;
      }
      case 'heart': {
        this.companiaLogo = '../../assets/img/company_logo_heart.png'
        break;
      }
      case 'deeploy': {
        this.companiaLogo = '../../assets/img/company_logo_deeploy.png'
        break;
      }
      case 'preferente': {
        this.companiaLogo = '../../assets/img/company_logo_preferente.png'
        break;
      }
      default: {
        this.companiaLogo = '../../assets/img/company_logo.jpg';
        break;
      }
    }
*/
  }
  creadopor() {
    this._route.params.subscribe(params => {
      this.creado_por = params['creado_por'];
    });
    this.novedades.creado_por = this.creado_por;
    console.log(this.novedades.creado_por);


  }
}
