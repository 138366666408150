import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Proveedor} from '../models/providers';
import { global } from './global';


@Injectable()
export class ProveedoresService{
	public url: string;

	constructor(
	  	public _http: HttpClient
	){
		this.url = global.url;
	}

	create(proveedor,tipo_guardado='registro',empresa ='brm',cod_pais,socios): Observable<any>{

		let json = JSON.stringify(proveedor);
    let sociosP = JSON.stringify(socios);
		let params = 'json='+json+'&tipo_guardado='+tipo_guardado+'&empresa='+empresa+'&cod_pais='+cod_pais+'&socios='+sociosP;
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.post(this.url+'provider',params, {headers: headers});
	}

	test(){
	  return "prueba de un servicio";
	}

	getListas(tipo,empresa): Observable<any>{
    	//todos los listados los toma de BRM
      	empresa='brm';
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get(this.url+'provider/listas/'+tipo+'/'+empresa, {headers: headers});
	}

	getLocations(modo): Observable<any[]>{
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get<any[]>(this.url+'provider/cities/'+modo, {headers: headers});
  	}

  	getActividad_principal(modo): Observable<any[]>{
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get<any[]>(this.url+'provider/ciiu/'+modo, {headers: headers});
	}

	subirDocumento(datos:any,nit,empresa):Observable<any>{
	   return this._http.post(this.url+'provider/upload/'+nit+'/'+empresa, datos);
	}

	getProvider(id,empresa,procedimiento):Observable<any>{
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get(`${this.url}providerOne/${id}/${empresa}/${procedimiento}`, {headers: headers});
	}

	validarProveedor(nit, dig_verificacion,tipo_identificacion,empresa): Observable<any>{
		let numero_tributario = '';
	    if (tipo_identificacion == 'nit') {
	        numero_tributario = nit+'-'+dig_verificacion;
	    } else {
	        numero_tributario = nit;
	    }
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get(this.url+'provider/validar/'+numero_tributario+'/'+empresa, {headers: headers});
	}

	validarCodigoVerificacion(codigo_verificacion,empresa): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get(this.url+'provider/validarCodigo/'+codigo_verificacion+'/'+empresa, {headers: headers});
	}

	eliminarSocio(socioID,empresa):Observable<any>{
		let params = 'socio_id='+socioID;
	   return this._http.post(this.url+'provider/deleteS/'+socioID+'/'+empresa, params);
  	}

}



