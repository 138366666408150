<div><ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">{{texto_spinner}}</p>
  </ngx-spinner></div>

<div class="container-fluid" style="margin: 82px;">
  <nav class="navbar navbar-dark navbar-expand-lg fixed-top bg-danger border-light border rounded shadow pulse animated"
    style="filter: blur(0px) brightness(92%) contrast(111%) grayscale(11%) hue-rotate(0deg) invert(0%) saturate(107%) sepia(0%);opacity: 0.98;">
    <div class="container-fluid">
      <a class="navbar-brand text-uppercase text-left text-light shadow-none visible" href="#"
        style="font-family: 'Roboto Condensed', sans-serif;">Articulación</a>
      <button data-toggle="collapse" class="navbar-toggler" data-target="#navcol-1">
        <span class="sr-only">Toggle navigation</span><span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse text-success bg-danger border-success d-table-cell" id="navcol-1">
        <ul class="nav navbar-nav">
          <li class="nav-item" role="presentation"></li>
          <li class="nav-item" role="presentation"></li>
          <li class="nav-item" role="presentation"></li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<div class="container-fluid">
  <div class="card text-light shadow-lg col-12">
    <div class="card-body text-left text-body border rounded border-light flex-row visible">
      <h3 class="text-uppercase text-left card-title">FORMATO DE REGISTRO PROVEEDORES<br></h3>
      <h6 class="text-capitalize text-left text-muted card-subtitle mb-2">Actualización (1) Vigencia: 01/07/2019
        Elaborado: Gerente Procesos Revisado: Representante Sistema Aprobado: Presidente<br><br></h6>
      <br>
      <form #providerForm="ngForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <br>
        <div class="seccion_1">


          <br>
          <div class="form-row" [hidden]='deshabilitar_boton'>
            <div class="form-group col-md-3">
              <label class="bmd-label-floating" class="text-center"><strong>Código*</strong><br></label>
              <input id="codigo_verificacion" name="codigo_verificacion" class="form-control" type="text"
                #codigo_verificacion="ngModel" [(ngModel)]="proveedor.codigo_verificacion"
                pattern="[A-Za-z,.'-, ' ',0-9,-]{4,30}"
                tooltip='Este código confirmará que el representante legal ha registrado los datos y realizado las declaraciones, o ha delegado a un funcionario que lo ejecutó en su nombre y responsabilidad.'
                required="{{codigo_verificacion_req}}">
              <span *ngIf="!codigo_verificacion.valid && codigo_verificacion.touched">El Campo: Código es
                requerido</span>
            </div>
          </div>
          <div class="form-row" [hidden]='deshabilitar_boton'>
            <div class=" form-group col-md-3">
              <button class="btn btn-danger active text-white border rounded" value="providerForm"
                (click)="validarCodigo(1)" type="button">Verificar Código
              </button>
            </div>
          </div>

          <hr>
          <div class='informacion_general' #informacion_general style="display: none;">
            <div class="form-row">
              <div class="form-group col-md-12">
                <h5>INFORMACIÓN GENERAL</h5>
                <hr>
                <br>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3" id='ng-autocomplete'>
                <label class="text-center"><strong>Ciudad*</strong><br></label>
                <ng-autocomplete [data]="data$ | async" [searchKeyword]="keyword" (selected)='selectEvent($event)'
                  [itemTemplate]="itemCiudad" (inputFocused)='onFocusAutoc($event)' [placeHolder]="valueCity"
                  [disabled]="disabledcity" [notFoundTemplate]="notFoundTemplate"
                  tooltip='Ingrese la Ciudad donde se encuentra registrada la Compañia'>

                </ng-autocomplete>
                <ng-template #itemCiudad let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
              <!-- <div class="form-group col-md-3">
                    <label class="bmd-label-floating" class="text-center"><strong>Código*</strong><br></label>
                    <input id="codigo_verificacion"
                        name="codigo_verificacion"
                        class="form-control"
                        type="text"
                        (change)="validarCodigo(1)"
                        #codigo_verificacion="ngModel"
                        [(ngModel)]="proveedor.codigo_verificacion" pattern="[A-Za-z,.'-, ' ',0-9,-]{4,30}" tooltip='Este código confirmará que el representante legal ha registrado los datos y realizado las declaraciones, o ha delegado a un funcionario que lo ejecutó en su nombre y responsabilidad.' required="{{codigo_verificacion_req}}">
                        <span *ngIf="!codigo_verificacion.valid && codigo_verificacion.touched">El Campo: Código es requerido</span>
                </div>-->
              <div class="form-group col-md-3">
                <label class="text-center"><strong>{{label_razon_social}}</strong><br></label>
                <input id="firm_name" name="firm_name" class="form-control" type="text" #firm_name="ngModel"
                  (change)="validarCodigo(0);camposRequeridos()" [(ngModel)]="proveedor.firm_name"
                  pattern="[A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ\u00f1\u00d1,.'-, ' ',0-9,-]{4,64}"
                  tooltip='{{tooltip_firm_name}}' required="{{firm_name_req}}">
                <span *ngIf="!firm_name.valid && firm_name.touched">Falta el campo requerido:Razón Social/Nombre Persona
                  Natural.</span>
              </div>
              <div class="form-group col-md-3">
                <label class="text-center"><strong>Teléfono (1)*</strong><br></label><br>
                <input id="phone_office" name="phone_office" class="form-control" type="tel"
                  (change)="camposRequeridos()" (countryChange)="onCountryChange($event)" #phone_office="ngModel"
                  [(ngModel)]="proveedor.phone_office" pattern="[A-Za-z,.'-, ' ',0-9,-]{4,20}"
                  tooltip='Hace referencia al contacto comercial' required="{{phone_office_req}}"><br>
                <span *ngIf="!phone_office.valid && phone_office.touched">Formato de telefono Invalido.</span>
              </div>
              <div class="form-group col-md-3">
                <label class="text-center"><strong>Teléfono (2)*</strong><br></label><br>
                <input id="phone_alternate" name="phone_alternate" class="form-control" type="tel"
                  (change)="camposRequeridos()" (countryChange)="onCountryChange_2($event)" aling='right'
                  #phone_alternate="ngModel" [(ngModel)]="proveedor.phone_alternate"
                  pattern="[A-Za-z,.'-, ' ',0-9,-]{4,20}"
                  tooltip='Teléfono adicional en caso de no localizar al comercial'
                  required="{{phone_alternate_req}}"><br>
                <!-- <span *ngIf="!phone_alternate.valid && phone_alternate.touched">El numero de Teléfono es requerido.</span>-->
              </div>
            </div>
            <div class="form-row">

              <div class="form-group col-md-3">
                <label class="text-center"><strong>Dirección*</strong><br></label>
                <input id="billing_address_street" name="billing_address_street" class="form-control" type="text"
                  #billing_address_street="ngModel" (change)="camposRequeridos()"
                  [(ngModel)]="proveedor.billing_address_street"
                  tooltip='La registrada en la Cámara de Comercio (Persona Jurídica) o de correspondencia (Persona Natural)'
                  required="{{billing_address_street_req}}">
                <span *ngIf="!billing_address_street.valid && billing_address_street.touched">El campo:Dirección es
                  requerido.</span>
              </div>
              <div class="form-group col-md-3">
                <label class="bmd-label-floating" class="text-center"><strong>Contacto (Comercial)*</strong><br></label>
                <input id="contacto_comercial" name="contacto_comercial" class="form-control" type="text"
                  (change)="camposRequeridos()" #contacto_comercial="ngModel" [(ngModel)]="proveedor.contacto_comercial"
                  pattern="[A-Za-z,.'-,' ',0-9,-]{4,64}"
                  tooltip='Con que funcionario debemos contactarnos en su compañía'
                  required="{{contacto_comercial_req}}">
                <span *ngIf="!contacto_comercial.valid && contacto_comercial.touched">Falta el campo requerido:Contacto
                  (Comercial)</span>
              </div>
              <div class="form-group col-md-3">
                <label class="bmd-label-floating" class="text-center"><strong>Correo electrónico (Contacto
                    Comercial)*</strong><br></label>
                <input id="email_contacto_comercial" name="email_contacto_comercial" #email_contacto_comercial="ngModel"
                  [(ngModel)]="proveedor.email_contacto_comercial" class="form-control"
                  (change)="validarCamposC(email_contacto_comercial);camposRequeridos();" type="email"
                  pattern="^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$"
                  tooltip='Correo de a quien podemos contactar' required="{{email_contacto_comercial_req}}">
                <span *ngIf="!email_contacto_comercial.valid && email_contacto_comercial.touched">El Campo Email es
                  requerido.</span>
              </div>


              <div class="form-group col-md-3" *ngIf="empresa =='brmmx' && codigo_pais=='MX'">
                <label class="bmd-label-floating" class="text-center"><strong>RFC</strong><br></label>
                <input #numero_tributario id="tributary_number" input-lg name="tributary_number" class="form-control"
                  type="text" tooltip='Registro Federal de Contribuyentes' (change)="camposRequeridos();"
                  #tributary_number="ngModel" [(ngModel)]="proveedor.tributary_number" minlength='4' maxlength='13'
                  required="{{tributary_number_req}}">
                <span *ngIf="!tributary_number.valid && tributary_number.touched">El Campo: RFC es requerido.</span>

              </div>
            </div>

            <!--campos ocultos donde se guardan los valores del pais y la ciudad -->
            <div class="form-row">
              <div class="form-group col-md-6">
                <input id="billing_country_id" name="billing_country_id" class="form-control" type="hidden"
                  #billing_country_id="ngModel" value="{{pais}}" [(ngModel)]="proveedor.billing_country_id">
                <input id="nombres_localizacion" name="nombres_localizacion" class="form-control" type="hidden"
                  #nombres_localizacion="ngModel" value="{{concatedNames}}"
                  [(ngModel)]="proveedor.nombres_localizacion">
                <input id="billing_city_id" name="billing_city_id" class="form-control" type="hidden"
                  #billing_city_id="ngModel" value="{{ciudad}}" [(ngModel)]="proveedor.billing_city_id">
              </div>
            </div>



          </div>

          <br>
          <div class="internacionales" #seccion_internacional style="display: none;">
            <section>
              <h5>INFORMACIÓN BANCARIA</h5>
              <hr><br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label><strong>Banco </strong><br></label>
                  <input id="banco_internacional" name="banco_internacional" class="form-control" type="text"
                    #banco_internacional="ngModel" (change)="camposRequeridos()"
                    [(ngModel)]="proveedor.banco_internacional" tooltip='Corresponde al banco de su país.'>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="bmd-label-floating"><strong>Número de Cuenta*</strong><br></label>
                  <input id="numero_cuenta" name="numero_cuenta" class="form-control" type="text"
                    (change)="validarCamposC(numero_cuenta);camposRequeridos();" #numero_cuenta="ngModel"
                    [(ngModel)]="proveedor.numero_cuenta" required="{{numero_cuenta_req}}"
                    tooltip='Ingrese numero de cuenta Bancaria'>
                  <span *ngIf="!numero_cuenta.valid && numero_cuenta.touched">El Campo: Número de Cuenta es
                    requerido</span>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="bmd-label-floating" class="text-center"><strong>{{campo_switch}}</strong><br></label>
                  <input id="numero_swift" name="numero_swift" class="form-control" type="text" #numero_swift="ngModel"
                    [(ngModel)]="proveedor.numero_swift" tooltip='{{tooltip_switch}}'>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6"><label><strong>{{certificacion_cuenta}}</strong><br></label><br>
                  <div class="">
                    <input type="file" name="file3" id="file3" (change)="documentCertificacionCuenta($event)"
                      accept=".pdf" *ngIf="!loader_crt_cuenta" class="inputfile" />
                    <div *ngIf="loader_crt_cuenta">
                      <img src="../../assets/loading.gif" />
                    </div>
                    <label for="file3" class="decorator" tooltip='{{tooltip_certificacion_cuenta}}' placement='right'
                      #account_int>{{msn_crt_cuenta}} </label>
                  </div>

                  <br>
                  <br>
                  <input id="certificado_cuenta_doc" name="certificado_cuenta_doc" class="form-control" type="hidden"
                    (change)="camposRequeridos()" #certificado_cuenta_doc="ngModel" value="{{certificacion_cuenta_doc}}"
                    [(ngModel)]="proveedor.certificado_cuenta_doc">
                </div>

              </div>
              <div *ngIf="codigo_pais==='MX' && empresa==='brmmx'">
                <div class="form-row">
                  <!-- <div class="form-group col-md-6">
                        <label><strong>DOCUMENTO IDENTIDAD DE REPRESENTANTE LEGAL*</strong><br></label><br>
                        <div class="">
                            <input type="file" name="file5" id="file5" (change)="documentRL($event)" accept=".pdf" *ngIf="!loader_crt_rl"  class="inputfile" />

                            <div *ngIf="loader_crt_rl">
                                <img src="../../assets/loading.gif" alingh="center" />
                            </div>
                            <label for="file5" class="decorator" tooltip='Documento de Identificacion Representante legal
                            ' placement='right' #rep_int>{{msn_doc_rl}}</label>
                        </div>

                        <br>
                        <br>
                        <input id="documento_rl_doc"
                            name="documento_rl_doc"
                            class="form-control"
                            type="hidden"
                            #documento_rl_doc="ngModel"
                            value="{{documento_identidad_rl}}"
                            [(ngModel)]="proveedor.documento_rl_doc">
                    </div>-->

                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label><strong>RFC(Registro Federal de contribuyentes)*</strong><br></label><br>
                    <div class="">
                      <input type="file" name="file6" id="file6" (change)="RFC($event)" accept=".pdf"
                        *ngIf="!loader_crt_rfc" class="inputfile" />

                      <div *ngIf="loader_crt_rfc">
                        <img src="../../assets/loading.gif" alingh="center" />
                      </div>
                      <label for="file6" class="decorator" tooltip='Registro federal de contribuyentes
                          ' placement='right' #rfc>{{msn_crt_rfc}}</label>
                    </div>

                    <br>
                    <br>
                    <input id="rfc_doc" name="rfc_doc" class="form-control" type="hidden" #rfc_doc="ngModel"
                      value="{{documento_rfc}}" [(ngModel)]="proveedor.rfc_doc">
                  </div>

                </div>
              </div>
            </section>
            <div class="container-fluid">




              <div [formGroup]="registerPartner">
                <div *ngIf="mostrar_labels_socios">
                  <h4 class="col-md-12 text-center">Socio / Beneficiario Final</h4>
                  <hr>
                  <div class="form-row">
                    <p class="text-justify">Por favor registrar la información de los BENEFICIARIOS FINALES de la
                      entidad con participación igual o mayor al 5% (Si no los registra en este espacio, debe adjuntar
                      una certificación con la lista que contenga nombre, tipo y número de identificación y
                      participación (%) participación, suscrita por el Representante Legal)
                    </p>
                    <ul *ngIf="codigo_pais!='MX' && empresa!='brmmx'" style="list-style-type:circle color=red"
                      class="text-justify">
                      <li>Personas Expuestas Políticamente
                        De conformidad con las leyes Colombianas (Persona que ocupa uno de los cargos públicos
                        establecidos en Decreto 1674 de 2016).En caso de aplicar por favor marcar la casilla
                        correspondiente.</li>
                    </ul>
                  </div>
                  <hr style="background-color: #dcdcdd">
                  <div class="form-row">
                    <div class="form-group col " *ngFor="let nombre of nombresLabels">
                      <label
                        class="bmd-label-floating  text-align: center;"><strong>{{nombre.nombres}}</strong><br></label>
                    </div>
                    <div *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="form-group col">
                      <label class="bmd-label-floating  text-align: center;"><strong
                          class="circulo">•</strong><strong>PEP</strong><br></label>
                    </div>
                  </div>
                </div>
                <div formArrayName="socios" *ngFor="let socio of socios.controls.slice(0, 5); let i = index">
                  <div class="form-row">
                    <div class="form-group col" *ngIf="i>0">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="removerSocio(i)">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div class="form-row" [formGroupName]="i">
                    <div class="form-group col ">
                      <select class="form-control" formControlName="tipo_identificacion"
                        (change)="socioTipoId($event,i)">
                        <option value="{{tipo_identificacion.id}}"
                          *ngFor="let tipo_identificacion of tipo_identificacion_rls">{{tipo_identificacion.nombre}}
                        </option>
                      </select>
                    </div>

                    <div class="form-group col">
                      <input class="form-control" type="text" formControlName="numero_identificacion"
                        (keypress)="validarNumeroIdentificacion($event)">
                    </div>
                    <div class="form-group col">
                      <input class="form-control" type="text" formControlName="primer_nombre">
                    </div>

                    <div class="form-group col ">
                      <input class="form-control " type="text" formControlName="segundo_nombre">
                    </div>

                    <div class="form-group col ">
                      <input class="form-control" type="text" formControlName="primer_apellido">
                    </div>

                    <div class="form-group col ">
                      <input class="form-control " type="text" formControlName="segundo_apellido">
                    </div>



                    <div *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="form-group col">
                      <div class="form-check text-align: center;">
                        <input class="form-check-input" type="checkbox" value="0" formControlName="pep">
                      </div>
                    </div>
                  </div>
                  <hr style="background-color: #dcdcdd">


                </div>
                <div class="form-row" *ngIf="boton_agregar_socio">
                  <div class="adicionar-socio" class="col-md-3">
                    <button class="btn btn-danger active text-white border rounded" value="agregaSocio"
                      (click)="agregarSocio(1)" type="button">Agregar
                    </button>
                  </div>
                  <!--<div class="guardar-socio" class="col-md-3">
                      <button class="btn btn-success active text-white border rounded" value="guardaSocio" (click)="guardarSocio()" type="button">Guardar Socio
                        </button>
                  </div>-->
                </div>
              </div>
              <hr>

              <p class="text-center"><strong>DECLARACIÓN</strong></p>
              <p class="text-justify"> En calidad de Representante Legal y/o apoderado y/o autorizado de la Sociedad
                arriba suscrita,
                declaro y manifiesto expresamente que toda la información consignada en el presente
                registro es veraz, cierta y autentica. Igualmente, declaro:<br></p>
              <p class="text-justify"><strong>• RESPECTO A POTENCIALES CONFLICTOS DE INTERÉS.</strong></p>
              <p class="text-justify">Declaro que ningún empleado, directivo, miembro de junta directiva o socio de la
                sociedad a
                quien represento, ni sostienen vínculo alguno de cónyuge o compañero permanente, familiar hasta el
                cuarto grado de
                consanguinidad, segundo de afinidad y primero civil con el personal administrativo y directivo de
                {{texto_empresa}}; ni ninguno
                de ellos dará, ofrecerá o prometerá, directa o indirectamente, ninguna dádiva, dinero, beneficio o
                ventaja, a los
                empleados, asesores o directivos de {{texto_empresa}}. De igual forma, en caso de configurarse una
                situación de conflicto de
                intereses, conforme con las declaraciones aquí expuestas, {{texto_empresa}} se encuentra en la facultad
                de dar por terminada la
                relación comercial y/o contrato, sin que se cause derecho de indemnización alguna en favor del cliente
                y/o proveedor,
                y en todo caso, en el evento de presentarse alguna de las situaciones mencionadas anteriormente, me
                comprometo a
                informar por escrito de manera detallada el conflicto de interés, mediante carta dirigida al
                representante legal de
                {{texto_empresa}}<br></p>
              <p class="text-justify"> <strong>• RESPECTO AL ORIGEN DE FONDOS.</strong></p>
              <p class="text-justify">Declaro igualmente, bajo la gravedad del juramento que los ingresos de la sociedad
                provienen de
                actividades lícitas, que ni la sociedad, ni los socios o directivos se encuentran con registro negativo
                en listados de
                prevención de lavado de activos nacionales o internacionales, ni tampoco se encuentran dentro de una de
                las dos
                categorías de lavado de activos (conversión o movimiento) y que en consecuencia, mantendrán indemne a
                {{texto_empresa}} frente a
                cualquier controversia, conflicto, perjuicio o requerimiento que ocurriese por origen de los fondos de
                los
                mencionados. Será causa de terminación anticipada de cualquier contrato, convenio, acuerdo que exista
                con {{texto_empresa}}, sin
                el reconocimiento o pago de penas a favor de la contraparte, el engaño, la información falsa o
                presentación de
                documentos falsificados que no permitan conocer la vinculación de la sociedad con actividades ilícitas
                y/o
                financiación terrorismo. De igual forma, aplicara lo mismo, si la sociedad, sus filiales, subordinadas,
                socios o sus
                representantes legales fueren investigados o condenados mediante sentencia por un juez por cualquier
                delito vinculado
                con la producción, tenencia, tráfico, proselitismo y otros actos en materia de narcóticos, encubrimiento
                y/u
                operaciones con recursos de procedencia ilícita, terrorismo y/o delincuencia organizada en el territorio
                nacional o en
                cualquier país del mundo, con el que Colombia tenga firmado tratados internacionales referentes a lo
                establecido en el
                presente párrafo o bien es mencionado en las Listas OFAC (Office Foreign Assets Control) o cualquier
                otra lista de
                naturaleza similar. Igualmente, en calidad de Representante Legal de la Sociedad arriba descrita,
                autorizo de manera
                expresa a {{texto_empresa}} a consignar los recursos por los servicios que prestamos, conforme a la
                información suministrada.<br></p>
              <p class="text-justify"> <strong>• RESPECTO AL TRATAMIENTO DE DATOS PERSONALES POR PARTE DE
                  {{texto_empresa}}</strong></p>
              <p class="text-justify">Declaro y manifiesto que: De conformidad y en cumplimiento de la Ley 1581 de 2012
                y el Decreto 1377 del 2013; he sido informado sobre las medidas de seguridad implementadas para
                garantizar la conservación y confidencialidad sobre los datos personales de la Sociedad, de mi como
                Representante Legal y como persona natural y sobre los derechos que como titular de los mismos puedo
                ejercer: Los derechos de acceso, rectificación, prueba de autorización, reserva de responder preguntas
                sobre datos sensibles o información de menor de edad, oposición y supresión (este último cuando no medie
                un deber legal o contractual que lo impida), en la forma establecida en la Política de Tratamiento de
                Datos Personales de {{texto_empresa}}, disponible a solicitud del interesado en la página web de
                {{texto_empresa}} De esta manera, autorizo de forma previa, consciente, expresa e informada a la
                {{texto_empresa}} en calidad de Responsable y/o Encargado del tratamiento la información mencionada, en
                virtud de la relación comercial existente, para que utilice los datos personales recopilados en una base
                de datos de su titularidad, con las siguientes finalidades: 1. Gestión contable, fiscal y
                administrativa; 2. Registro como cliente y/o proveedor, así como su evaluación; 3. Actualización de
                datos en los sistemas de información de la Compañía Contratante; 4. Análisis del riesgo financiero y
                operativo; 5. Dar cumplimiento y seguimiento a las obligaciones contraídas por el proveedor y/o el
                cliente; 6. Consultar en cualquier momento en cualquier entidad financiera, los datos y toda la
                información relevante para la presente vinculación, conocer su desempeño como deudor y su capacidad de
                pago, valorar el riesgo futuro y verificar el cumplimiento de sus deberes; 7. Reportar los datos de la
                Sociedad en las entidades financieras y de riesgos, directamente o por intermedio de las autoridades de
                vigilancia y control, datos tratados o sin tratar, referidos a la información mencionada anteriormente;
                8. Gestionar el cobro de las obligaciones comerciales, económicas, financieras adquiridas por parte de
                la Sociedad: 9. Todas las demás finalidades que correspondan para el desarrollo de la relación comercial
                y aquellas indicadas en la Política de Tratamiento de Datos Personales disponible a en la Página Web de
                la Sociedad.<br></p>


              <hr>
              <div class="form-row">
                <div class="form-group col-md-2">
                  <label class="text-center"><strong>CALIDAD DE QUIEN REALIZA EL REGISTRO*</strong><br></label>
                  <select id="quien_realiza_registro" name="quien_realiza_registro" class="form-control"
                    #quien_realiza_registro="ngModel" [(ngModel)]="proveedor.quien_realiza_registro"
                    required="{{quien_realiza_registro_req}}">
                    <option value="Representante legal">Representante Legal</option>
                    <option value="Delegado representante legal">Delegado Representante Legal</option>
                    <!--<option value="{{autoretenedor.value}}" *ngFor="let autoretenedor of quien_realiza_registro">{{autoretenedor.nombre}}</option>-->
                  </select>
                  <!-- <span *ngIf="quien_realiza_registro.value==='Delegado Representante Legal'">El Campo: AUTORETENEDOR - RENTA es requerido</span>-->
                </div>
                <div class="form-group col-md-2"
                  *ngIf="proveedor.quien_realiza_registro ==='Delegado representante legal'">
                  <label class="bmd-label-floating" class="text-center"><strong>Correo Electronico Representante
                      Legal*</strong><br></label>
                  <input id="email_representante_legal" name="email_representante_legal" class="form-control"
                    type="text" #email_representante_legal="ngModel" [(ngModel)]="proveedor.email_representante_legal"
                    pattern="^[_A-Za-z0-9-]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9-]+)*(.[A-Za-z]{2,4})$"
                    tooltip='Se refiere al Email del Representante Legal' required>
                  <span *ngIf="!email_representante_legal.valid && email_representante_legal.touched">El Campo: Correo
                    Electronico Representante Legal es requerido</span>
                </div>
              </div>
              <hr>

              <div class="form-row">
                <h6 style="color:rgb(148, 17, 17)">DATOS DE LA PERSONA NATURAL O REPRESENTANTE LEGAL DE LA SOCIEDAD</h6>
                <p *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="text-justify"><strong
                    class="circulo">•</strong>Personas Expuestas Políticamente
                  De conformidad con las leyes Colombianas (Persona que ocupa uno de los cargos públicos establecidos en
                  Decreto 1674 de 2016).En caso de aplicar por favor marcar la casilla correspondiente.
                </p>
              </div>
              <br>

              <div class="form-row">
                <div class="form-group col-md-2">
                  <label class="bmd-label-floating" class="text-center"><strong>Primer Nombre*</strong><br></label>
                  <input id="primer_nombre_rl" name="primer_nombre_rl" class="form-control" type="text"
                    #primer_nombre_rl="ngModel" (change)="camposRequeridos()" [(ngModel)]="proveedor.primer_nombre_rl"
                    tooltip='Primer Nombre del Representante Legal' required="{{primer_nombre_rl_req}}">
                  <span *ngIf="!primer_nombre_rl.valid && primer_nombre_rl.touched">El Campo: Primer Nombre es
                    requerido</span>
                </div>
                <div class="form-group col-md-2">
                  <label class="bmd-label-floating" class="text-center"><strong>Segundo Nombre</strong><br></label>
                  <input id="segundo_nombre_rl" name="segundo_nombre_rl" class="form-control" type="text"
                    #segundo_nombre_rl="ngModel" (change)="camposRequeridos()" [(ngModel)]="proveedor.segundo_nombre_rl"
                    tooltip='Segundo Nombre del Representante Legal' required="{{segundo_nombre_rl_req}}">
                  <span *ngIf="!segundo_nombre_rl.valid && segundo_nombre_rl.touched">El Campo: Segundo Nombre Nombre es
                    requerido</span>
                </div>
                <div class="form-group col-md-2">
                  <label class="bmd-label-floating" class="text-center"><strong>Primer Apellido*</strong><br></label>
                  <input id="primer_apellido_rl" name="primer_apellido_rl" class="form-control" type="text"
                    #primer_apellido_rl="ngModel" (change)="camposRequeridos()"
                    [(ngModel)]="proveedor.primer_apellido_rl" tooltip='Primer Apellido del Representante Legal'
                    required="{{primer_apellido_rl_req}}">
                  <span *ngIf="!primer_apellido_rl.valid && primer_apellido_rl.touched">El Campo: Primer Apellido es
                    requerido</span>
                </div>
                <div class="form-group col-md-2">
                  <label class="bmd-label-floating" class="text-center"><strong>Segundo Apellido*</strong><br></label>
                  <input id="segundo_apellido_rl" name="segundo_apellido_rl" class="form-control" type="text"
                    #segundo_apellido_rl="ngModel" (change)="camposRequeridos()"
                    [(ngModel)]="proveedor.segundo_apellido_rl" tooltip='Segundo Apellido del Representante Legal'
                    required="{{segundo_apellido_rl_req}}">
                  <span *ngIf="!segundo_apellido_rl.valid && segundo_apellido_rl.touched">El Campo: Segundo Apellido es
                    requerido</span>
                </div>
                <!-- <div class="form-group col-md-4">
                        <label class="bmd-label-floating" class="text-center"><strong>Tipo de Identificación</strong></label>
                        <select id="tipo_identificación_rl"
                            name="tipo_identificación_rl"
                            class="form-control"
                            #tipo_identificacion_rl="ngModel"
                            [(ngModel)]="proveedor.tipo_identificacion_rl" required="{{tipo_identificacion_rl_req}}">
                            <option value="Cédula Extranjería">Cédula Extranjería</option>
                        </select>
                        <span *ngIf="tipo_identificacion_rl.invalid && tipo_identificacion_rl.touched">El Campo: Tipo de Identificación es requerido</span>
                    </div>-->
                <!--tipo_identificación_rl-->
                <div class="form-group col-md-4">
                  <label class="bmd-label-floating" class="text-center "><strong>Numero del documento de identidad del
                      Representante Legal de la sociedad*</strong></label>
                  <input id="numero_identificacion_rl" name="numero_identificacion_rl" class="form-control " type="text"
                    #numero_identificacion_rl="ngModel"
                    (change)="validarCamposC(numero_identificacion_rl);camposRequeridos();"
                    [(ngModel)]="proveedor.numero_identificacion_rl" required="{{numero_identificacion_rl_req}}"
                    tooltip='Corresponde al numero de identificacion del Representante Legal.'
                    (keypress)="validarNumeroIdentificacion($event)">
                  <span *ngIf="!numero_identificacion_rl.valid && numero_identificacion_rl.touched">El Campo: Numero
                    Identificación RL es requerido</span>
                </div>

                <div *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="form-group col-md-2 text-center">
                  <label class="bmd-label-floating"><strong class="circulo">•</strong><strong>PEP
                    </strong><br></label>
                  <br>
                  <br>
                  <div class="form-check text-center">
                    <input id="pep_rl" name="pep_rl" class="form-control" class="form-check-input" type="checkbox"
                      #pep_rl="ngModel" [(ngModel)]="proveedor.pep_rl" value=""
                      tooltip='Por favor indique si es una Persona Políticamente expuesta (PEP)' id="defaultCheck1">
                  </div>
                </div>


                <div class="form-group col-md-6">
                  <label><strong>{{label_doc_rl}}</strong><br></label><br>
                  <div class="">
                    <input type="file" name="file7" id="file7" (change)="documentRepresentanteLegal($event)"
                      accept=".doc,.docx,.pdf" *ngIf="!loader_crt_replegal" class="inputfile" />
                    <div *ngIf="loader_crt_replegal">
                      <img src="../../assets/img/30.gif" />
                    </div>
                    <label for="file7" class="decorator"
                      tooltip='Documento de identificacion Representante Legal. Formato PDF' placement='right'
                      #representante>{{msn_crt_rl}}</label>
                  </div>
                  <br>
                  <br>
                  <input id="representante_legal_doc" name="representante_legal_doc" class="form-control" type="hidden"
                    #representante_legal_doc="ngModel" value="{{doc_representante_legal}}"
                    [(ngModel)]="proveedor.representante_legal_doc">
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-3" style="display: flex;align-items: center;">
                  <p>Desempeña y/o ha desempeñado un cargo publico <i>(Have you ever held a public position)</i></p>
                </div>
                <div class="col-md-2 text-center">
                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                    <tr>
                      <td>
                        <p style="font-size: 1rem;padding-top: 11px;">Si (Yes)</p>
                      </td>
                      <td>
                        <input id="cargo_publico" name="cargo_publico" type="checkbox"
                          [(ngModel)]="cargo_publico_check_si"
                          (change)="onChangeCargoPublico($event.target.value, $event.target.checked)" value="si">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style="font-size: 1rem;padding-top: 11px;">No</p>
                      </td>
                      <td>
                        <input id="cargo_publico_no" name="cargo_publico_no" type="checkbox"
                          [(ngModel)]="cargo_publico_check_no"
                          (change)="onChangeCargoPublico($event.target.value, $event.target.checked)" value="no">
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="col-md-6">
                  <p style="padding-left: 11px;">Si la respuesta es <strong>SI</strong>, indicar cargo y la fecha desde
                    la cual lo desempeña o la fecha desde la cual dejó de desempeñarlo <i>(if you answered YES, indicate
                      the time periodo in which you held the position)</i></p>
                  <div class="col-md-12" style="display: flex;" *ngIf="proveedor.ejerce_cargo_publico_rl == 'si'">
                    <div class="col-md-6">
                      <label class="text-center">Cargo</label>
                      <input id="cargo_publico_rl" name="cargo_publico_rl" class="form-control" type="text"
                        maxlength='50' #cargo_publico_rl="ngModel" [(ngModel)]="proveedor.cargo_publico_rl">
                    </div>
                    <div class="col-md-6">
                      <label class="text-center">Fecha Desempeño</label>
                      <input id="fecha_cargo_publico_rl" name="fecha_cargo_publico_rl" class="form-control" type="date"
                        #fecha_cargo_publico_rl="ngModel" [(ngModel)]="proveedor.fecha_cargo_publico_rl">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row" style="padding-top: 20px;">
                <div class="col-md-3" style="display: flex;align-items: center;">
                  <p>Maneja y/o ha manejado recursos publicos <i>(Have you ever managed or do you currently manage
                      public founds?)</i></p>
                </div>
                <div class="col-md-2 text-center">
                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                    <tr>
                      <td>
                        <p style="font-size: 1rem;padding-top: 11px;">Si (Yes)</p>
                      </td>
                      <td>
                        <input id="maneja_recurso_publico_si" name="maneja_recurso_publico_si" type="checkbox"
                          [(ngModel)]="maneja_recurso_publico_check_si"
                          (change)="onChangeManejaRecursoPublico($event.target.value, $event.target.checked)"
                          value="si">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style="font-size: 1rem;padding-top: 10px;">No</p>
                      </td>
                      <td>
                        <input id="maneja_recurso_publico_no" name="maneja_recurso_publico_no" type="checkbox"
                          [(ngModel)]="maneja_recurso_publico_check_no"
                          (change)="onChangeManejaRecursoPublico($event.target.value, $event.target.checked)"
                          value="no">
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <p style="padding-left: 15px;">Si la respuesta es <strong>SI</strong>, indicar cargo desde el cual
                    maneja los recursos publicos y la fecha desde la cual lo desempeña o la fecha desde la cual dejo de
                    desempeñar el cargo y manejar los recursos publicos <i>(If the you answered YES, indicate from which
                      position you manage(d) the public funds).</i></p>
                  <div class="col-md-12" style="display: flex;" *ngIf="proveedor.maneja_recurso_publico_rl == 'si'">
                    <div class="col-md-6">
                      <label class="text-center">Cargo</label>
                      <input id="cargo_recurso_publico_rl" name="cargo_recurso_publico_rl" class="form-control"
                        type="text" maxlength='50' #cargo_recurso_publico_rl="ngModel"
                        [(ngModel)]="proveedor.cargo_recurso_publico_rl">
                    </div>
                    <div class="col-md-6">
                      <label class="text-center">Fecha Desempeño</label>
                      <input id="fecha_recurso_publico_rl" name="fecha_recurso_publico_rl" class="form-control"
                        type="date" #fecha_recurso_publico_rl="ngModel"
                        [(ngModel)]="proveedor.fecha_recurso_publico_rl">
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <br>

              <div class="form-row">
                <div *ngIf='mostrarcampos==true'>
                  <h6>Faltan los campos requeridos:</h6>
                  <ul *ngFor="let item of prueba">
                    <li class="validacion">{{item}}</li>
                  </ul>
                </div>
              </div>

              <div class="form-row">
                <div *ngIf="!success" class="col-md-2">
                  <button class="btn btn-danger active text-white border rounded" [disabled]='deshabilitar_boton'
                    value="providerForm" type='button' (click)="camposRequeridos(1,1)">Envíar
                  </button>
                </div>

                <div *ngIf="!success" class="col-md-3">
                  <button class="btn btn-danger active text-white border rounded" value="providerForm"
                    (click)="guardarBorrador()" type="button">Guardar Borrador
                  </button>
                </div>
              </div>
              <div class="alert alert-success" role="alert" *ngIf="success">
                <h4 class="alert-heading">Registro Exitoso</h4>
                <p>Su informacion ya fue almacenada, si necesitamos mas informacion adicional nos estaremos comunicando
                  posteriormente</p>
              </div>
            </div>
          </div>
          <div #informacion_inicial style="display: none;">
            <div class="form-row">
              <div class="form-group col-md-12">
                <h5>INFORMACIÓN INICIAL</h5>
                <hr>
              </div>
            </div>
            <div class="form-row">
              <!-- <div class="form-group col-md-3">
                    <label><strong>Registro / Actualización*</strong><br></label>
                    <select id="registro_actualizacion"
                      name="registro_actualizacion"
                      class="bmd-label-floating"
                      class="form-control"
                      type="hidden"
                      #registro_actualizacion="ngModel"
                      [(ngModel)]="proveedor.registro_actualizacion"  tooltip="Por favor indique si es registro (nuevo) o actualización de información">
                    <option value="registro" selected="">Registro</option>
                    <option value="actualizacion">Actualización</option>
                    </select>
                    <span *ngIf="!registro_actualizacion.valid && registro_actualizacion.touched">Falta el campo requerido:Registro / Actualización. </span>
                </div>-->
              <div class="form-group col-md-3">
                <label class="bmd-label-floating" class="text-center" class="bmd-label-floating"><strong>Tipo de
                    Identificación Tributaria*</strong><br></label>
                <select id="tributary_id" name="tributary_id" class="form-control" #tributary_id="ngModel"
                  (change)="validarCamposC(tributary_id);" [(ngModel)]="proveedor.tributary_id"
                  required="{{tributary_id_req}}">
                  <option>- Ninguno -</option>
                  <option value="{{tipo_identificacion.id_2}}"
                    *ngFor="let tipo_identificacion of tipo_identificacion_rls">{{tipo_identificacion.nombre}}</option>
                </select>
              </div>
              <div class="form-group col-md-3" *ngIf="empresa!='brmmx'">
                <label class="bmd-label-floating" class="text-center"><strong>Número Identificación Tributaria /
                    Fiscal*</strong><br></label>
                <input #numero_tributario id="tributary_number" input-lg name="tributary_number" class="form-control"
                  (change)="validarNumero();validarProveedor(1);" type="text" (change)="camposRequeridos();"
                  #tributary_number="ngModel" [(ngModel)]="proveedor.tributary_number" pattern="[0-9]{5,15}"
                  required="{{tributary_number_req}}">
                <span *ngIf="!tributary_number.valid && tributary_number.touched">El Campo: Número Identificación
                  Tributaria es requerido.</span>
              </div>
              <div class="form-group col-md-3" [hidden]="proveedor.tributary_id!='nit'">
                <label class="bmd-label-floating" class="text-center"><strong>Dígito de
                    Verificación*</strong><br></label>
                <select class="form-control" id="digito_verificacion" name="digito_verificacion"
                  (change)="validarProveedor(0)" #dig_verifica [(ngModel)]="proveedor.digito_verificacion"
                  required="{{digito_verificacion_req}}">
                  <option value="{{digito.value}}" *ngFor="let digito of digito_verificacion">{{digito.nombre}}</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-9">
              </div>
              <div *ngIf="!success" class="col-md-offset-9 col-md-3">
                <button class="btn btn-danger active text-white border rounded" value="providerForm"
                  (click)="guardarBorrador()" type="button">Guardar Borrador
                </button>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
        <br>
        <div class="seccion_2 nacionales" #div_proveedor style="display: none;">
          <section>
            <h5> INFORMACIÓN TRIBUTARIA</h5>
            <hr><br>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="bmd-label-floating" class="text-center"><strong>Tipo de Persona*</strong><br></label>
                <select id="person_type" name="person_type" class="form-control" #person_type="ngModel"
                  (change)="camposRequeridos()" [(ngModel)]="proveedor.person_type" required="{{person_type_req}}">
                  <option>- Ninguno -</option>
                  <option value="{{tipo_persona.id}}" *ngFor="let tipo_persona of tipo_personas">{{tipo_persona.nombre}}
                  </option>
                </select>
                <span *ngIf="!person_type.valid && person_type.touched">El Campo: Tipo de Persona es requerido.</span>
              </div>

              <!-- <div class="form-group col-md-3">
                  <label class="text-center"><strong>Régimen</strong><br /></label>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="regimen_type"
                      id="regimen_type"
                      value="{{regimens[0].id}}"
                      #regimen_type="ngModel"
                      [(ngModel)]="proveedor.regimen_type" required="{{regimen_type_req}}"

                    />
                    <label class="form-check-label" for="exampleRadios1">
                      {{regimens[0].nombre}}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="autoholder_iva"
                      id="autoholder_iva"
                       value="{{regimens[2].id}}"
                      #regimen_type="ngModel"
                      [(ngModel)]="proveedor.regimen_type" required="{{regimen_type_req}}"

                    />
                    <label class="form-check-label" for="exampleRadios2">
                      {{regimens[2].nombre}}
                    </label>
                  </div>
                  <span *ngIf="!autoholder_iva.valid && autoholder_iva.touched">El Campo: RESPONSABLE - IVA es requerido</span>-->
              <!-- <small id="passwordHelpInline" class="text-muted">
                    Indique si desea prorrogar de acuerdo al tipo de novedad.
                  </small>
                </div>-->
              <div class="form-group col-md-3">
                <label class="text-center"><strong>Régimen</strong><br /></label>
                <div class="form-check" *ngFor="let regimen of regimens">
                  <input class="form-check-input" type="radio" name="regimen_type" *ngIf="regimen.nombre!='No Aplica'"
                    (change)="camposRequeridos()" value="{{regimen.id}}" #regimen_type="ngModel"
                    [(ngModel)]="proveedor.regimen_type" required="{{regimen_type_req}}" />
                  <label class="form-check-label" for="exampleRadios1">
                    <option *ngIf="regimen.nombre!='No Aplica'">{{regimen.nombre}}</option>
                  </label>
                </div>
              </div>

            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="bmd-label-floating"><strong>Municipio donde Tributa ICA*</strong><br></label>
                <select id="ciudad_tributo_ica_id" name="ciudad_tributo_ica_id" class="form-control"
                  #ciudad_tributo_ica_id="ngModel" [(ngModel)]="proveedor.ciudad_tributo_ica_id"
                  required="{{ciudad_tributo_ica_id_req}}">
                  <option value="{{ciudad.id}}" *ngFor="let ciudad of ciudad_tributa_ica ">{{ciudad.nombre}}</option>
                </select>
                <span *ngIf="!ciudad_tributo_ica_id.valid && ciudad_tributo_ica_id.touched">El Campo: Municipio donde
                  Tributa ICA es requerido.</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="bmd-label-floating" class="text-center"><strong>Gran Contribuyente*</strong><br></label>
                <select id="gran_contribuyente" name="gran_contribuyente" class="form-control"
                  #gran_contribuyente="ngModel" (change)="camposRequeridos()" [(ngModel)]="proveedor.gran_contribuyente"
                  required="{{gran_contribuyente_req}}">
                  <option value="{{contribuyente.id}}" *ngFor="let contribuyente of contribuyentes">
                    {{contribuyente.nombre}}</option>
                </select>
                <span *ngIf="!gran_contribuyente.valid && gran_contribuyente.touched">El Campo: Gran Contribuyente es
                  requerido</span>
              </div>
              <div class="form-group col-md-6">
                <label><strong>GRAN CONTRIBUYENTE - ICA*</strong><br></label>
                <select id="gran_contribuyente_ica" name="gran_contribuyente_ica" class="form-control"
                  (change)="camposRequeridos()" #gran_contribuyente_ica="ngModel"
                  [(ngModel)]="proveedor.gran_contribuyente_ica" required="{{gran_contribuyente_ica_req}}">
                  <option value="{{contribuyente.id}}" *ngFor="let contribuyente of contribuyentes">
                    {{contribuyente.nombre}}</option>
                </select>
                <span *ngIf="!gran_contribuyente_ica.valid && gran_contribuyente_ica.touched">El Campo:GRAN
                  CONTRIBUYENTE-ICA es requerido</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="text-center"><strong>AUTORETENEDOR - RENTA*</strong><br></label>
                <select id="autoholder_revenue" name="autoholder_revenue" class="form-control"
                  #autoholder_revenue="ngModel" (change)="camposRequeridos()" [(ngModel)]="proveedor.autoholder_revenue"
                  required="{{autoholder_revenue_req}}">
                  <option value="{{autoretenedor.id}}" *ngFor="let autoretenedor of autoretenedores">
                    {{autoretenedor.nombre}}</option>
                </select>
                <span *ngIf="!autoholder_revenue.valid && autoholder_revenue.touched">El Campo: AUTORETENEDOR - RENTA es
                  requerido</span>
              </div>
              <div class="form-group col-md-3">
                <label class="text-center"><strong>RESPONSABLE - IVA_</strong><br /></label>
                <div class="form-check" *ngFor="let autoretenedor of autoretenedores; index as i">
                  <input class="form-check-input" type="radio" name="autoholder_iva" id="autoholder_iva"
                    *ngIf="autoretenedor.nombre!='No aplica'" (change)="camposRequeridos()" value="{{autoretenedor.id}}"
                    #autoholder_iva="ngModel" [(ngModel)]="proveedor.autoholder_iva"
                    required="{{autoholder_iva_req}}" />
                  <label class="form-check-label" for="exampleRadios1">
                    <option *ngIf="autoretenedor.nombre!='No aplica'">{{autoretenedor.nombre}}</option>
                    <!--<span *ngIf="!autoholder_iva.valid && autoholder_iva.touched">El Campo: RESPONSABLE - IVA es requerido</span>-->
                  </label>
                </div>
                <!-- <small id="passwordHelpInline" class="text-muted">
                    Indique si desea prorrogar de acuerdo al tipo de novedad.
                  </small>-->
              </div>
              <!-- <div class="form-group col-md-6">
                    <label><strong>RESPONSABLE - IVA_</strong><br></label>
                    <select id="autoholder_iva"
                        name="autoholder_iva"
                        class="form-control"
                        #autoholder_iva="ngModel"
                        [(ngModel)]="proveedor.autoholder_iva" required="{{autoholder_iva_req}}">
                        <option value="{{autoretenedor.id}}" *ngFor="let autoretenedor of autoretenedores">{{autoretenedor.nombre}}</option>
                    </select>
                    <span *ngIf="!autoholder_iva.valid && autoholder_iva.touched">El Campo: RESPONSABLE - IVA es requerido</span>
                </div>-->
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="text-center"><strong>Fecha de FECHA DE RESOLUCIÓN DE FACTURACIÓN</strong><br></label>
                <input id="fecha_resolucion_fact" name="fecha_resolucion_fact" class="form-control" type="date"
                  #fecha_resolucion_fact="ngModel" [(ngModel)]="proveedor.fecha_resolucion_fact">
              </div>
              <!--fecha_resolucion_fact-->
              <div class="form-group col-md-6">
                <label><strong>Vigencia RESOLUCIÓN DE FACTURACIÓN</strong><br></label>
                <select id="vigencia_resolucion_fact" name="vigencia_resolucion_fact" class="form-control"
                  #vigencia_resolucion_fact="ngModel" [(ngModel)]="proveedor.vigencia_resolucion_fact">
                  <option value="{{vigencia.id}}" *ngFor="let vigencia of vigencias">{{vigencia.nombre}}</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="ng-autocomplete form-group col-md-6">
                <label class="text-center"><strong>CODIGO CIIU SERVICIO (1)</strong><br><br></label>
                <ng-autocomplete [data]="actividad$ | async" [searchKeyword]="keyciiu" [placeHolder]="valueCiiu"
                  (selected)='selectEventCiiu($event)' [itemTemplate]="itemCiiu" [notFoundTemplate]="notFoundTemplate"
                  tooltip='Si es proveedor extranjero NO aplica - registrar "0"'>
                </ng-autocomplete>
                <ng-template #itemCiiu let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
              <div class="ng-autocomplete form-group col-md-6">
                <label class="text-center"><strong>CODIGO CIIU SERVICIO (2)</strong><br><br></label>
                <ng-autocomplete [data]="actividad$ | async" [searchKeyword]="keyciiu" [placeHolder]="valueCiiu2"
                  (selected)='selectEventCiiu2($event)' [itemTemplate]="itemCiiu2" [notFoundTemplate]="notFoundTemplate"
                  tooltip='Si es proveedor extranjero NO aplica - registrar "0"'>
                </ng-autocomplete>
                <ng-template #itemCiiu2 let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
              <div class="form-group col-md-1">
                <input id="id_actividad_principal" name="id_actividad_principal" class="form-control" type="hidden"
                  (change)="camposRequeridos()" #id_actividad_principal="ngModel" value="{{codigo_ciuu}}"
                  [(ngModel)]="proveedor.id_actividad_principal">
              </div>
              <div class="form-group col-md-1">
                <input id="id_actividad_secundaria" name="id_actividad_secundaria" class="form-control" type="hidden"
                  #id_actividad_secundaria="ngModel" value="{{codigo_ciuu_2}}"
                  [(ngModel)]="proveedor.id_actividad_secundaria">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="bmd-label-floating" class="text-center"><strong>Descripción de los
                    productos*</strong><br></label>
                <textarea id="description" name="description" class="form-control" type="text"
                  (change)="camposRequeridos()" #description="ngModel" required="{{description_req}}"
                  [(ngModel)]="proveedor.description"
                  tooltip='Descripción de los productos que suministrará a la compañía que originan este registro.'></textarea>
                <span *ngIf="!description.valid && description.touched">El Campo: Descripcion es requerido.</span>
              </div>

            </div>
          </section>
          <section>
            <h5>INFORMACIÓN BANCARIA</h5>
            <hr><br>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="text-center"><strong>Banco Nacional "Colombia"*</strong><br></label>
                <select id="banco_nacional" name="banco_nacional" class="form-control" #banco_nacional="ngModel"
                  (change)="camposRequeridos()" [(ngModel)]="proveedor.banco_nacional"
                  tooltip='Se refiere a los bancos Colombianos' required="{{banco_nacional_req}}">
                  <option value="{{banco_nacional.id}}" *ngFor="let banco_nacional of bancos_nacionales">
                    {{banco_nacional.nombre}}</option>
                </select>
                <span *ngIf="!banco_nacional.valid && banco_nacional.touched">El Campo: Banco (Nacional - Colombia) es
                  requerido</span>
              </div>
              <div class="form-group col-md-6"><label class="text-center"><strong>Tipo Cuenta*</strong><br></label>
                <select id="tipo_cuenta" name="tipo_cuenta" class="form-control" #tipo_cuenta="ngModel"
                  (change)="camposRequeridos()" [(ngModel)]="proveedor.tipo_cuenta" required="{{tipo_cuenta_req}}">
                  <option value="{{tipo_cuenta.id}}" *ngFor="let tipo_cuenta of tipo_cuentas">{{tipo_cuenta.nombre}}
                  </option>
                </select>
                <span *ngIf="!tipo_cuenta.valid && tipo_cuenta.touched">El Campo: Tipo Cuenta es requerido</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6" *ngIf="codigo_pais=='CO'&& empresa!='brmmx'">
                <label class="bmd-label-floating"><strong>Número de Cuenta*</strong><br></label>
                <input id="numero_cuenta" name="numero_cuenta" class="form-control" type="text"
                  (change)="validarCamposC(numero_cuenta);camposRequeridos();" #numero_cuenta="ngModel"
                  [(ngModel)]="proveedor.numero_cuenta" required="{{numero_cuenta_req}}" pattern="[0-9-]{4,30}"
                  tooltip='Ingrese numero de cuenta Bancaria'>
                <span *ngIf="!numero_cuenta.valid && numero_cuenta.touched">El Campo: Número de Cuenta es
                  requerido</span>
              </div>
              <div class="form-group col-md-6">
                <label><strong>Banco (Internacional)</strong><br></label>
                <input id="banco_internacional" name="banco_internacional" class="form-control" type="text"
                  #banco_internacional="ngModel" (change)="camposRequeridos()"
                  [(ngModel)]="proveedor.banco_internacional" tooltip='Corresponde al banco de su país.'>
              </div>
              <div class="form-group col-md-6">
                <label class="bmd-label-floating" class="text-center"><strong>Número SWIFT</strong><br></label>
                <input id="numero_swift" name="numero_swift" class="form-control" type="text" #numero_swift="ngModel"
                  [(ngModel)]="proveedor.numero_swift" tooltip='Para proveedor extranjero se requiere esta información'>
              </div>
            </div>
          </section>
          <!--seccion Informacion Bancaria Para Proveedores Internacionales 01/10/2020 -->
          <br>
          <section>
            <h5>DOCUMENTOS DE SOPORTE</h5>
            <hr><br>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label><strong>COPIA DEL RUT / DOCUMENTO FISCAL*</strong><br></label><br>
                <div class="">
                  <input type="file" name="file1" id="file1" (change)="documentRUT($event)" accept=".pdf"
                    *ngIf="!loader_crt_rut" class="inputfile" />
                  <div *ngIf="loader_crt_rut">
                    <img src="../../assets/img/30.gif" />
                  </div>
                  <label for="file1" class="decorator"
                    tooltip='Con fecha de expedición posterior al primero de enero de 2.013. Formato PDF. Tamaño archivo maximo 5 MB'
                    placement='right' #rut>{{msn_crt_rut}}</label>
                </div>
                <br>
                <br>
                <input id="rut_doc" name="rut_doc" class="form-control" type="hidden" #rut_doc="ngModel"
                  value="{{doc_rut}}" [(ngModel)]="proveedor.rut_doc">
              </div>
              <div class="form-group col-md-6">
                <label><strong>CERTIFICADO DE REPRESENTACIÓN LEGAL*</strong><br></label><br>
                <div class="">
                  <input type="file" name="file2" id="file2" (change)="documentCertRepresentanteLegal($event)"
                    accept=".pdf" *ngIf="!loader_crt_rep" class="inputfile" />
                  <div *ngIf="loader_crt_rep">
                    <img src="../../assets/loading.gif" alingh="center" />
                  </div>
                  <label for="file2" class="decorator" tooltip='Documento que otorga la capacidad de obligar la compañía con sus clientes
                      ' placement='right' #rep>{{msn_crt_rep}}</label>
                </div>
                <br>
                <br>
                <input id="certificado_representante_doc" name="certificado_representante_doc" class="form-control"
                  type="hidden" #certificado_representante_doc="ngModel" value="{{cert_representante_legal_doc}}"
                  [(ngModel)]="proveedor.certificado_representante_doc">
              </div>


            </div>
          </section>
          <!--adjuntos-->
          <div class="form-row">
            <div class="form-group col-md-6"><label><strong>CERTIFICACIÓN DE CUENTA BANCARIA / SWIFT*</strong><br></label><br>
              <div class="">
                <input type="file" name="file3" id="file3" (change)="documentCertificacionCuenta($event)" accept=".pdf"
                  *ngIf="!loader_crt_cuenta" class="inputfile" />
                <div *ngIf="loader_crt_cuenta">
                  <img src="../../assets/loading.gif" />
                </div>
                <label for="file3" class="decorator"
                  tooltip='Certificación expedida por la entidad bancaria respecto la cuenta a la cual se le realizara el pago de las facturas emitidas. Expedición no superior a 30 días'
                  placement='right' #account>{{msn_crt_cuenta}}</label>
              </div>
              <br>
              <br>
              <input id="certificado_cuenta_doc" name="certificado_cuenta_doc" class="form-control" type="hidden"
                #certificado_cuenta_doc="ngModel" value="{{certificacion_cuenta_doc}}"
                [(ngModel)]="proveedor.certificado_cuenta_doc">
            </div>
            <!-- <div class="form-group col-md-6">
              <label><strong>RESOLUCIÓN DE FACTURACIÓN VIGENTE</strong><br></label><br>
              <div class="">
                <input type="file" name="file4" id="file4" (change)="documentResFacturaVigente($event)" accept=".pdf"
                  *ngIf="!loader_crt_fact" class="inputfile" />
                <div *ngIf="loader_crt_fact">
                  <img src="../../assets/loading.gif" />
                </div>
                <label for="file4" class="decorator"
                  tooltip='Aplíca para personas Jurídicas de Régimen Común. Debe estar vigente. Formato PDF. Tamaño archivo maximo 5 MB'
                  placement='right' #fact>{{msn_crt_fact}}</label>
                <span *ngIf="!facturacion_vigente_doc.valid  && person_type.value=='juridical'">El Campo:RESOLUCIÓN DE
                  FACTURACIÓN VIGENTE es requerido</span>
              </div>
              <br>
              <br>
              <input id="facturacion_vigente_doc" name="facturacion_vigente_doc" class="form-control" type="hidden"
                #facturacion_vigente_doc="ngModel" value="{{res_fact_vigente_doc}}"
                [(ngModel)]="proveedor.facturacion_vigente_doc">
            </div> -->
            <div class="form-group col-md-6">
              <label><strong>CERTIFICACIÓN - PCI DSS</strong><br></label><br>
              <div class="doc_certificacion_pci">
                <input type="file" name="file0" id="file0" (change)="documentCertificacionPCI($event)" accept=".pdf"
                  *ngIf="!loader_crt_pci" class="inputfile" />
                <div *ngIf="loader_crt_pci">
                  <img src="../../assets/img/30.gif" />
                </div>
                <label for="file0" class="decorator" #pci
                  tooltip=' Si usted es proveedor tecnologico y en razon a sus servicios almacena, procesa o transmite datos de tarjeta de crédito, deberá anexar la Certificación PCI DSS vigente. Tamaño archivo maximo 5 MB'
                  placement="right">{{msn_crt_pci}}</label>
              </div>
              <br>
              <br>
              <input id="certificacion_pci" name="certificacion_pci" class="form-control" type="hidden"
                #certificacion_pci="ngModel" value="{{certificacion_pci_doc}}"
                [(ngModel)]="proveedor.certificacion_pci">
            </div>
            <!-- Este  codigo:  [required]="person_type.value=='juridical'",  va dentro del input despues de  [(ngModel)]="proveedor.facturacion_vigente_doc" cuando se requiera de nuevo que cuando el proveedor es juridico sea el documento requerido-->
            <div class="container-fluid">
              <div [formGroup]="registerPartner">
                <div *ngIf="mostrar_labels_socios">
                  <h4 class="col-md-12 text-center">Socio / Beneficiario Final</h4>
                  <hr>
                  <div class="form-row">
                    <p class="text-justify"><span class="spinner-border text-danger spinner-border-sm"></span>Por favor
                      registrar la información de los BENEFICIARIOS FINALES de la entidad con participación igual o
                      mayor al 5% (Si no los registra en este espacio, debe adjuntar una certificación con la lista que
                      contenga nombre, tipo y número de identificación y participación (%) participación, suscrita por
                      el Representante Legal)
                    </p>
                    <ul *ngIf="codigo_pais!='MX' && empresa!='brmmx'" style="list-style-type:circle color=red"
                      class="text-justify">
                      <li>Personas Expuestas Políticamente
                        De conformidad con las leyes Colombianas (Persona que ocupa uno de los cargos públicos
                        establecidos en Decreto 1674 de 2016).En caso de aplicar por favor marcar la casilla
                        correspondiente.</li>
                    </ul>
                  </div>
                  <hr style="background-color: #dcdcdd">
                  <div class="form-row">
                    <div class="form-group col " *ngFor="let nombre of nombresLabels">
                      <label
                        class="bmd-label-floating  text-align: center;"><strong>{{nombre.nombres}}</strong><br></label>
                    </div>
                    <div *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="form-group col">
                      <label class="bmd-label-floating  text-align: center;"><strong
                          class="circulo">•</strong><strong>PEP</strong><br></label>
                    </div>
                  </div>
                </div>
                <div formArrayName="socios" *ngFor="let socio of socios.controls.slice(0, 5); let i = index">
                  <div class="form-row">
                    <div class="form-group col" *ngIf="i>0">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="removerSocio(i)">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div class="form-row" [formGroupName]="i">
                    <div class="form-group col ">
                      <select class="form-control" formControlName="tipo_identificacion"
                        (change)="socioTipoId($event,i)">
                        <option value="{{tipo_identificacion.id}}"
                          *ngFor="let tipo_identificacion of tipo_identificacion_rls">{{tipo_identificacion.nombre}}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col">
                      <input class="form-control" type="text" formControlName="numero_identificacion"
                        (keypress)="validarNumeroIdentificacion($event)">
                    </div>
                    <div class="form-group col">
                      <input class="form-control" type="text" formControlName="primer_nombre">
                    </div>
                    <div class="form-group col ">
                      <input class="form-control " type="text" formControlName="segundo_nombre">
                    </div>
                    <div class="form-group col ">
                      <input class="form-control " type="text" formControlName="primer_apellido">
                    </div>
                    <div class="form-group col ">
                      <input class="form-control " type="text" formControlName="segundo_apellido">
                    </div>
                    <div *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="form-group col">
                      <div class="form-check  text-align: center;">
                        <input class="form-check-input" type="checkbox" value="0" formControlName="pep">
                      </div>
                    </div>

                  </div>
                  <hr style="background-color: #dcdcdd">
                </div>
                <div class="form-row" *ngIf="boton_agregar_socio">
                  <div class="adicionar-socio" class="col-md-3">
                    <button class="btn btn-danger active text-white border rounded" value="agregaSocio"
                      (click)="agregarSocio(1)" type="button">Agregar
                    </button>
                  </div>
                  <!--<div class="guardar-socio" class="col-md-3">
                      <button class="btn btn-success active text-white border rounded" value="guardaSocio" (click)="guardarSocio()" type="button">Guardar Socio
                        </button>
                  </div>-->
                </div>
              </div>
              <hr>
            </div>
            <div class="container-fluid">
              <p class="text-center"> <strong>DECLARACIÓN</strong></p>
              <p class="text-justify"> En calidad de Representante Legal y/o apoderado y/o autorizado de la Sociedad
                arriba suscrita,
                declaro y manifiesto expresamente que toda la información consignada en el presente
                registro es veraz, cierta y autentica. Igualmente, declaro:<br></p>
              <p class="text-justify"> <strong>• RESPECTO A POTENCIALES CONFLICTOS DE INTERÉS.</strong></p>
              <p class="text-justify">Declaro que ningún empleado, directivo, miembro de junta directiva o socio de la
                sociedad a
                quien represento, ni sostienen vínculo alguno de cónyuge o compañero permanente, familiar hasta el
                cuarto grado de
                consanguinidad, segundo de afinidad y primero civil con el personal administrativo y directivo de
                {{texto_empresa}}; ni ninguno
                de ellos dará, ofrecerá o prometerá, directa o indirectamente, ninguna dádiva, dinero, beneficio o
                ventaja, a los
                empleados, asesores o directivos de {{texto_empresa}} De igual forma, en caso de configurarse una
                situación de conflicto de
                intereses, conforme con las declaraciones aquí expuestas, {{texto_empresa}} se encuentra en la facultad
                de dar por terminada la
                relación comercial y/o contrato, sin que se cause derecho de indemnización alguna en favor del cliente
                y/o proveedor,
                y en todo caso, en el evento de presentarse alguna de las situaciones mencionadas anteriormente, me
                comprometo a
                informar por escrito de manera detallada el conflicto de interés, mediante carta dirigida al
                representante legal de
                {{texto_empresa}}<br></p>
              <p class="text-justify"> <strong>• RESPECTO AL ORIGEN DE FONDOS.</strong></p>
              <p class="text-justify">Declaro igualmente, bajo la gravedad del juramento que los ingresos de la sociedad
                provienen de
                actividades lícitas, que ni la sociedad, ni los socios o directivos se encuentran con registro negativo
                en listados de
                prevención de lavado de activos nacionales o internacionales, ni tampoco se encuentran dentro de una de
                las dos
                categorías de lavado de activos (conversión o movimiento) y que en consecuencia, mantendrán indemne a
                {{texto_empresa}} frente a
                cualquier controversia, conflicto, perjuicio o requerimiento que ocurriese por origen de los fondos de
                los
                mencionados. Será causa de terminación anticipada de cualquier contrato, convenio, acuerdo que exista
                con {{texto_empresa}}, sin
                el reconocimiento o pago de penas a favor de la contraparte, el engaño, la información falsa o
                presentación de
                documentos falsificados que no permitan conocer la vinculación de la sociedad con actividades ilícitas
                y/o
                financiación terrorismo. De igual forma, aplicara lo mismo, si la sociedad, sus filiales, subordinadas,
                socios o sus
                representantes legales fueren investigados o condenados mediante sentencia por un juez por cualquier
                delito vinculado
                con la producción, tenencia, tráfico, proselitismo y otros actos en materia de narcóticos, encubrimiento
                y/u
                operaciones con recursos de procedencia ilícita, terrorismo y/o delincuencia organizada en el territorio
                nacional o en
                cualquier país del mundo, con el que Colombia tenga firmado tratados internacionales referentes a lo
                establecido en el
                presente párrafo o bien es mencionado en las Listas OFAC (Office Foreign Assets Control) o cualquier
                otra lista de
                naturaleza similar. Igualmente, en calidad de Representante Legal de la Sociedad arriba descrita,
                autorizo de manera
                expresa a {{texto_empresa}} a consignar los recursos por los servicios que prestamos, conforme a la
                información suministrada.<br></p>
              <p class="text-justify"> <strong>• RESPECTO AL TRATAMIENTO DE DATOS PERSONALES POR PARTE DE
                  {{texto_empresa}}</strong></p>
              <p class="text-justify">Declaro y manifiesto que: De conformidad y en cumplimiento de la Ley 1581 de 2012
                y el Decreto 1377 del 2013; he sido informado sobre las medidas de seguridad implementadas para
                garantizar la conservación y confidencialidad sobre los datos personales de la Sociedad, de mi como
                Representante Legal y como persona natural y sobre los derechos que como titular de los mismos puedo
                ejercer: Los derechos de acceso, rectificación, prueba de autorización, reserva de responder preguntas
                sobre datos sensibles o información de menor de edad, oposición y supresión (este último cuando no medie
                un deber legal o contractual que lo impida), en la forma establecida en la Política de Tratamiento de
                Datos Personales de {{texto_empresa}}, disponible a solicitud del interesado en la página web de
                {{texto_empresa}}. De esta manera, autorizo de forma previa, consciente, expresa e informada a la
                {{texto_empresa}} en calidad de Responsable y/o Encargado del tratamiento la información mencionada, en
                virtud de la relación comercial existente, para que utilice los datos personales recopilados en una base
                de datos de su titularidad, con las siguientes finalidades: 1. Gestión contable, fiscal y
                administrativa; 2. Registro como cliente y/o proveedor, así como su evaluación; 3. Actualización de
                datos en los sistemas de información de la Compañía Contratante; 4. Análisis del riesgo financiero y
                operativo; 5. Dar cumplimiento y seguimiento a las obligaciones contraídas por el proveedor y/o el
                cliente; 6. Consultar en cualquier momento en cualquier entidad financiera, los datos y toda la
                información relevante para la presente vinculación, conocer su desempeño como deudor y su capacidad de
                pago, valorar el riesgo futuro y verificar el cumplimiento de sus deberes; 7. Reportar los datos de la
                Sociedad en las entidades financieras y de riesgos, directamente o por intermedio de las autoridades de
                vigilancia y control, datos tratados o sin tratar, referidos a la información mencionada anteriormente;
                8. Gestionar el cobro de las obligaciones comerciales, económicas, financieras adquiridas por parte de
                la Sociedad: 9. Todas las demás finalidades que correspondan para el desarrollo de la relación comercial
                y aquellas indicadas en la Política de Tratamiento de Datos Personales disponible a en la Página Web de
                la Sociedad.<br></p>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label class="bmd-label-floating" class="text-center"><strong>Conflicto de
                      Interés*</strong><br></label>
                  <textarea id="conflicto_interes" name="conflicto_interes"
                    class="form-control form-control-lg col-md-12" #conflicto_interes="ngModel"
                    [(ngModel)]="proveedor.conflicto_interes"
                    tooltip='Aquí podrá indicar que conflicto ha identificado'></textarea>
                </div>
              </div>
              <hr>
              <div class="form-row">
                <div class="form-group col-md-2">
                  <label class="text-center"><strong>CALIDAD DE QUIEN REALIZA EL REGISTRO*</strong><br></label>
                  <select id="quien_realiza_registro" name="quien_realiza_registro" class="form-control"
                    #quien_realiza_registro="ngModel" [(ngModel)]="proveedor.quien_realiza_registro"
                    required="{{quien_realiza_registro_req}}">
                    <option value="Representante legal">Representante Legal</option>
                    <option value="Delegado representante legal">Delegado Representante Legal</option>
                    <!--<option value="{{autoretenedor.value}}" *ngFor="let autoretenedor of quien_realiza_registro">{{autoretenedor.nombre}}</option>-->
                  </select>
                  <!-- <span *ngIf="quien_realiza_registro.value==='Delegado Representante Legal'">El Campo: AUTORETENEDOR - RENTA es requerido</span>-->
                </div>

                <div class="form-group col-md-2"
                  *ngIf="proveedor.quien_realiza_registro ==='Delegado representante legal'">
                  <label class="bmd-label-floating" class="text-center"><strong>Correo Electronico Representante
                      Legal*</strong><br></label>
                  <input id="email_representante_legal" name="email_representante_legal" class="form-control"
                    type="text" #email_representante_legal="ngModel" [(ngModel)]="proveedor.email_representante_legal"
                    pattern="^[_A-Za-z0-9-]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9-]+)*(.[A-Za-z]{2,4})$"
                    tooltip='Se refiere al Email del Representante Legal' required>
                  <span *ngIf="!email_representante_legal.valid && email_representante_legal.touched">El Campo: Correo
                    Electronico Representante Legal es requerido</span>
                </div>

              </div>
              <hr>

              <div class="form-row">
                <h6 style="color:rgb(148, 17, 17)">DATOS DE LA PERSONA NATURAL O REPRESENTANTE LEGAL DE LA SOCIEDAD</h6>
                <p *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="text-justify"><strong
                    class="circulo">•</strong>Personas Expuestas Políticamente
                  De conformidad con las leyes Colombianas (Persona que ocupa uno de los cargos públicos establecidos en
                  Decreto 1674 de 2016).En caso de aplicar por favor marcar la casilla correspondiente.
                </p>
              </div>
              <br>

              <div class="form-row">
                <div class="form-group col-md-2">
                  <label class="bmd-label-floating" class="text-center"><strong>Primer Nombre*</strong><br></label>
                  <input id="primer_nombre_rl" name="primer_nombre_rl" class="form-control" type="text"
                    #primer_nombre_rl="ngModel" (change)="camposRequeridos()" [(ngModel)]="proveedor.primer_nombre_rl"
                    tooltip='Primer Nombre del Representante Legal' required="{{primer_nombre_rl_req}}">
                  <span *ngIf="!primer_nombre_rl.valid && primer_nombre_rl.touched">El Campo: Primer Nombre es
                    requerido</span>
                </div>
                <div class="form-group col-md-2">
                  <label class="bmd-label-floating" class="text-center"><strong>Segundo Nombre</strong><br></label>
                  <input id="segundo_nombre_rl" name="segundo_nombre_rl" class="form-control" type="text"
                    #segundo_nombre_rl="ngModel" (change)="camposRequeridos()" [(ngModel)]="proveedor.segundo_nombre_rl"
                    tooltip='Segundo Nombre del Representante Legal' required="{{segundo_nombre_rl_req}}">
                  <span *ngIf="!segundo_nombre_rl.valid && segundo_nombre_rl.touched">El Campo: Segundo Nombre Nombre es
                    requerido</span>
                </div>
                <div class="form-group col-md-2">
                  <label class="bmd-label-floating" class="text-center"><strong>Primer Apellido*</strong><br></label>
                  <input id="primer_apellido_rl" name="primer_apellido_rl" class="form-control" type="text"
                    #primer_apellido_rl="ngModel" (change)="camposRequeridos()"
                    [(ngModel)]="proveedor.primer_apellido_rl" tooltip='Primer Apellido del Representante Legal'
                    required="{{primer_apellido_rl_req}}">
                  <span *ngIf="!primer_apellido_rl.valid && primer_apellido_rl.touched">El Campo: Primer Apellido es
                    requerido</span>
                </div>
                <div class="form-group col-md-2">
                  <label class="bmd-label-floating" class="text-center"><strong>Segundo Apellido*</strong><br></label>
                  <input id="segundo_apellido_rl" name="segundo_apellido_rl" class="form-control" type="text"
                    #segundo_apellido_rl="ngModel" (change)="camposRequeridos()"
                    [(ngModel)]="proveedor.segundo_apellido_rl" tooltip='Segundo Apellido del Representante Legal'
                    required="{{segundo_apellido_rl_req}}">
                  <span *ngIf="!segundo_apellido_rl.valid && segundo_apellido_rl.touched">El Campo: Segundo Apellido es
                    requerido</span>
                </div>

                <div class="form-group col-md-2">
                  <label class="bmd-label-floating" class="text-center"><strong>Tipo de Identificación*</strong></label>
                  <select id="tipo_identificación_rl" name="tipo_identificación_rl" class="form-control"
                    #tipo_identificacion_rl="ngModel" (change)="camposRequeridos()"
                    [(ngModel)]="proveedor.tipo_identificacion_rl" required="{{tipo_identificacion_rl_req}}">
                    <option value="{{tipo_identificacion.id}}"
                      *ngFor="let tipo_identificacion of tipo_identificacion_rls">{{tipo_identificacion.nombre}}
                    </option>
                  </select>
                  <span *ngIf="!tipo_identificacion_rl.valid && tipo_identificacion_rl.touched">El Campo: Tipo de
                    Identificación es requerido</span>
                </div>
                <!--tipo_identificación_rl-->
                <div class="form-group col-md-2" *ngIf="codigo_pais==='CO'">
                  <label class="bmd-label-floating" class="text-center"><strong>Numero Identificación
                      RL*</strong><br></label>
                  <input id="numero_identificacion_rl" name="numero_identificacion_rl" class="form-control" type="text"
                    (keypress)="validarNumeroIdentificacion($event)"
                    (change)="validarCamposC(numero_identificacion_rl);camposRequeridos();"
                    #numero_identificacion_rl="ngModel" [(ngModel)]="proveedor.numero_identificacion_rl"
                    required="{{numero_identificacion_rl_req}}" maxlength="13"
                    tooltip='Corresponde al numero de identificacion del Representante Legal.'>
                  <span *ngIf="!numero_identificacion_rl.valid && numero_identificacion_rl.touched">El Campo: Numero
                    Identificación RL es requerido</span>
                </div>

                <div *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="form-group col-md-2 text-center">
                  <label class="bmd-label-floating"><strong class="circulo">•</strong><strong>PEP
                    </strong><br></label>
                  <br>
                  <div class="form-check text-center">
                    <input id="pep_rl" name="pep_rl" class="form-control" class="form-check-input" type="checkbox"
                      #pep_rl="ngModel" [(ngModel)]="proveedor.pep_rl" value=""
                      tooltip='Por favor indique si es una Persona Políticamente expuesta (PEP)' id="defaultCheck1">
                  </div>
                </div>


                <div class="form-group col-md-6">
                  <label><strong>Documento Representante Legal*</strong><br></label><br>
                  <div class="">
                    <input type="file" name="file7" id="file7" (change)="documentRepresentanteLegal($event)"
                      accept=".doc,.docx,.pdf" *ngIf="!loader_crt_replegal" class="inputfile" />
                    <div *ngIf="loader_crt_replegal">
                      <img src="../../assets/img/30.gif" />
                    </div>
                    <label for="file7" class="decorator"
                      tooltip='Documento de identificacion Representante Legal. Formato PDF' placement='right'
                      #representante>{{msn_crt_rl}}</label>
                  </div>
                  <br>
                  <br>
                  <input id="representante_legal_doc" name="representante_legal_doc" class="form-control" type="hidden"
                    #representante_legal_doc="ngModel" value="{{doc_representante_legal}}"
                    [(ngModel)]="proveedor.representante_legal_doc">
                </div>



              </div>

              <div class="form-row">
                <div class="col-md-3" style="display: flex;align-items: center;">
                  <p>Desempeña y/o ha desempeñado un cargo publico <i>(Have you ever held a public position)</i></p>
                </div>
                <div class="col-md-2 text-center">
                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                    <tr>
                      <td>
                        <p style="font-size: 1rem;padding-top: 11px;">Si (Yes)</p>
                      </td>
                      <td>
                        <input id="cargo_publico" name="cargo_publico" type="checkbox"
                          [(ngModel)]="cargo_publico_check_si"
                          (change)="onChangeCargoPublico($event.target.value, $event.target.checked)" value="si">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style="font-size: 1rem;padding-top: 11px;">No</p>
                      </td>
                      <td>
                        <input id="cargo_publico_no" name="cargo_publico_no" type="checkbox"
                          [(ngModel)]="cargo_publico_check_no"
                          (change)="onChangeCargoPublico($event.target.value, $event.target.checked)" value="no">
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="col-md-6">
                  <p style="padding-left: 11px;">Si la respuesta es <strong>SI</strong>, indicar cargo y la fecha desde
                    la cual lo desempeña o la fecha desde la cual dejó de desempeñarlo <i>(if you answered YES, indicate
                      the time periodo in which you held the position)</i></p>
                  <div class="col-md-12" style="display: flex;" *ngIf="proveedor.ejerce_cargo_publico_rl == 'si'">
                    <div class="col-md-6">
                      <label class="text-center">Cargo</label>
                      <input id="cargo_publico_rl" name="cargo_publico_rl" class="form-control" type="text"
                        maxlength='50' #cargo_publico_rl="ngModel" [(ngModel)]="proveedor.cargo_publico_rl"
                        required="{{cargo_publico_rl_req}}">
                      <span *ngIf="!cargo_publico_rl.valid && cargo_publico_rl.touched">El Campo: Cargo es
                        requerido</span>
                    </div>
                    <div class="col-md-6">
                      <label class="text-center">Fecha Desempeño</label>
                      <input id="fecha_cargo_publico_rl" name="fecha_cargo_publico_rl" class="form-control" type="date"
                        #fecha_cargo_publico_rl="ngModel" [(ngModel)]="proveedor.fecha_cargo_publico_rl"
                        required="{{fecha_cargo_publico_rl_req}}">
                      <span *ngIf="!fecha_cargo_publico_rl.valid && fecha_cargo_publico_rl.touched">El Campo: Numero
                        Fecha Desempeño es requerido</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row" style="padding-top: 20px;">
                <div class="col-md-3" style="display: flex;align-items: center;">
                  <p>Maneja y/o ha manejado recursos publicos <i>(Have you ever managed or do you currently manage
                      public founds?)</i></p>
                </div>
                <div class="col-md-2 text-center">
                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                    <tr>
                      <td>
                        <p style="font-size: 1rem;padding-top: 11px;">Si (Yes)</p>
                      </td>
                      <td>
                        <input id="maneja_recurso_publico_si" name="maneja_recurso_publico_si" type="checkbox"
                          [(ngModel)]="maneja_recurso_publico_check_si"
                          (change)="onChangeManejaRecursoPublico($event.target.value, $event.target.checked)"
                          value="si">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style="font-size: 1rem;padding-top: 10px;">No</p>
                      </td>
                      <td>
                        <input id="maneja_recurso_publico_no" name="maneja_recurso_publico_no" type="checkbox"
                          [(ngModel)]="maneja_recurso_publico_check_no"
                          (change)="onChangeManejaRecursoPublico($event.target.value, $event.target.checked)"
                          value="no">
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <p style="padding-left: 15px;">Si la respuesta es <strong>SI</strong>, indicar cargo desde el cual
                    maneja los recursos publicos y la fecha desde la cual lo desempeña o la fecha desde la cual dejo de
                    desempeñar el cargo y manejar los recursos publicos <i>(If the you answered YES, indicate from which
                      position you manage(d) the public funds).</i></p>
                  <div class="col-md-12" style="display: flex;" *ngIf="proveedor.maneja_recurso_publico_rl == 'si'">
                    <div class="col-md-6">
                      <label class="text-center">Cargo</label>
                      <input id="cargo_recurso_publico_rl" name="cargo_recurso_publico_rl" class="form-control"
                        type="text" maxlength='50' #cargo_recurso_publico_rl="ngModel"
                        [(ngModel)]="proveedor.cargo_recurso_publico_rl" required="{{cargo_recurso_publico_rl_req}}">
                      <span *ngIf="!cargo_recurso_publico_rl.valid && cargo_recurso_publico_rl.touched">El Campo: Cargo
                        es requerido</span>
                    </div>
                    <div class="col-md-6">
                      <label class="text-center">Fecha Desempeño</label>
                      <input id="fecha_recurso_publico_rl" name="fecha_recurso_publico_rl" class="form-control"
                        type="date" #fecha_recurso_publico_rl="ngModel" [(ngModel)]="proveedor.fecha_recurso_publico_rl"
                        required="{{fecha_recurso_publico_rl_req}}">
                      <span *ngIf="!fecha_recurso_publico_rl.valid && fecha_recurso_publico_rl.touched">El Campo: Numero
                        Fecha Desempeño es requerido</span>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <br>



              <div class="form-row">
                <div *ngIf="prueba!=''">
                  <strong class="validacion">Faltan los campos requeridos:</strong>
                  <ul *ngFor="let item of prueba">
                    <li class="validacion">{{item}}</li>
                  </ul>
                </div>
              </div>

              <div class="form-row">
                <div *ngIf="!success" class="col-md-2">
                  <button class="btn btn-danger active text-white border rounded" [disabled]='deshabilitar_boton'
                    value="providerForm" type="button" (click)="camposRequeridos(1,1)">Envíar
                  </button>
                </div>
                <div *ngIf="!success" class="col-md-3">
                  <button class="btn btn-danger active text-white border rounded" value="providerForm"
                    (click)="guardarBorrador()" type="button">Guardar Borrador
                  </button>
                </div>

              </div>
              <div class="alert alert-success" role="alert" *ngIf="success">
                <h4 class="alert-heading">Registro Exitoso</h4>
                <p>Su informacion ya fue almacenada, si necesitamos mas informacion adicional nos estaremos comunicando
                  posteriormente</p>
              </div>
            </div>
            <!--*[disabled]="providerForm.invalid"*/-->
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #modalSocios>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{titulo_modal_socios}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalSocios()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p [innerHTML]="texto_modal_socios"></p>
  </div>
</ng-template>
