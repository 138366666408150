import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { global } from './global';
import {Novedades } from '../models/novedades';



@Injectable()
export class NovedadesService{
	public url: string;

	constructor(
	  	public _http: HttpClient
	){
		this.url = global.url;
  }
  create(Novedades,tipo_guardado='registro',empresa ='brm'): Observable<any>{
		let json = JSON.stringify(Novedades);
		let params = 'json='+json+'&tipo_guardado='+tipo_guardado+'&empresa='+empresa;
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.post(this.url+'novedades',params, {headers: headers});
	}
	getListas(tipo): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get(this.url+'novedades/listas/'+tipo, {headers: headers});
  }
  getListasEps(eps): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get(this.url+'novedades/listas/'+eps, {headers: headers});
  }
  soportes(consecutivo,file){
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
  return this._http.get(`${this.url}novedades/${consecutivo}/${file}`, {headers: headers});
  }
  subirDocumento(datos:any,consecutivo,empresa):Observable<any>{
    return this._http.post(this.url+'novedades/upload/'+consecutivo+'/'+empresa, datos);
  }

  getNovedad(id, empresa):Observable<any>{
  let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
  return this._http.get(this.url+'novedadesOne/'+id+'/'+empresa, {headers: headers});
  }

getConsecutivo(empresa){
  let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
  return this._http.get(this.url+'novedades/consecutivo/'+empresa, {headers: headers});
  }
  getContratacion(empresa):Observable<any[]>{
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get<any[]>(this.url+'novedades/contrataciones/'+empresa, {headers: headers});
  }
  validarNovedad(contratacion_id, tipo_novedad,empresa): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
		return this._http.get(this.url+'novedades/validar/'+contratacion_id+'/'+tipo_novedad+'/'+empresa, {headers: headers});
	}


}
