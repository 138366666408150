export class Novedades{
  constructor(
    public id:string,
    public compania:string,
    public cedula:string,
    public tipo_novedad:string,
    public eps:string,
    public numero_incapacidad:string,
    public incapacidad_prorroga:string,
    public numero_incapacidad_prorroga:string,
    public inicio_novedad:string,
    public final_novedad:string,
    public radicacion_novedad:string,
    public diagnostico:string,
    public prorroga:string,
    public registro_kactus:string,
    public soporte_novedad:string,
    public soporte_novedad_adicional:string,
    public observaciones:string,
    public contratacion_id:string,
    public contratacion:string,
    public estado_contratacion:string,
    public centro_costo:string,
    public subcentro_costo:string,
    public campania:string,
    public campania_id:string,
    public telefono:string,
    public tipo_vinculacion:string,
    public consecutivo:string,
    public creado_por:string,
    public estado:string






  ){

  }
}
