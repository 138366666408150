import { Component, OnInit, Renderer2, ViewChild,ElementRef, ɵSWITCH_ELEMENT_REF_FACTORY__POST_R3__} from '@angular/core';
import { FormBuilder, FormArray,Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import{Proveedor, Requeridos} from '../../models/providers';
import{tributario,tipo_persona, regimen, vigencia, contribuyente, autoretenedor,tipo_cuenta, tipo_identificacion, banco_nacional,ciudad_tributa} from '../../listas/tributarios';
import {ProveedoresService} from '../../Servicios/proveedor.service';
import {Observable} from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { isNumber } from 'util';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


declare var $:any;
let requeridosArray:any=[];
let requeridosSocios:any=[];
const messages={billing_address_street:'Ingrese Dirección.',
billing_country_id:'Ingrese Ciudad.',
billing_city_id:'Ingrese Ciudad.',
id_actividad_principal:'CODIGO CIIU SERVICIO (1)',
rut_doc:'COPIA DEL RUT / DOCUMENTO FISCAL',
certificado_representante_doc:'CERTIFICADO DE REPRESENTACIÓN LEGAL',
certificado_cuenta_doc:'CERTIFICACIÓN DE CUENTA BANCARIA / SWIFT',
documento_rl_doc:'DOCUMENTO IDENTIDAD DE REPRESENTANTE LEGAL',
rfc_doc:'RFC(Registro Federal de contribuyentes)',
phone_office:'Teléfono (1) ',
phone_alternate:'Teléfono (2)',
email_contacto_comercial:'Correo electrónico (Contacto Comercial)',
codigo_verificacion:'Codigo',
digito_verificacion:'Digito Verificacion del Nit',
firm_name:'Razón Social/Nombre Persona Natural',
contacto_comercial:'Contacto (Comercial)',
banco_internacional:'Banco Internacional',
numero_cuenta:'Número de Cuenta',
primer_nombre_rl:'Primer Nombre Representante legal*',
segundo_nombre_rl:'Segundo Nombre Representante legal',
primer_apellido_rl:'Primer Apellido Representante legal*',
segundo_apellido_rl:'segundo Apellido Representante legal*',
numero_identificacion_rl:'Numero Identificación RL*',
tributary_number:'Número Identificación Tributaria / Fiscal*',
person_type:'Tipo de Persona',
regimen_type:'Régimen',
gran_contribuyente:'Gran Contribuyente',
gran_contribuyente_ica:'GRAN CONTRIBUYENTE-ICA',
autoholder_revenue: 'AUTORETENEDOR - RENTA',
autoholder_iva:'RESPONSABLE - IVA',
description:'Descripción de los productos',
banco_nacional:'Banco (Nacional - Colombia)',
tipo_cuenta:'Tipo Cuenta',
tipo_identificacion_rl:'Tipo de Identificación',
quien_realiza_registro:'CALIDAD DE QUIEN REALIZA EL REGISTRO*',
email_representante_legal:'Correo Electronico Representante Legal*',
primer_nombre:'Primer Nombre',
ejerce_cargo_publico_rl: 'Desempeño Cargo Publico',
maneja_recurso_publico_rl: 'Manejo de Recursos Publicos',
cargo_publico_rl:'Cargo(cargo publico)',
fecha_cargo_publico_rl:'Fecha Desempeño(cargo publico)',
cargo_recurso_publico_rl:'Cargo(recursos publicos)',
fecha_recurso_publico_rl:'Fecha Desempeño(recursos publicos)'

};

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css'],
  providers:[ProveedoresService]
})

export class ProvidersComponent implements OnInit{
  modalRef: BsModalRef | null;
  modalRef2: BsModalRef;
  @ViewChild("numero_tributario") numero_tributario: ElementRef;
  @ViewChild("pci") pci: ElementRef;
  @ViewChild("rut") rut: ElementRef;
  @ViewChild("rep") rep: ElementRef;
  @ViewChild("account") account: ElementRef;
  @ViewChild("fact") fact: ElementRef;
  @ViewChild("div_proveedor") div_proveedor: ElementRef;
  @ViewChild("informacion_general") informacion_general: ElementRef;
  @ViewChild("dig_verifica") dig_verifica: ElementRef;
  @ViewChild("div_codigo_pais") div_codigo_pais: ElementRef;
  @ViewChild("informacion_inicial") informacion_inicial: ElementRef;
  @ViewChild("seccion_internacional") seccion_internacional: ElementRef;
  @ViewChild("account_int") account_int: ElementRef;
  @ViewChild("rep_int") rep_int: ElementRef;
  @ViewChild("rfc") rfc: ElementRef;
  @ViewChild("modalSocios") modalSocios: ElementRef;
  @ViewChild("representante") representante: ElementRef;


  public requeridos:Requeridos;
  public proveedor:Proveedor;
  public doc_representante_legal='';
  public texto_spinner = 'Cargando...';
  public registro;
  public indicativo_phone_office;
  public indicativo_phone_alternate;
  public phone_office;
  public tributarios:Array<tributario>;
  public tipo_personas:Array<tipo_persona>;
  public regimens:Array<regimen>;
  public contribuyentes:Array<contribuyente>;
  public vigencias:Array<vigencia>;
  public autoretenedores:Array<autoretenedor>;
  public tipo_cuentas:Array<tipo_cuenta>;
  public tipo_identificacion_rls:Array<tipo_identificacion>;
  public bancos_nacionales:Array<banco_nacional>;
  public ciudad_tributa_ica: Array<ciudad_tributa>;
  public keyword = 'ciudad';
  public valueCiiu = 'Digita Codigo Ciuu Principal';
  public valueCiiu2 = 'Digita Codigo Ciuu 2';
  public valueCity = 'Digita la Ciudad';
  public keyciiu = 'name';
  public data$:Observable<any[]>;
  public actividad$:Observable<any[]>;
  public pais='';
  public concatedNames='';
  public ciudad='';
  public codigo_ciuu= '';
  public codigo_ciuu_2='';
  public digito_verificacion:any=[
   {value:'', nombre:''},
  {value:'1' ,nombre:'1'},
  {value:'2' ,nombre:'2'},
  {value:'3' ,nombre:'3'},
  {value:'4' ,nombre:'4'},
  {value:'5' ,nombre:'5'},
  {value:'6' ,nombre:'6'},
  {value:'7' ,nombre:'7'},
  {value:'8' ,nombre:'8'},
  {value:'9' ,nombre:'9'},
  {value:'0' ,nombre:'0'}
  ];
  public certificacion_pci_doc='';
  public doc_rut = '';
  public res_fact_vigente_doc = '';
  public certificacion_cuenta_doc = '';
  public documento_rfc='';
  public documento_identidad_rl='';
  public cert_representante_legal_doc = '';
  public ciudad_invalida = true;
  public loader_crt_pci:Boolean = false;
  public msn_crt_pci='Seleccione el Archivo';
  public msn_crt_rfc='Seleccione el Archivo'
  public loader_crt_rep:Boolean = false;
  public loader_crt_rl:Boolean = false;
  public loader_crt_rfc:Boolean = false;
  public loader_crt_replegal:Boolean = false;
  public msn_crt_rep='Seleccione el Archivo';
  public msn_doc_rl='Seleciones el Archivo';
  public loader_crt_cuenta:Boolean = false;
  public msn_crt_cuenta='Seleccione el Archivo';
  public loader_crt_rut:Boolean = false;
  public msn_crt_rut='Seleccione el Archivo';
  public loader_crt_fact:Boolean = false;
  public msn_crt_fact='Seleccione el Archivo';
  public msn_crt_rl='Arrastre y suelte el Archivo';
  public success:boolean=false;
  public value1: number;
  public codigo_pais='';
  public disabledcity=false;
  public spin=false;
  public prueba:any=[];
  public mostrarcampos:boolean;
  public phone_office_req=false;
  public phone_alternate_req=false;
  public billing_address_street_req=false;
  public email_contacto_comercial_req=false;
  public certificado_representante_doc_req=false;
  public certificado_cuenta_doc_req=false;
  public codigo_verificacion_req=false;
  public firm_name_req=false;
  public contacto_comercial_req=false;
  public numero_cuenta_req=false;
  public primer_nombre_rl_req=false;
  public segundo_nombre_rl_req=false;
  public primer_apellido_rl_req=false;
  public segundo_apellido_rl_req=false;
  public primer_nombre_req=false;
  public segundo_nombre_req=false;
  public primer_apellido_req=false;
  public segundo_apellido_req=false;
  public tipo_identificacion_req=false;
  public numero_identificacion_req=false;
  public numero_identificacion_rl_req=false;
  public tipo_identificacion_rl_req=false;
  public id_actividad_principal_req=false;
  public rut_doc_req=false;
  public tributary_number_req=false;
  public person_type_req=false;
  public regimen_type_req=false;
  public gran_contribuyente_req=false;
  public gran_contribuyente_ica_req=false;
  public autoholder_revenue_req=false;
  public autoholder_iva_req=false;
  public description_req=false;
  public banco_nacional_req=false;
  public tipo_cuenta_req=false;
  public digito_verificacion_req=false;
  public ciudad_tributo_ica_id_req=false;
  public tributary_id_req=false;
  public quien_realiza_registro_req=false;
  public email_representante_legal_req=false;
  public fecha_recurso_publico_rl_req=false;
  public cargo_recurso_publico_rl_req=false;
  public fecha_cargo_publico_rl_req=false;
  public cargo_publico_rl_req=false;
  public submit='';
  public texto_empresa='';
  public empresa='';
  public procedimiento = 'registrar';
  public deshabilitar_boton=false;
  public campo_switch='Número SWIFT';
  public tooltip_switch='Para proveedor extranjero se requiere esta información';
  public certificacion_cuenta='CERTIFICACIÓN DE CUENTA BANCARIA';
  public tooltip_certificacion_cuenta='Certificación expedida por la entidad bancaria respecto la cuenta a la cual se le realizara el pago de las facturas emitidas. Expedición no superior a 30 días';
  public deshabilitar_delegado=false;
  public nombresLabels: any = [
    { value: '1', nombres: 'Tipo de Identificación*' },
    { value: '2', nombres: 'Numero Identificación(Personal)/NIT*' },
    { value: '3', nombres: 'Primer Nombre/Razón Social*'},
    { value: '4', nombres: 'Segundo Nombre*' },
    { value: '5', nombres: 'Primer Apellido*' },
    { value: '6', nombres: 'Segundo Apellido*' },
  ];

  public mostrar_labels_socios=false;
  public boton_agregar_socio=true;
  public messagesSocios = {
    tipo_identificacion:'Tipo de Identificacion',
    primer_nombre: 'Primer Nombre',
    //segundo_nombre: 'Segundo Nombre',
    primer_apellido: 'Primer Apellido',
    segundo_apellido:'Segundo Apellido',
    numero_identificacion:'Numero Identificacion(Personal)',
  };
  public messagesSociosXNit = {
    tipo_identificacion:'Tipo de Identificacion',
    primer_nombre: 'Primer Nombre',
    numero_identificacion:'Numero Identificacion(Personal)',
  };
  public titulo_modal_socios = '';
  public texto_modal_socios = '';
  public camposSocios = {
    primer_nombre: 'Primer Nombre',
    segundo_nombre: 'Segundo Nombre',
    primer_apellido: 'Primer Apellido',
    segundo_apellido:'Segundo Apellido',
    tipo_identificacion:'Tipo de Identificacion',
    numero_identificacion:'Numero Identificacion(Personal)',
  };
  public dataSocios: any = [];
  public tooltip_firm_name='Nombre de la (sociedad registrado en la Cámara de Comercio / Nombre(s) y Apellido(s) de persona Natural)'
  public label_doc_rl='Documento Representante Legal*';
  public cargo_publico_check_si = false;
  public cargo_publico_check_no = false;
  public maneja_recurso_publico_check_si = false;
  public maneja_recurso_publico_check_no = false;
  public tipo_identificacion_original: any = [];
  public label_razon_social='Razón Social/Nombre Persona Natural*';

/*public contribuyentes:any=[{value:1,nombre:'Si'},{value:0 ,nombre:'No'}
]*/

//public listaTributarios = new Object();

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private _proveedoresService:ProveedoresService,
    private renderer: Renderer2,
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: BsModalService
  ) {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 4000);
    this._proveedoresService.getListas('tipo_identificacion',this.empresa).subscribe(
      response => {
        this.tipo_identificacion_original = response;
        //this.tipo_identificacion_rls=response;
      },
      error =>{
        console.log(<any>error);
      }
    );
    this.proveedor= new Proveedor('1','', '','','','', '','', '', '', '', '', '', 0, '', '', '', '', '', '', '', '','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','');
    this._route.params.subscribe(params => {
    var empresa = params['empresa'];
     var indexempresa = empresa.replace('index.php', '');
      this.empresa = indexempresa;
      console.log(this.empresa);
      switch (this.empresa) {
        case 'brm': {
          this.texto_empresa='BRM S.A.S'
          break;
        }
        case 'brmmx': {
          this.texto_empresa='BRM RELATIONSHIP MANAGEMENT SA DE CV'
          break;
        }
        case 'dar': {
          this.texto_empresa='GIVING S.A.S'
          break;
        }
        case 'heart': {
          this.texto_empresa='HEART EMOTIONAL EXPERIENCES SAS'
          break;
        }
        case 'deeploy': {
          this.texto_empresa='DEEPLOY SAS'
          break;
        }
        case 'preferente': {
          this.texto_empresa='PREFERENTE MP SAS'
          break;
        }
        case 'qat': {
          this.texto_empresa='QUATRO ATELIER SAS'
          break;
        }
        default: {
          this.texto_empresa='BRM S.A.S'
          break;
        }
      }

      if(params['procedimiento'] === "ed_"){
        this.deshabilitar_boton=true;
      }else{
        this.deshabilitar_boton=false;
      }

    });

    this._route.params.subscribe(params => {
      if (typeof params['procedimiento'] === "undefined") {
        // se mantiene procedimiento por defecto
      } else {
        this.procedimiento = params['procedimiento'];
      }
    });
  }

  get socios(){
    return this.registerPartner.get('socios') as FormArray;
  }

  registerPartner = this.formBuilder.group({
    socios: this.formBuilder.array([])
  });

  ngOnInit(): void {
    $('body').bootstrapMaterialDesign();
    this.precargarProvedor();
    this.agregarSocio();
    this.getLocation();
    this.getActividad();
    this.getListados();
    //this.proveedor= new Proveedor('1','', '','','','', '','', '', '', '', '', '', '', '', '', '', '', '', '', '', '','','','','','','','','','','','','','','','','','','','','','');
  }

  agregarSocio(adicionNueva = 0){

    console.log(this.dataSocios);
    if (Object.keys(this.dataSocios).length > 0 && adicionNueva == 0) {
      this.socios.removeAt(0);
      for (let row of this.dataSocios) {
        var deshabilitador=false;
        if (row.tipo_identificacion=='NIT' || row.tipo_identificacion == 'RFC') {
             deshabilitador=true;
        }else{
          deshabilitador=false;
        }
        if (row.pep==0 || row.pep=='' || row.pep == null) {
          row.pep = false;
        }else{
          row.pep = true;
        }
        const socioForm = this.formBuilder.group({
          id: [row.id],
          primer_nombre:[row.primer_nombre],
          segundo_nombre: [{ value:row.segundo_nombre, disabled: deshabilitador}],
          primer_apellido:  [{ value:row.primer_apellido, disabled: deshabilitador}],
          segundo_apellido:  [{ value:row.segundo_apellido, disabled: deshabilitador}],
          tipo_identificacion: [row.tipo_identificacion] ,
          numero_identificacion: [row.numero_identificacion],
          pep:[{value:row.pep, disabled: deshabilitador}]
        });
        this.socios.push(socioForm);
      }
    } else {
      console.log('else');
      const socioForm = this.formBuilder.group({
        id: [''],
        primer_nombre: [''],
        segundo_nombre: [''],
        primer_apellido: [''],
        segundo_apellido: [''],
        tipo_identificacion: [''],
        numero_identificacion: [''],
        pep:[''],
      });
      this.socios.push(socioForm);
    }
    this.mostrar_labels_socios=true;
  }

  removerSocio(indice) {
    var socioID = 'sin_guardar';
    if (this.socios.controls[indice].value.id) {
      socioID = this.socios.controls[indice].value.id;
    }
    if (socioID == 'sin_guardar') {
      this.socios.removeAt(indice);
    } else {
      this._proveedoresService.eliminarSocio(socioID,this.empresa).subscribe(
        response => {
          console.log(response);
          if (response.eliminacion == 1) {
            this.socios.removeAt(indice);
          } else {
            alert('No se pudo Eliminar Socio');
            return false
          }
        },
        error => {
          console.log(<any>error);
          let message;
          if(error.error.validacion){
            message = <any>error.error.validacion;
          } else if(error.error.message){
            message = <any>error.error.message;
          } else {
            message = <any>error.error;
          }
          alert(message);
          return false;
        }
      );
    }
  }

  guardarSocio(){
    console.log('guardar linea');
    console.log(this.registerPartner.value);
    console.log(this.registerPartner.value.socios);
  }

  onChangeCargoPublico(valor, estado){
    if (valor=='si' && estado==true) {
      this.proveedor.ejerce_cargo_publico_rl = 'si';
      this.cargo_publico_check_no = false;
    } else if (valor=='no' && estado==true) {
      this.proveedor.ejerce_cargo_publico_rl = 'no';
      this.cargo_publico_check_si = false;
      this.proveedor.cargo_publico_rl='';
      this.proveedor.fecha_cargo_publico_rl='';
    } else if (estado==false) {
      this.proveedor.ejerce_cargo_publico_rl = '';
      this.proveedor.cargo_publico_rl='';
      this.proveedor.fecha_cargo_publico_rl='';
    }
    console.log(this.proveedor.ejerce_cargo_publico_rl);
  }

  onChangeManejaRecursoPublico(valor, estado){
    if (valor=='si' && estado==true) {
      this.proveedor.maneja_recurso_publico_rl = 'si';
      this.maneja_recurso_publico_check_no = false;
    } else if (valor=='no' && estado==true) {
      this.proveedor.maneja_recurso_publico_rl = 'no';
      this.maneja_recurso_publico_check_si = false;
      this.proveedor.cargo_recurso_publico_rl='';
      this.proveedor.fecha_recurso_publico_rl='';
    } else if (estado==false) {
      this.proveedor.maneja_recurso_publico_rl = '';
      this.proveedor.cargo_recurso_publico_rl='';
      this.proveedor.fecha_recurso_publico_rl='';
    }

    console.log(this.proveedor.maneja_recurso_publico_rl);
  }

  onSubmit(){
    //console.log(this.proveedor);
    this.camposRequeridos();
    let socios = this.registerPartner.value;
    this._proveedoresService.create(this.proveedor,'registro',this.empresa,this.codigo_pais,socios).subscribe(
      response => {
        //console.log(response);
        if(response.status=='success'){
          this.success=true;
        }

      },
      error =>{
        console.log(<any>error);
        let message;
        if (<any>error.error.validacion=='The firm name field is required.'){
          message = 'Ingrese la Razón Social/Nombre Persona Natural';
        } else if(error.error.validacion){
          message = <any>error.error.validacion;
        } else if(error.error.message){
          message = <any>error.error.message;
        } else {
          message = <any>error.error;
        }
        alert(message);
        return false;
      }
    );
    //form.reset();

  }

  getListados(){
    this._proveedoresService.getListas('ide_tributaria',this.empresa).subscribe(
      response => {
        console.log(response);
        this.tributarios = response;
      },
      error =>{
        console.log(<any>error);
      }
    );

    this._proveedoresService.getListas('type_person',this.empresa).subscribe(
      response => {
        this.tipo_personas=response;
      },
      error =>{
        console.log(<any>error);
      }
    );

    this._proveedoresService.getListas('tipo_regimen',this.empresa).subscribe(
      response => {
        this.regimens=response;
      },
      error =>{
        console.log(<any>error);
      }
    );

    this._proveedoresService.getListas('ide_contribuyente',this.empresa).subscribe(
      response => {
        this.contribuyentes=response;
      },
      error =>{
        console.log(<any>error);
      }
    );

    this._proveedoresService.getListas('ide_vigencia_res_fac',this.empresa).subscribe(
      response => {
        this.vigencias=response;
      },
      error =>{
        console.log(<any>error);
      }
    );

    this._proveedoresService.getListas('ide_autoretenedor',this.empresa).subscribe(
      response => {
        this.autoretenedores=response;
      },
      error =>{
        console.log(<any>error);
      }
    );

    this._proveedoresService.getListas('tipo_cuenta',this.empresa).subscribe(
      response => {
        this.tipo_cuentas=response;
      },
      error =>{
        console.log(<any>error);
      }
    );

    this._proveedoresService.getListas('ide_banco_nacional' ,this.empresa).subscribe(
      response => {
        this.bancos_nacionales=response;
      },
      error =>{
        console.log(<any>error);
      }
    );

    this._proveedoresService.getListas('ide_ciudad_tributa',this.empresa).subscribe(
      response => {
        this.ciudad_tributa_ica=response;
      },
      error =>{
        console.log(<any>error);
      }
    );

  }

  getLocation(){
    this.data$ = this._proveedoresService.getLocations('general');
  }

  getActividad(){
    this.actividad$=this._proveedoresService.getActividad_principal('general')
  }

  onFocusAutoc(e){
    e.target.setAttribute('autocomplete', 'new-text');
  }

  selectEvent(event){
    if(confirm('¿Esta seguro de escoger '+event.name+' como Ciudad?')){
        this.proveedor.billing_country_id=event.billing_country_id;
        this.proveedor.billing_city_id=event.billing_city_id;
        this.proveedor.nombres_localizacion=event.name;
        this.codigo_pais=event.codigo_pais;
        if(this.codigo_pais=='MX'){
          this.campo_switch='Número Clave'
          this.tooltip_switch='Se Requiere Clave Bancaria Estandarizada';
          this.certificacion_cuenta='Certificación Bancaria*';
          this.label_razon_social='Razón Social/Persona Fisica';
          this.tooltip_certificacion_cuenta='Usted podra omitir los movimientos';
          this.label_doc_rl='INE/RFC Representante Legal';



          this.nombresLabels.splice(1, 1,   { value: '2', nombres: 'INE/RFC*' })

        }else{
          this.campo_switch='Número SWIFT';
          this.tooltip_switch='Para proveedor extranjero se requiere esta información';
          this.certificacion_cuenta='CERTIFICACIÓN DE CUENTA BANCARIA';
          this.tooltip_certificacion_cuenta='Certificación expedida por la entidad bancaria respecto la cuenta a la cual se le realizara el pago de las facturas emitidas. Expedición no superior a 30 días';
          this.label_doc_rl='Documento Representante Legal*';
        }
        if(this.codigo_pais!='CO'){
          this.tooltip_firm_name='Nombre de la sociedad';

        }else{
         this.tooltip_firm_name='(Nombre de la sociedad registrado en la Cámara de Comercio / Nombre(s) y Apellido(s) de persona Natural)'

        }

        if (this.codigo_pais == 'CO' && this.empresa!='brmmx') {
          this.renderer.setStyle(this.informacion_inicial.nativeElement, "display", "");
          this.phone_office_req=true;
          //this.phone_alternate_req=true;
          this.billing_address_street_req =true;
          this.email_contacto_comercial_req=true;
          this.id_actividad_principal_req=true;
          this.rut_doc_req=true;
          this.certificado_representante_doc_req=true;
          this.certificado_cuenta_doc_req=true;
          this.codigo_verificacion_req=true;
          this.firm_name_req=true;
          this.contacto_comercial_req=true;
          this.numero_cuenta_req=true;
          this.quien_realiza_registro_req=true;
          this.primer_nombre_rl_req=true;
          this.primer_apellido_rl_req=true;
          this.segundo_apellido_rl_req=true;
          this.numero_identificacion_rl_req=true;
          this.tipo_identificacion_rl_req=true;
          this.tributary_number_req=true;
          this.person_type_req=true;
          this.regimen_type_req=true;
          this.gran_contribuyente_req=true;
          this.gran_contribuyente_ica_req=true;
          this.autoholder_revenue_req=true;
          this.autoholder_iva_req=true;
          this.description_req=true;
          this.banco_nacional_req=true;
          this.tipo_cuenta_req=true;
          this.digito_verificacion_req=true;
          this.ciudad_tributo_ica_id_req=true;
          this.ciudad_tributo_ica_id_req=true;
          this.tributary_id_req=true;
          this. primer_nombre_req=true;
          this. segundo_nombre_req=true;
          this.primer_apellido_req=true;
          this.segundo_apellido_req=true;
          this.tipo_identificacion_req=true;
          this.numero_identificacion_req=true;

          if(this.proveedor.ejerce_cargo_publico_rl=='si'){
          this.fecha_cargo_publico_rl_req=true;
          this.cargo_publico_rl_req=true;
          }
          if(this.proveedor.maneja_recurso_publico_rl=='si'){
            this.fecha_recurso_publico_rl_req=true;
          this.cargo_recurso_publico_rl_req=true;

            }
          if(this.proveedor.quien_realiza_registro=="Delegado representante legal"){
            this.email_representante_legal_req=true;
          }

          //this.renderer.setStyle(this.div_proveedor.nativeElement, "display", "");
        } else {
          this.renderer.setStyle(this.seccion_internacional.nativeElement, "display", "");
          this.phone_office_req=true;
          //this.phone_alternate_req=true;
          this.billing_address_street_req=true;
          this.email_contacto_comercial_req=true;
          //this.certificado_representante_doc_req=true;
          this.certificado_cuenta_doc_req=true;
          this.codigo_verificacion_req=true;
          this.firm_name_req=true;
          this.numero_cuenta_req=true;
          this.primer_nombre_rl_req=true;
          this.primer_apellido_rl_req=true;
          this.segundo_apellido_rl_req=true;
          this.numero_identificacion_rl_req=true;
          this.tipo_identificacion_rl_req=true;
          if(this.proveedor.quien_realiza_registro=="Delegado representante legal"){
            this.email_representante_legal_req=true;
          }
          if(this.proveedor.ejerce_cargo_publico_rl=='si'){
            this.fecha_cargo_publico_rl_req=true;
            this.cargo_publico_rl_req=true;
            }
            if(this.proveedor.maneja_recurso_publico_rl=='si'){
              this.fecha_recurso_publico_rl_req=true;
            this.cargo_recurso_publico_rl_req=true;

              }
          if(this.codigo_pais!='MX'&& this.empresa!='brmmx'){
            this. primer_nombre_req=true;
            this. segundo_nombre_req=true;
            this.primer_apellido_req=true;
            this.segundo_apellido_req=true;
            this.tipo_identificacion_req=true;
            this.numero_identificacion_req=true;
          }
          this.proveedor.tipo_identificacion_rl='Cédula Extranjería';
          if(this.codigo_pais=='MX'&& this.empresa=='brmmx'){
            this.tributary_number_req=true;
          }

        }
        this.procesarTipoIdentificacion();
        this.disabledcity = true;
    }

  }
  selectEventCiiu(event){
     this.proveedor.id_actividad_principal=event.ciiu_id;
     this.camposRequeridos();
  }

  selectEventCiiu2(event){
     this.proveedor.id_actividad_secundaria=event.ciiu_id;
  }

  documentCertificacionPCI(ev){
    let doc:any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);
    if (this.proveedor.tributary_number==0) {
      alert('Por favor digite el Número de Identificación Tributaria');
      return false;
    } else {
      this.renderer.setAttribute(this.numero_tributario.nativeElement, "readonly", "true");
    }
    if (sizekiloByte > 5120) {
      alert("No se permiten Archivos con tamaño superior a 5 MB");
      return false;
    }
    if(doc.files.length > 0){
      this.loader_crt_pci = true;
      let form = new FormData();
      let nit = this.proveedor.tributary_number;
      form.append('file0',doc.files[0]);
      var namedoc=doc.files[0].name;
      this._proveedoresService.subirDocumento(form,nit,this.empresa).subscribe(
        resp => {
          this.loader_crt_pci = false;
          if(resp.status == 'success'){
            this.certificacion_pci_doc = resp.documento;
            this.proveedor.certificacion_pci = resp.documento;
            this.msn_crt_pci = namedoc;
            if (this.codigo_pais == 'CO' && this.empresa!='brmmx') {
              //this.renderer.removeClass(this.pci.nativeElement, "decorator");
              //this.renderer.addClass(this.pci.nativeElement, "success");
            }
          }
        },
        error => {
          this.loader_crt_pci = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }

  documentCertRepresentanteLegal(ev){

    let doc:any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);
    if (this.proveedor.tributary_number==0 && this.codigo_pais==='CO') {
      alert('Por favor digite el Número de Identificación Tributaria');
      return false;
    }
      this.renderer.setAttribute(this.numero_tributario.nativeElement, "readonly", "true");

    if (sizekiloByte > 5120) {
      alert("No se permiten Archivos con tamaño superior a 5 MB");
      return false;
    }
    if(doc.files.length > 0){
      this.loader_crt_rep = true;
      let form = new FormData();
      var nit;
      if(this.codigo_pais=='CO' || this.empresa=='brmmx'){
        nit = this.proveedor.tributary_number;
      } else {
        nit = this.proveedor.codigo_verificacion;
      }
      form.append('file0',doc.files[0]);
      var repredoc=doc.files[0].name;
      this._proveedoresService.subirDocumento(form,nit,this.empresa).subscribe(
        resp => {
          this.loader_crt_rep = false;
          if(resp.status == 'success'){
            this.cert_representante_legal_doc = resp.documento;
            this.proveedor.certificado_representante_doc = resp.documento;
            this.msn_crt_rep = repredoc;
            this.camposRequeridos();
            //this.renderer.removeClass(this.rep.nativeElement, "decorator");
            //this.renderer.addClass(this.rep.nativeElement, "success");
          }
        },
        error => {
          this.loader_crt_rep = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }

  documentCertificacionCuenta(ev){

    let doc:any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);
    if (this.proveedor.tributary_number==0  && this.codigo_pais==='CO'  && this.empresa!='brmmx') {
      alert('Por favor digite el Número de Identificación Tributaria');
      return false;
    }
    if(this.empresa!='brmmx'){
      this.renderer.setAttribute(this.numero_tributario.nativeElement, "readonly", "true");
    }
    if (sizekiloByte > 5120) {
      alert("No se permiten Archivos con tamaño superior a 5 MB");
      return false;
    }
    if(doc.files.length > 0){
      this.loader_crt_cuenta = true;
      let form = new FormData();
      var nit;
      if(this.codigo_pais=='CO' &&  this.empresa !='brmmx'){
        nit = this.proveedor.tributary_number;
      } else {
        nit = this.proveedor.codigo_verificacion;
      }

      form.append('file0',doc.files[0]);
      var cerdoc=doc.files[0].name;
      this._proveedoresService.subirDocumento(form,nit,this.empresa).subscribe(
        resp => {
          this.loader_crt_cuenta = false;
          if(resp.status == 'success'){
            this.certificacion_cuenta_doc = resp.documento;
            this.proveedor.certificado_cuenta_doc = resp.documento;
            this.msn_crt_cuenta = cerdoc;
            this.camposRequeridos();
            //this.renderer.removeClass(this.account.nativeElement, "decorator");
            //this.renderer.addClass(this.account.nativeElement, "success");
          }
        },
        error => {
          this.loader_crt_cuenta = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }

  RFC(ev){
    let doc:any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);
    if (this.proveedor.tributary_number==0  && this.codigo_pais==='CO') {
      alert('Por favor digite el Número de Identificación Tributaria');
      return false;
    }
      this.renderer.setAttribute(this.numero_tributario.nativeElement, "readonly", "true");

    if (sizekiloByte > 5120) {
      alert("No se permiten Archivos con tamaño superior a 5 MB");
      return false;
    }
    if(doc.files.length > 0){
      this.loader_crt_rfc = true;
      let form = new FormData();
      var nit;
      if(this.codigo_pais=='CO'  && this.empresa=='brmmx'){
        nit = this.proveedor.tributary_number;
      } else {
        nit = this.proveedor.codigo_verificacion;
      }

      form.append('file0',doc.files[0]);
      var rfcdoc=doc.files[0].name;
      this._proveedoresService.subirDocumento(form,nit,this.empresa).subscribe(
        resp => {
          this.loader_crt_rfc = false;
          if(resp.status == 'success'){
            this.documento_rfc = resp.documento;
            this.proveedor.rfc_doc = resp.documento;
            this.msn_crt_rfc = rfcdoc;
            this.camposRequeridos();
            //this.renderer.removeClass(this.account.nativeElement, "decorator");
            //this.renderer.addClass(this.account.nativeElement, "success");
          }
        },
        error => {
          this.loader_crt_rfc = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }
  /*documentRL(ev){
    let doc:any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);
    if (this.proveedor.tributary_number==0  && this.codigo_pais==='CO'  && this.empresa!='brmmx') {
      alert('Por favor digite el Número de Identificación Tributaria');
      return false;
    }
      this.renderer.setAttribute(this.numero_tributario.nativeElement, "readonly", "true");

    if (sizekiloByte > 5120) {
      alert("No se permiten Archivos con tamaño superior a 5 MB");
      return false;
    }
    if(doc.files.length > 0){
      this.loader_crt_rl = true;
      let form = new FormData();
      var nit;
      if(this.codigo_pais=='CO'  && this.empresa=='brmmx'){
        nit = this.proveedor.tributary_number;
      } else {
        nit = this.proveedor.codigo_verificacion;
      }
      form.append('file0',doc.files[0]);
      var rldoc=doc.files[0].name;
      this._proveedoresService.subirDocumento(form,nit,this.empresa).subscribe(
        resp => {
          this.loader_crt_rl = false;
          if(resp.status == 'success'){
            this.documento_identidad_rl = resp.documento;
            this.proveedor.documento_rl_doc = resp.documento;
            this.msn_doc_rl = rldoc;
            this.camposRequeridos();
            //this.renderer.removeClass(this.account.nativeElement, "decorator");
            //this.renderer.addClass(this.account.nativeElement, "success");
          }
        },
        error => {
          this.loader_crt_rl = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }*/

  documentResFacturaVigente(ev){

    let doc:any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);
    if (this.proveedor.tributary_number==0  && this.codigo_pais=='CO'  && this.empresa!='brmmx') {
      alert('Por favor digite el Número de Identificación Tributaria');
      return false;
    } else {
      this.renderer.setAttribute(this.numero_tributario.nativeElement, "readonly", "true");
    }
    if (sizekiloByte > 5120) {
      alert("No se permiten Archivos con tamaño superior a 5 MB");
      return false;
    }
    if(doc.files.length > 0){
      this.loader_crt_fact = true;
      let form = new FormData();
      let nit = this.proveedor.tributary_number;
      form.append('file0',doc.files[0]);
      let facdoc:any =doc.files[0].name;
      this._proveedoresService.subirDocumento(form,nit,this.empresa).subscribe(
        resp => {
          this.loader_crt_fact = false;
          if(resp.status == 'success'){
            this.res_fact_vigente_doc = resp.documento;
            this.proveedor.facturacion_vigente_doc = resp.documento;
            this.msn_crt_fact = facdoc;
            //this.renderer.removeClass(this.fact.nativeElement, "decorator");
            //this.renderer.addClass(this.fact.nativeElement, "success");
          }
        },
        error => {
          this.loader_crt_fact = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }

  documentRUT(ev){

    let doc:any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);
    if (this.proveedor.tributary_number==0) {
      alert('Por favor digite el Número de Identificación Tributaria');
      return false;
    } else {
      this.renderer.setAttribute(this.numero_tributario.nativeElement, "readonly", "true");
    }
    if (sizekiloByte > 5120) {
      alert("No se permiten Archivos con tamaño superior a 5 MB");
      return false;
    }
    if(doc.files.length > 0){
      this.loader_crt_rut = true;
      let form = new FormData();
      let nit = this.proveedor.tributary_number;
      form.append('file0',doc.files[0]);
      let rutdoc:any =doc.files[0].name;
      this._proveedoresService.subirDocumento(form,nit,this.empresa).subscribe(
        resp => {
          this.loader_crt_rut = false;
          if(resp.status == 'success'){
            this.doc_rut = resp.documento;
            this.proveedor.rut_doc = resp.documento;
            this.msn_crt_rut = rutdoc;
            this.camposRequeridos();
            //this.renderer.removeClass(this.rut.nativeElement, "decorator");
            //this.renderer.addClass(this.rut.nativeElement, "success");
          }
        },
        error => {
          this.loader_crt_rut = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }

  documentRepresentanteLegal(ev){

    let doc:any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);

    if (this.proveedor.tributary_number==0  && this.codigo_pais=='CO'&& this.empresa!='brmmx') {
      alert('Por favor digite el Número de Identificación Tributaria');
      return false;
    } if(this.empresa!='brmmx'){
      this.renderer.setAttribute(this.numero_tributario.nativeElement, "readonly", "true");
    }
    if (sizekiloByte > 5120) {
      alert("No se permiten Archivos con tamaño superior a 5 MB");
      return false;
    }
    if(doc.files.length > 0){
      this.loader_crt_replegal = true;
      let form = new FormData();
      var nit;
      if(this.codigo_pais=='CO' && this.empresa!='brmmx'){
      nit = this.proveedor.tributary_number;
      }else{
      nit=this.proveedor.codigo_verificacion;
      }
      form.append('file0',doc.files[0]);
      let rldoc:any =doc.files[0].name;
      this._proveedoresService.subirDocumento(form,nit,this.empresa).subscribe(
        resp => {
          this.loader_crt_replegal = false;
          if(resp.status == 'success'){
            this.doc_representante_legal = resp.documento;
            this.proveedor.representante_legal_doc = resp.documento;
            this.msn_crt_rl = rldoc;
            this.renderer.removeClass(this.representante.nativeElement, "decorator");
            this.renderer.addClass(this.representante.nativeElement, "success");
          }
        },
        error => {
          this.loader_crt_replegal = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }

  precargarProvedor(pre_id='',tipo='registro',empresa='brm'){
    this._route.params.subscribe(params =>{

        let id = '';
        if (pre_id=='') {
          id = params['id'];
        } else {
          id = pre_id;
        }

        if (id != 'registro') {
            this._proveedoresService.getProvider(id,this.empresa,this.procedimiento).subscribe(
                response => {
                  console.log('Este es el proveedor',response);
                  if (response.status == 'success') {
                    console.log(response);
                    this.registro = response.provider;
                    this.dataSocios = response.dataSocios;
                    this.proveedor= new Proveedor(
                        this.registro.id,
                        tipo,//this.registro.registro_actualizacion,
                        this.registro.firm_name,
                        this.registro.compania_contratante,
                        this.registro.phone_office,
                        this.registro.phone_alternate,
                        this.registro.billing_address_street,
                        this.registro.billing_country_id,
                        this.registro.billing_city_id,
                        response.informacion.nombres_localizacion,
                        this.registro.contacto_comercial,
                        this.registro.email_contacto_comercial,
                        this.registro.tributary_id,
                        response.informacion.tributary_number,
                        this.registro.person_type,
                        this.registro.regimen_type,
                        this.registro.ciudad_tributo_ica_id,
                        this.registro.gran_contribuyente,
                        this.registro.gran_contribuyente_ica,
                        this.registro.autoholder_revenue,
                        this.registro.autoholder_iva,
                        this.registro.fecha_resolucion_fact,
                        this.registro.vigencia_resolucion_fact,
                        this.registro.id_actividad_principal,
                        response.informacion.id_actividad_secundaria,
                        this.registro.banco_nacional,
                        this.registro.banco_internacional,
                        this.registro.tipo_cuenta,
                        this.registro.numero_cuenta,
                        this.registro.numero_swift,
                        this.registro.conflicto_interes,
                        this.registro.primer_nombre_rl,
                        this.registro.segundo_nombre_rl,
                        this.registro.primer_apellido_rl,
                        this.registro.segundo_apellido_rl,
                        this.registro.tipo_identificación_rl,
                        this.registro.numero_identificacion_rl,
                        this.registro.codigo_verificacion,
                        this.registro.description,
                        this.registro.tipo_identificacion_rl,
                        response.informacion.digito_verificacion,
                        this.registro.certificacion_pci,
                        this.registro.rut_doc,
                        this.registro.certificado_representante_doc,
                        this.registro.certificado_cuenta_doc,
                       // this.registro.documento_rl_doc,
                        this.registro.rfc_doc,
                        this.registro.facturacion_vigente_doc,
                        this.registro.quien_realiza_registro,
                        this.registro.email_representante_legal,
                        this.registro.pep_rl,//pep representante legal
                        this.registro.representante_legal_doc,//documento representante legal
                        this.registro.ejerce_cargo_publico_rl,
                        this.registro.maneja_recurso_publico_rl,
                        this.registro.cargo_publico_rl,
                        this.registro.fecha_cargo_publico_rl,
                        this.registro.cargo_recurso_publico_rl,
                        this.registro.fecha_recurso_publico_rl,
                    );

                    if (this.registro.ejerce_cargo_publico_rl=='si') {
                      this.cargo_publico_check_si = true;
                      this.cargo_publico_check_no = false;
                    } else if (this.registro.ejerce_cargo_publico_rl=='no') {
                      this.cargo_publico_check_si = false;
                      this.cargo_publico_check_no = true;
                    } else {
                      this.cargo_publico_check_si = false;
                    }

                    if (this.registro.maneja_recurso_publico_rl=='si') {
                      this.maneja_recurso_publico_check_si = true;
                      this.maneja_recurso_publico_check_no = false;
                    } else if (this.registro.maneja_recurso_publico_rl=='no') {
                      this.maneja_recurso_publico_check_si = false;
                      this.maneja_recurso_publico_check_no = true;
                    } else {
                      this.maneja_recurso_publico_check_si = false;
                    }

                    this.valueCiiu = response.informacion.nombre_ciiu;
                    this.valueCiiu2 = response.informacion.nombre_ciiu2;
                    this.valueCity = response.informacion.nombres_localizacion;
                    this.codigo_pais = response.informacion.codigo_pais;
                    //console.log(response.informacion);
                    //console.log(this.registro.stage);
                    console.log(this.campo_switch);

                    if(this.codigo_pais=='MX'){
                      this.campo_switch='Número Clave'
                      this.tooltip_switch='Se Requiere Clave Bancaria Estandarizada';
                      this.certificacion_cuenta='Certificación Bancaria*';
                      this.tooltip_certificacion_cuenta='Usted podra omitir los movimientos';
                      this.label_doc_rl='INE/RFC Representante Legal';
                      this.label_razon_social='Razón Social/Persona Fisica';
                      this.nombresLabels.splice(1, 1,   { value: '2', nombres: 'INE/RFC*' })
                    }else{
                      this.campo_switch='Número SWIFT';
                      this.tooltip_switch='Para proveedor extranjero se requiere esta información';
                      this.certificacion_cuenta='CERTIFICACIÓN DE CUENTA BANCARIA';
                      this.tooltip_certificacion_cuenta='Certificación expedida por la entidad bancaria respecto la cuenta a la cual se le realizara el pago de las facturas emitidas. Expedición no superior a 30 días';
                      this.label_doc_rl='Documento Representante Legal*';
                    }
                    if(this.codigo_pais!='CO'){
                        this.tooltip_firm_name='Nombre de la sociedad';
                    }else{
                        this.tooltip_firm_name='(Nombre de la sociedad registrado en la Cámara de Comercio / Nombre(s) y Apellido(s) de persona Natural)'
                    }

                    if (this.codigo_pais == 'CO'  && this.empresa != 'brmmx') {
                      this.renderer.setStyle(this.informacion_general.nativeElement, "display", "");
                      this.renderer.setStyle(this.informacion_inicial.nativeElement, "display", "");
                      this.renderer.setStyle(this.div_proveedor.nativeElement, "display", "");
                      if (response.informacion.name_doc_pci != '') {
                        this.msn_crt_pci=response.informacion.name_doc_pci;
                        if (this.registro.stage == 6 || this.registro.stage == 5 || this.registro.stage == 3 || this.procedimiento=='ed_'){

                        }else{
                          this.renderer.removeClass(this.pci.nativeElement, "decorator");
                          this.renderer.addClass(this.pci.nativeElement, "success");

                        }
                      }
                      if (response.informacion.name_doc_rut != '') {
                        this.msn_crt_rut=response.informacion.name_doc_rut;
                        if (this.registro.stage == 6 || this.registro.stage == 5 || this.registro.stage == 3 || this.procedimiento=='ed_'){

                        }else{
                          this.renderer.removeClass(this.rut.nativeElement, "decorator");
                          this.renderer.addClass(this.rut.nativeElement, "success");
                        }
                      }
                      if (response.informacion.name_doc_fact != '') {
                        this.msn_crt_fact=response.informacion.name_doc_fact;
                        if (this.registro.stage == 6 || this.registro.stage == 5 || this.registro.stage == 3 || this.procedimiento=='ed_'){


                        }else{
                          this.renderer.removeClass(this.fact.nativeElement, "decorator");
                          this.renderer.addClass(this.fact.nativeElement, "success");
                        }
                      }
                      if (response.informacion.name_doc_rep != '') {
                        this.msn_crt_rep=response.informacion.name_doc_rep;
                        if (this.registro.stage == 6  || this.registro.stage == 5 || this.registro.stage == 3 ||this.procedimiento=='ed_') {

                        }else{
                            this.renderer.removeClass(this.rep.nativeElement, "decorator");
                            this.renderer.addClass(this.rep.nativeElement, "success");

                        }
                      }
                      if (response.informacion.name_doc_account != '') {
                        this.msn_crt_cuenta=response.informacion.name_doc_account;
                        if (this.registro.stage == 6 || this.registro.stage == 5 || this.registro.stage == 3 ||this.procedimiento=='ed_'){

                        }else{
                            this.renderer.removeClass(this.account.nativeElement, "decorator");
                            this.renderer.addClass(this.account.nativeElement, "success");

                        }
                      }

                      if (response.informacion.name_doc_rl != '') {
                        this.msn_doc_rl = response.informacion.name_doc_rl;
                        if (this.registro.stage == 6 || this.registro.stage == 5 || this.registro.stage == 3||this.procedimiento=='ed_'){

                        }else{
                            this.renderer.removeClass(this.rep_int.nativeElement, "decorator");
                            this.renderer.addClass(this.rep_int.nativeElement, "success");

                        }
                      }
                      if (response.informacion.msn_crt_rl != '') {
                        this.msn_crt_rl = response.informacion.msn_crt_rl;
                        if (this.registro.stage == 6 || this.registro.stage == 5 || this.registro.stage == 3||this.procedimiento=='ed_') {
                        }else{
                            this.renderer.removeClass(this.representante.nativeElement, "decorator");
                            this.renderer.addClass(this.representante.nativeElement, "success");

                        }
                      }

                      this.renderer.setAttribute(this.numero_tributario.nativeElement, "readonly", "true");
                      this.renderer.addClass(this.dig_verifica.nativeElement, "deshabilitarClick");
                      this.renderer.setAttribute(this.dig_verifica.nativeElement, "readonly", "true");
                    } else {
                      this.renderer.setStyle(this.informacion_general.nativeElement, "display", "");
                      this.renderer.setStyle(this.seccion_internacional.nativeElement, "display", "");
                      if (response.informacion.name_doc_account != '') {
                        this.msn_crt_cuenta = response.informacion.name_doc_account;
                        if (this.registro.stage == 6 || this.registro.stage == 5 || this.registro.stage == 3 ||this.procedimiento=='ed_'){
                        }else{
                            this.renderer.removeClass(this.account_int.nativeElement, "decorator");
                            this.renderer.addClass(this.account_int.nativeElement, "success");

                        }
                      }
                      if (response.informacion.name_doc_rl != '') {
                        this.msn_doc_rl = response.informacion.name_doc_rl;
                        if (this.registro.stage == 6 || this.registro.stage == 5 || this.registro.stage == 3 ||this.procedimiento=='ed_') {

                        }else{
                            this.renderer.removeClass(this.rep_int.nativeElement, "decorator");
                            this.renderer.addClass(this.rep_int.nativeElement, "success");

                        }
                      }
                      if (response.informacion.name_doc_rfc != '') {
                        this.msn_crt_rfc = response.informacion.name_doc_rfc;
                        if (this.registro.stage == 6 || this.registro.stage == 5 || this.registro.stage == 3 ||this.procedimiento=='ed_'){

                        }else{
                            this.renderer.removeClass(this.rfc.nativeElement, "decorator");
                            this.renderer.addClass(this.rfc.nativeElement, "success");
                        }
                      }
                      if (response.informacion.msn_crt_rl != '') {
                        this.msn_crt_rl = response.informacion.msn_crt_rl;
                        if (this.registro.stage == 6 || this.registro.stage == 5 || this.registro.stage == 3 ||this.procedimiento=='ed_') {
                        }else{
                            this.renderer.removeClass(this.representante.nativeElement, "decorator");
                            this.renderer.addClass(this.representante.nativeElement, "success");

                        }
                      }

                    }
                    this.disabledcity = true;
                    console.log(this.dataSocios);
                    if (Object.keys(this.dataSocios).length > 0) {
                        this.agregarSocio();
                    }
                    console.log( 'this.procesarTipoIdentificacion()');
                    this.procesarTipoIdentificacion();
                    /*if (pre_id=='' &&  this.codigo_pais=='CO') {

                      this.renderer.setStyle(this.div_proveedor.nativeElement, "display","" );
                      this.renderer.addClass(this.dig_verifica.nativeElement, "deshabilitarClick");
                      this.renderer.setAttribute(this.dig_verifica.nativeElement, "readonly", "true");
                      this.renderer.setAttribute(this.numero_tributario.nativeElement, "readonly", "true");

                    }*/

                  }else{
                    this._router.navigate(['proveedores/registro' + this.empresa]);
                  }
                },
                error => {
                  console.log(error.error);
                  if (error.error.status=='unauthorized') {
                    if (this.codigo_pais =='CO'  && this.empresa!='brmmx') {
                      this.renderer.removeAttribute(this.numero_tributario.nativeElement, "readonly");
                      this.renderer.removeClass(this.dig_verifica.nativeElement, "deshabilitarClick");
                      this.renderer.removeAttribute(this.dig_verifica.nativeElement, "readonly", "true");
                      this.renderer.setStyle(this.div_proveedor.nativeElement, "display", "none");
                    }
                    this.proveedor.tributary_number = 0;
                    this.proveedor.digito_verificacion = '';
                    alert(error.error.message);
                  } else {
                    this._router.navigate(['proveedores/registro' + this.empresa]);
                  }
                }

            );
        } else {
          //this.proveedor= new Proveedor('1','', '','','','', '','', '', '', '', '', '', '', '', '', '', '', '', '', '', '','','','','','','','','','','','','','','','','','','','','','');
        }

    });
  }

  validarProveedor(tipo=0){
    if (tipo==1 && this.proveedor.tributary_id=='nit' && this.proveedor.digito_verificacion == '') {
      // code...No procesa
    } else if(this.proveedor.tributary_id!=''){
        if (this.proveedor.tributary_number==0) {
          alert('Por favor digite el Número de Identificación Tributaria');
          return false;
        } else {
          this._proveedoresService.validarProveedor(this.proveedor.tributary_number,this.proveedor.digito_verificacion,this.proveedor.tributary_id,this.empresa).subscribe(
            resp => {
              //console.log(resp);
              if (resp.id) {
                //Permite editar si esta en estado Rechazado o Borrador (y se decidira si Inactivo tambien)
                if (resp.estado==5 || resp.estado==6 || this.procedimiento=='ed_' || resp.estado==3) {
                  this.precargarProvedor(resp.id,'actualizacion',this.empresa);
                  this.renderer.setStyle(this.div_proveedor.nativeElement, "display", "");
                  this.renderer.setAttribute(this.numero_tributario.nativeElement, "readonly", "true");
                } else {
                  this.proveedor.codigo_verificacion = '';
                  this.proveedor.tributary_number = 0;
                  this.renderer.setStyle(this.div_proveedor.nativeElement, "display", "none");
                  alert('Este proveedor se encuentra registrado en el sistema, por lo tanto no se puede editar, por favor comuniquese con el area de Compras');
                  return false;
                }
              } else {
                this.renderer.setStyle(this.div_proveedor.nativeElement, "display", "");
              }
            },
            error => {
              console.log(error);
            }
          );
        }
    }

  }

  validarNumero(){
    if (this.proveedor.tributary_id==''){
      this.proveedor.tributary_number = 0;
      alert('Por favor ingrese el tipo de identificacion');
    } else {
        let numero = this.proveedor.tributary_number;
        if (isNaN(numero)){
            this.proveedor.tributary_number = 0;
            alert ("El valor " + numero + " no es un número.");
        } else {
            if (numero % 1 == 0) {
                //console.log("Es un numero entero");
                this.proveedor.tributary_number = numero;
                /*if (this.proveedor.digito_verificacion!='') {
                  this.validarProveedor();
                }*/
            } else {
                this.proveedor.tributary_number = 0;
                alert ("El valor " + numero + " es un decimal, solo se admiten numeros enteros.");
            }
        }
    }
  }

  guardarBorrador(){
    //console.log(this.proveedor);
    let validar_campos = this.validarSociosBorrador();
    console.log(validar_campos);
    if (validar_campos==0) {
        this.camposRequeridos();
        let socios = this.registerPartner.value;
        console.log(socios);
        this._proveedoresService.create(this.proveedor,'borrador',this.empresa,this.codigo_pais,socios).subscribe(
          response => {
            //console.log(response);
            //console.log(response.status);
            if (response.status == 'success') {
              console.log(response.socios);
              this.proveedor.id = response.message.id;
              for (let row of response.socios) {
                for (var i = 0; i < 5; i++) {
                  if ( typeof this.socios.controls[i] != "undefined" && this.socios.controls[i].value.id.length == 0) {
                    if (this.socios.controls[i].value.numero_identificacion==row.numero_identificacion) {
                       //this.socios.controls[i].value.id = row.socio_id;
                       this.socios.at(i).get('id').setValue(row.socio_id);
                       console.log(row.socio_id);
                    }
                  }
                }
              }
              if (this.codigo_pais=='CO' && this.empresa!='brmmx') {
                  //this.renderer.addClass(this.dig_verifica.nativeElement, "deshabilitarClick");
                  //this.renderer.setAttribute(this.dig_verifica.nativeElement, "readonly", "true");
              }
              alert('Borrador Guardado');
            }

          },
          error =>{
            //console.log(<any>error.error.validacion);
            let message;
            if (<any>error.error.validacion=='The firm name field is required.'){
              message = 'Ingrese la Razón Social/Nombre Persona Natural';
            } else if(error.error.validacion){
              message = <any>error.error.validacion;
            } else if(error.error.message){
              message = <any>error.error.message;
            } else {
              message = <any>error.error;
            }
            alert(message);
            return false;
          }
        );
        //form.reset();
    }

  }

  onCountryChange(event){
  //console.log(event)
  this.indicativo_phone_office="+("+event.dialCode+')';
  this.proveedor.phone_office=this.indicativo_phone_office;
  }

  onCountryChange_2(event){
  this.indicativo_phone_alternate="+("+event.dialCode+')';
  this.proveedor.phone_alternate=this.indicativo_phone_alternate;
  }

  validarCodigo(campo_codigo){
      /*if (this.proveedor.billing_country_id=='') {
        this.proveedor.codigo_verificacion = '';
        this.proveedor.firm_name = '';
        alert('Por favor digite la Ciudad');
        return false;

      } else*/
      if (this.proveedor.codigo_verificacion=='') {
        //this.proveedor.firm_name = '';
        alert('Por favor digite el codigo que se envio a su correo para registrar el Proveedor');
        return false;
      } else {
        if (campo_codigo==1) {
            this.spinner.show();
            this.texto_spinner='Verificando codigo...'
            this._proveedoresService.validarCodigoVerificacion(this.proveedor.codigo_verificacion,this.empresa).subscribe(
              resp => {
                if(resp.status=='no_encontrado'){
                  this.spinner.hide();

                this.renderer.setStyle(this.informacion_general.nativeElement, "display", "");
                }
                console.log(resp);
                if (resp.status=='success') {
                  this.spinner.hide();
                  //Permite editar si esta en estado Rechazado o Borrador (y se decidira si Inactivo tambien)
                  if (resp.estado==5 || resp.estado==6 ||this.procedimiento=='ed_' || resp.estado==3) {
                    this.precargarProvedor(resp.id,'actualizacion', this.empresa);
                    this.renderer.setStyle(this.informacion_general.nativeElement, "display", "");
                    if (this.codigo_pais=='CO'  && this.empresa!='brmmx') {
                      this.renderer.setStyle(this.div_proveedor.nativeElement, "display", "");

                    }
                  } else {
                    this.proveedor.codigo_verificacion = '';
                    alert('Este proveedor se encuentra registrado en el sistema, por lo tanto no se puede editar, por favor comuniquese con el area de Compras');
                    return false;
                  }
                } else if(resp.status=='no_valido'){
                  this.spinner.hide();
                  this.renderer.setStyle(this.informacion_general.nativeElement, "display", "none");
                    this.proveedor.codigo_verificacion = '';
                    alert('El codigo Ingresado no es valido, por favor ingrese el que se envio en el correo de invitacion');
                    return false;
                }
              },
              error => {
                console.log(error);
              }
            );
        }
      }
  }

  camposRequeridos(enviarform=0,validarSocios=0) {
    console.log(this.proveedor.fecha_cargo_publico_rl);
    console.log(this.proveedor.fecha_recurso_publico_rl);
    if (validarSocios==1) {
      let sociosObject = this.registerPartner.value;
      let sociosArray = sociosObject.socios;
      let requeridosTmp = this.messagesSocios;
      var requeridosGen = {};
      var msg = [];
      var contador = 1;
      for (let row of sociosArray) {
        var arrayNit = 0;
          for (const indice in requeridosTmp) {
            if (indice=='tipo_identificacion'&& row[indice] == 'NIT'|| row[indice] == 'RFC') {
              arrayNit = 1;
            }
          }
          if (arrayNit==1) {
            requeridosGen = this.messagesSociosXNit;
          } else {
            requeridosGen = this.messagesSocios;
          }
          for (const indice in requeridosGen) {
            if (row[indice] == '' || row[indice] == 0) {
              msg.push('En Socio/Beneficiario Nº '+contador+' falta campo: <strong>'+requeridosTmp[indice]+'</strong>');
            }

          }
          contador++;
      }
      if(msg.length >0){
        this.titulo_modal_socios = 'Falta Informacion Socios';
        this.texto_modal_socios = msg.join('<br>');
        this.openModalSocios();
        return false;
      }
    }

    requeridosArray = [];
    if (this.codigo_pais == 'CO' && this.empresa!='brmmx' ) {
      var requeridos = ['phone_office', 'billing_address_street', 'email_contacto_comercial', 'id_actividad_principal', 'rut_doc',
        'certificado_representante_doc', 'certificado_cuenta_doc', 'codigo_verificacion', 'firm_name','contacto_comercial', 'numero_cuenta',
        'primer_nombre_rl','primer_apellido_rl','segundo_apellido_rl','numero_identificacion_rl', 'tributary_number', 'person_type', 'regimen_type', 'gran_contribuyente', 'gran_contribuyente_ica', 'autoholder_revenue',
        'autoholder_iva', 'description', 'banco_nacional', 'tipo_cuenta', 'tipo_identificacion_rl','quien_realiza_registro','primer_nombre_req','segundo_nombre_req','primer_apellido_req','segundo_apellido_req','tipo_identificacion_req','numero_identificacion_req','ejerce_cargo_publico_rl','maneja_recurso_publico_rl'];
        if (this.proveedor.tributary_id=='nit') {
          requeridos.push('digito_verificacion');
        } else {
          if (requeridos.indexOf('digito_verificacion') !== -1) {
            var indice = requeridos.indexOf('digito_verificacion');
            delete requeridos[indice];
          }
        }
        if (this.proveedor.quien_realiza_registro=="Delegado representante legal") {
          requeridos.push('email_representante_legal');
        } else {
          if (requeridos.indexOf('email_representante_legal') !== -1) {
            var indice = requeridos.indexOf('email_representante_legal');
            delete requeridos[indice];
          }
        }
        if (this.proveedor.ejerce_cargo_publico_rl=='si') {
          requeridos.push('cargo_publico_rl','fecha_cargo_publico_rl');
        } else {
          if (requeridos.indexOf('cargo_publico_rl') !== -1) {
            var indice = requeridos.indexOf('cargo_publico_rl');
            delete requeridos[indice];
          }
          if (requeridos.indexOf('fecha_cargo_publico_rl') !== -1) {
            var indice = requeridos.indexOf('fecha_cargo_publico_rl');
            delete requeridos[indice];
          }
        }
        if (this.proveedor.maneja_recurso_publico_rl=='si') {
          requeridos.push('cargo_recurso_publico_rl','fecha_recurso_publico_rl');
        } else {
          if (requeridos.indexOf('cargo_recurso_publico_rl') !== -1) {
            var indice = requeridos.indexOf('cargo_recurso_publico_rl');
            delete requeridos[indice];
          }
          if (requeridos.indexOf('fecha_recurso_publico_rl') !== -1) {
            var indice = requeridos.indexOf('fecha_recurso_publico_rl');
            delete requeridos[indice];
          }
        }


    } else {
      if(this.codigo_pais=='MX' && this.empresa=='brmmx'){
        var requeridos = ['phone_office','billing_address_street', 'email_contacto_comercial',
        /* 'certificado_representante_doc'*/, 'certificado_cuenta_doc','rfc_doc','codigo_verificacion', 'firm_name', 'contacto_comercial', 'banco_internacional', 'numero_cuenta',
        'quien_realiza_registro', 'primer_nombre_rl','primer_apellido_rl','segundo_apellido_rl','numero_identificacion_rl','tributary_number','ejerce_cargo_publico_rl','maneja_recurso_publico_rl'];

      if (this.proveedor.quien_realiza_registro=="Delegado representante legal") {
        requeridos.push('email_representante_legal');
      } else {
        if (requeridos.indexOf('email_representante_legal') !== -1) {
          var indice = requeridos.indexOf('email_representante_legal');
          delete requeridos[indice];
        }
      }
      if (this.proveedor.ejerce_cargo_publico_rl=='si') {
        requeridos.push('cargo_publico_rl','fecha_cargo_publico_rl');
      } else {
        if (requeridos.indexOf('cargo_publico_rl') !== -1) {
          var indice = requeridos.indexOf('cargo_publico_rl');
          delete requeridos[indice];
        }
        if (requeridos.indexOf('fecha_cargo_publico_rl') !== -1) {
          var indice = requeridos.indexOf('fecha_cargo_publico_rl');
          delete requeridos[indice];
        }
      }
      if (this.proveedor.maneja_recurso_publico_rl=='si') {
        requeridos.push('cargo_recurso_publico_rl','fecha_recurso_publico_rl');
      } else {
        if (requeridos.indexOf('cargo_recurso_publico_rl') !== -1) {
          var indice = requeridos.indexOf('cargo_recurso_publico_rl');
          delete requeridos[indice];
        }
        if (requeridos.indexOf('fecha_recurso_publico_rl') !== -1) {
          var indice = requeridos.indexOf('fecha_recurso_publico_rl');
          delete requeridos[indice];
        }
      }
      }else{
        var requeridos = ['phone_office', 'billing_address_street', 'email_contacto_comercial',
        /* 'certificado_representante_doc'*/, 'certificado_cuenta_doc', 'codigo_verificacion', 'firm_name', 'contacto_comercial', 'banco_internacional', 'numero_cuenta',
        'quien_realiza_registro', 'primer_nombre_rl','primer_apellido_rl','segundo_apellido_rl', 'numero_identificacion_rl','primer_nombre_req','segundo_nombre_req','primer_apellido_req','segundo_apellido_req','tipo_identificacion_req','numero_identificacion_req','ejerce_cargo_publico_rl','maneja_recurso_publico_rl'];
         if (this.proveedor.quien_realiza_registro=="Delegado representante legal") {
          requeridos.push('email_representante_legal');
        } else {
          if (requeridos.indexOf('email_representante_legal') !== -1) {
            var indice = requeridos.indexOf('email_representante_legal');
            delete requeridos[indice];
          }
        }
        if (this.proveedor.ejerce_cargo_publico_rl=='si') {
          requeridos.push('cargo_publico_rl','fecha_cargo_publico_rl');
        } else {
          if (requeridos.indexOf('cargo_publico_rl') !== -1) {
            var indice = requeridos.indexOf('cargo_publico_rl');
            delete requeridos[indice];
          }
          if (requeridos.indexOf('fecha_cargo_publico_rl') !== -1) {
            var indice = requeridos.indexOf('fecha_cargo_publico_rl');
            delete requeridos[indice];
          }
        }
        if (this.proveedor.maneja_recurso_publico_rl=='si') {
          requeridos.push('cargo_recurso_publico_rl','fecha_recurso_publico_rl');
        } else {
          if (requeridos.indexOf('cargo_recurso_publico_rl') !== -1) {
            var indice = requeridos.indexOf('cargo_recurso_publico_rl');
            delete requeridos[indice];
          }
          if (requeridos.indexOf('fecha_recurso_publico_rl') !== -1) {
            var indice = requeridos.indexOf('fecha_recurso_publico_rl');
            delete requeridos[indice];
          }
        }
      }

    }

    for (let item in this.proveedor) {
      if (this.proveedor[item] == '' || this.proveedor[item] == null) {
        requeridos.forEach(function (valor, indice, array) {
          if (valor == item) {
            requeridosArray.push(messages[valor]);
          }
        });
      }

    }
    this.prueba = requeridosArray;
    if (Object.keys(this.prueba).length > 0) {
      this.mostrarcampos = true;
    } else {
      this.mostrarcampos = false;
      //this.enviar_internacionales=true;
      if(enviarform==1){
        this.onSubmit();
      }


    }

  }

  validarCamposC(campo) {
    let namecampo = campo.name;
    if (campo.status == 'INVALID') {
      this.proveedor[namecampo] = '';
      alert(`el campo:${messages[namecampo]} es requerido`);
      return false;
    }

  }

  openModalSocios() {
    let templateM = this.modalSocios;
    this.modalRef = this.modalService.show(templateM, { class: 'modal-lg' });
  }

  closeModalSocios() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  closeModal(modalId?: number){
    this.modalService.hide(modalId);
  }

  validarSociosBorrador(){
    let sociosObject = this.registerPartner.value;
    let sociosArray = sociosObject.socios;
    let campos = this.camposSocios;
    var validarCampos = 0;
    for (let row of sociosArray) {
        for (const indice in campos) {
          if (row[indice] == '' || row[indice] == 0 || row[indice] == null) {
          } else {
            validarCampos = 1;
          }
        }
    }
    console.log(validarCampos);
    if (validarCampos==1) {

        /*if (this.proveedor.tributary_id=='nit') {
          requeridos.push('digito_verificacion');
        } else {
          if (requeridos.indexOf('digito_verificacion') !== -1) {
            var indice = requeridos.indexOf('digito_verificacion');
            delete requeridos[indice];
          }
        }*/
        var msg = [];
        var contador = 1;
        var validarCampos = 0;
        var requeridosTmp = this.messagesSocios;
        var requeridosGen = {};
        for (let row of sociosArray) {
            var arrayNit = 0;
            for (const indice in requeridosTmp) {
              if (indice=='tipo_identificacion'&& row[indice] == 'NIT'||row[indice] == 'RFC') {
                arrayNit = 1;
              }
            }
            if (arrayNit==1) {
              requeridosGen = this.messagesSociosXNit;
            } else {
              requeridosGen = this.messagesSocios;
            }
            for (const indice in requeridosGen) {
              if (row[indice] == '' || row[indice] == 0) {
                msg.push('En Socio/Beneficiario Nº '+contador+' falta campo: <strong>'+requeridosTmp[indice]+'</strong>');
              }
            }
            contador++;
        }
        if(msg.length >0){
          this.titulo_modal_socios = 'Falta Informacion Socios';
          this.texto_modal_socios = msg.join('<br>');
          this.openModalSocios();
          validarCampos = 1;
        }
        return validarCampos;
    } else {
      return validarCampos;
    }

  }

  socioTipoId(event,index){
    var nombreCampo=event.target.value;
      if(nombreCampo=='NIT'|| nombreCampo=='RFC'){
        this.socios.at(index).get('segundo_nombre').disable();
        this.socios.at(index).get('primer_apellido').disable();
        this.socios.at(index).get('segundo_apellido').disable();
        this.socios.at(index).get('pep').disable();
      }else{
        this.socios.at(index).get('segundo_nombre').enable();
        this.socios.at(index).get('primer_apellido').enable();
        this.socios.at(index).get('segundo_apellido').enable();
        this.socios.at(index).get('pep').enable();
     }
  }

  validarNumeroIdentificacion(evt){
    // code Equivale a codigo ASCII.
    var code = (evt.which) ? evt.which : evt.keyCode;
    if (this.codigo_pais=='CO') {
      //solo Numeros
      if((code >= 48 && code <= 57) || // Números
            (code >= 65 && code <= 90) || // Letras mayúsculas
            (code >= 97 && code <= 122) || // Letras minúsculas
            (code == 45) || // Guiones
            (code == 8)) { // Retroceso
        return true;
      } else{ // otras teclas.
        return false;
      }
    } else {
      //Alfanumerico
      if((code >= 48 && code <= 57) || // Números
            (code >= 65 && code <= 90) || // Letras mayúsculas
            (code >= 97 && code <= 122) || // Letras minúsculas
            (code == 45) || // Guiones
            (code == 8)) { // Retroceso
        //letras numeros y retroceso
        return true;
      } else{ // otras teclas.
        return false;
      }
    }
  }

  procesarTipoIdentificacion(){
    //for (let row of this.tipo_identificacion_original) {
    for (const indice in this.tipo_identificacion_original) {
      if (this.codigo_pais==indice) {
          this.tipo_identificacion_rls = this.tipo_identificacion_original[indice];
      }
    }
    if (typeof this.tipo_identificacion_rls == "undefined") {
      this.tipo_identificacion_rls = this.tipo_identificacion_original['CO'];
    }
  }

}
