import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';
import { Clientes } from '../../models/clientes';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { tributario, tipo_persona, regimen, vigencia, contribuyente, autoretenedor, tipo_cuenta, tipo_identificacion,persona_publica} from '../../listas/tributarios';
import { ClientesService } from '../../Servicios/cliente.service';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AnimationDriver } from '@angular/animations/browser';
import { isNumber } from 'util';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


declare var $: any;
let requeridosArray: any = [];
let requeridosSocios:any=[];
const messages = {
  billing_address_street: 'Dirección.',
  billing_country_id: 'Ingrese Ciudad.',
  billing_city_id: 'Ingrese Ciudad.',
  codigo_verificacion:'Codigo',
  name:'Razón Social/Nombre Persona Natural',
  phone_office:'Teléfono (1)',
  phone_alternate:'Teléfono (2)',
  contacto_pagaduria:'Contacto pagaduría-tesorería*',
  email_contacto_pagaduria:'Correo electrónico - Contacto Pagaduria - Tesoreria*',
  tributary_id_c:'Identicación Tributaria (Tipo)*',
  tributary_number_c:'Número Identificación Tributaria / Fiscal*',
  digito_verificacion:'Dígito de Verificación*',
  phone_pagaduria:'Teléfono Contacto Pagaduría - Tesorería*',
  contacto_contabilidad:'Contacto Contabilidad - Impuestos*',
  email_contacto_contabilidad:'Correo electrónico - Contacto Contabilidad - Impuestos*',
  phone_contabilidad:'Teléfono Contacto Contabilidad - Impuestos. Fecha*',
  closed_reception_invoices_c:'Fecha limite recepción de facturas (día):*',
  email_facturacion_electronica:'Correo electrónico - para envío de facturación electrónica*',
  person_type_c:'Tipo de Persona*',
  regime_type_c:'Régimen*',
  gran_contribuyente:'Gran Contribuyente*',
  gran_contribuyente_ica:'GRAN CONTRIBUYENTE - ICA*',
  autoholder_revenue_c:'AUTORETENEDOR - RENTA*',
  autoholder_iva_c:'RETENEDOR - IVA_*',
  rut_doc:'COPIA DEL RUT / DOCUMENTO FISCAL',
  primer_nombre_rl:'Primer Nombre Representante legal*',
  segundo_nombre_rl:'Segundo Nombre Representante legal',
  primer_apellido_rl:'Primer Apellido Representante legal*',
  segundo_apellido_rl:'Segundo Apellido Representante legal*',
  tipo_identificacion:'Tipo de Identificación Representante legal *',
  numero_identificacion:'Numero Identificación (Personal)*',
  quien_realiza_registro:'CALIDAD DE QUIEN REALIZA EL REGISTRO*',
  email_representante_legal:'Correo Electronico Representante Legal*',
  ejerce_cargo_publico_rl: 'Desempeño Cargo Publico',
  maneja_recurso_publico_rl: 'Manejo de Recursos Publicos',
  cargo_publico_rl:'Cargo(cargo publico)',
  fecha_cargo_publico_rl:'Fecha Desempeño(cargo publico)',
  cargo_recurso_publico_rl:'Cargo(recursos publicos)',
  fecha_recurso_publico_rl:'Fecha Desempeño(recursos publicos)',
  representante_legal_doc: 'Documento Representante Legal',
  camara_comercio_doc: 'Documento Camara de Comercio'

};

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css'],
  providers: [ClientesService]
})
export class ClientesComponent implements OnInit {
  modalRef: BsModalRef | null;
  modalRef2: BsModalRef;
  @ViewChild("tributary_number") tributary_number: ElementRef;
  @ViewChild("pci") pci: ElementRef;
  @ViewChild("rut") rut: ElementRef;
  @ViewChild("camara") camara: ElementRef;
  @ViewChild("representante") representante: ElementRef;
  @ViewChild("rep") rep: ElementRef;
  @ViewChild("div_clientes") div_clientes: ElementRef;
  @ViewChild("account") account: ElementRef;
  @ViewChild("fact") fact: ElementRef;
  @ViewChild("dig_verifica") dig_verifica: ElementRef;
  @ViewChild("informacion_inicial") informacion_inicial: ElementRef;
  @ViewChild("modalSocios") modalSocios: ElementRef;


  public clientes: Clientes;
  public registro;
  public account_cstm;
  public tributarios: Array<tributario>;
  public tipo_personas: Array<tipo_persona>;
  public regimens: Array<regimen>;
  public contribuyentes: Array<contribuyente>;
  public vigencias: Array<vigencia>;
  public autoretenedores: Array<autoretenedor>;
  public tipo_cuentas: Array<tipo_cuenta>;
  public pep:Array<persona_publica>;
  public tipo_identificacion_persona: Array<tipo_identificacion>;
  public oc_factura: any = [{ value: '', nombre: '' },
  { value: '1', nombre: 'SI' },
  { value: '0', nombre: 'No' }
  ];
  public keyword = 'ciudad';
  public keyciiu = 'name';
  public valueCity = 'Digita la Ciudad';
  public data$: Observable<any[]>;
  public actividad$: Observable<any[]>;
  public pais = '';
  public concatedNames = '';
  public ciudad = '';
  public digito_verificacion: any = [
    { value: '', nombre: '' },
    { value: '1', nombre: '1' },
    { value: '2', nombre: '2' },
    { value: '3', nombre: '3' },
    { value: '4', nombre: '4' },
    { value: '5', nombre: '5' },
    { value: '6', nombre: '6' },
    { value: '7', nombre: '7' },
    { value: '8', nombre: '8' },
    { value: '9', nombre: '9' },
    { value: '0', nombre: '0' }

  ];
  public doc_rut = '';
  public doc_camara_comercio='';
  public doc_representante_legal='';
  public ciudad_invalida = true;
  public loader_crt_rut: Boolean = false;
  public loader_crt_camara: Boolean = false;
  public loader_crt_rl: Boolean = false;
  public msn_crt_rut = 'Arrastre y suelte el Archivo';
  public msn_crt_camara='Arrastre y suelte el Archivo';
  public msn_crt_rl='Arrastre y suelte el Archivo';
  public success: boolean = false;
  public indicativo_phone_office;
  public indicativo_phone_alternate;
  public indicativo_phone_contabilidad;
  public indicativo_phone_pagaduria;
  public empresa = 'brm';
  public enlace = '';
  public mostrar_parrafo: boolean = false;
  public texto_spinner = 'Cargando';
  public procedimiento = 'registrar';
  public codigo_pais = '';
  public disabledcity = false;
  public prueba: any = [];
  public mostrarcampos: boolean;
  public firm_name_req = false;
  public codigo_verificacion_req = true;
  public phone_office_req = false;
  public phone_alternate_req = false;
  public billing_address_street_req = false;
  public contacto_pagaduria_req = false;
  public email_contacto_pagaduria_req = false;
  public tributary_id_c_req=false;
  public tributary_number_c_req = false;
  public digito_verificacion_req = false;
  public phone_pagaduria_req = false;
  public contacto_contabilidad_req = false;
  public email_contacto_contabilidad_req = false;
  public phone_contabilidad_req = false;
  public closed_reception_invoices_c_req = false;
  public email_facturacion_electronica_req = false;
  public person_type_c_req = false;
  public regime_type_c_req = false;
  public gran_contribuyente_req = false;
  public gran_contribuyente_ica_req = false;
  public autoholder_revenue_c_req = false;
  public autoholder_iva_c_req = false;
  public rut_doc_req = false;
  public primer_nombre_rl_req = false;
  public segundo_nombre_rl_req = false;
  public primer_apellido_rl_req = false;
  public segundo_apellido_rl_req = false;
  public tipo_identificacion_req = false;
  public numero_identificacion_req = false;
  public quien_realiza_registro_req=false;
  public email_representante_legal_req=false;
  public titulo_Formulario='';
  public mostrar_internacionales=false;
  public texto_empresa='';
  public deshabilitar_delegado=false;
  public nombresLabels: any = [
    { value: '1', nombres: 'Tipo de Identificación*' },
    { value: '2', nombres: 'Numero Identificación(Personal)/NIT*' },
    { value: '3', nombres: 'Primer Nombre/Razón Social*'},
    { value: '4', nombres: 'Segundo Nombre*' },
    { value: '5', nombres: 'Primer Apellido*' },
    { value: '6', nombres: 'Segundo Apellido*' },
  ];
  public mostrar_labels_socios=false;
  public boton_agregar_socio=true;
  public messagesSocios = {
    primer_nombre: 'Primer Nombre',
    //segundo_nombre: 'Segundo Nombre',
    primer_apellido: 'Primer Apellido',
    segundo_apellido:'Segundo Apellido',
    tipo_identificacion:'Tipo de Identificación',
    numero_identificacion:'Numero Identificación(Personal)',
  };
  public messagesSociosXNit = {
    tipo_identificacion:'Tipo de Identificacion',
    numero_identificacion:'Numero Identificacion(Personal)',
    primer_nombre: 'Primer Nombre',
  };
  public titulo_modal_socios = '';
  public texto_modal_socios = '';
  public camposSocios = {
    primer_nombre: 'Primer Nombre',
    segundo_nombre: 'Segundo Nombre',
    primer_apellido: 'Primer Apellido',
    segundo_apellido:'Segundo Apellido',
    tipo_identificacion:'Tipo de Identificación',
    numero_identificacion:'Numero Identificación(Personal)',

  };
  public dataSocios: any = [];
  public tooltip_firm_name='Nombre de la (sociedad registrado en la Cámara de Comercio / Nombre(s) y Apellido(s) de persona Natural)';
  public label_doc_rl='Documento Representante Legal*';
  public cargo_publico_check_si = false;
  public cargo_publico_check_no = false;
  public maneja_recurso_publico_check_si = false;
  public maneja_recurso_publico_check_no = false;
  public label_direccion='Dirección*';
  public label_doc_rut_internacional='CERTIFICADO DE INCORPORACIÓN';
  public pattern_numero_identificacion="[\\w-]{4,25}"; //   [0-9-]{4,25}
  public tipo_identificacion_original: any = [];
  public label_razon_social='Razón Social/Nombre Persona Natural*';
  public botones_finales: boolean = false;

  //{url:string,};



  /*public contribuyentes:any=[{value:1,nombre:'Si'},{value:0 ,nombre:'No'}
  ]*/

  //public listaTributarios = new Object();

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private _clientesService: ClientesService,
    private renderer: Renderer2,
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: BsModalService
  ) {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 4000);

    this._route.params.subscribe(params => {
      this.empresa = params['empresa'];

      switch (this.empresa) {
        case 'brm': {
          this.texto_empresa='BRM S.A'

          break;
        }
        case 'brmmx': {
          this.texto_empresa='BRM RELATIONSHIP MANAGEMENT SA DE CV'

          break;
        }
        case 'dar': {
          this.texto_empresa='GIVING S.A.S'
          break;
        }
        case 'heart': {
          this.texto_empresa='HEART EMOTIONAL EXPERIENCES SAS'

          break;
        }
        case 'deeploy': {
          this.texto_empresa='DEEPLOY SAS'
          break;
        }
        case 'preferente': {
          this.texto_empresa='PREFERENTE MP SAS'
          break;
        }
        case 'qat': {
          this.texto_empresa='QUATRO ATELIER SAS'
          break;
        }
        default: {
          this.texto_empresa='BRM S.A'
          break;
        }
      }




      if(params['procedimiento'] === "creacion_rapida"){
        this.titulo_Formulario='REGISTRO ADMINISTRATIVO DE CLIENTES'
        this.boton_agregar_socio=false;

      }else{

        this.titulo_Formulario='FORMATO DE REGISTRO CLIENTES'
      }
    });
    this._route.params.subscribe(params => {
      if (typeof params['procedimiento'] === "undefined") {
        // se mantiene procedimiento por defecto
      } else {
        this.procedimiento = params['procedimiento'];
      }
    });
  }

  get socios(){
    return this.registerPartner.get('socios') as FormArray;
  }

  registerPartner = this.formBuilder.group({
    socios: this.formBuilder.array([])
  });

  ngOnInit(): void {
    $('body').bootstrapMaterialDesign();
    //console.log(this._clientesService.getListas('ide_tributaria'));
    this.getLocation();
    this.getListados();
    this.precargarCliente('',this.empresa);
    this.obtenerEnlace();
    if(this.procedimiento!='creacion_rapida'){
    this.agregarSocio();
    }
    this.clientes = new Clientes('1', '', '', '', '', '', '', '', '', '', 0, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '','','','','','','','','','','','','');

      if(this.procedimiento=='creacion_rapida'){
        this.clientes.codigo_verificacion='facturacion2020';
        this.clientes.quien_realiza_registro="Delegado representante legal";
        this.deshabilitar_delegado=true;
      }

  }

  agregarSocio(adicionNueva = 0){
   // console.log(this.dataSocios);
    if (Object.keys(this.dataSocios).length > 0 && adicionNueva == 0) {
      this.socios.removeAt(0);
      for (let row of this.dataSocios) {
        var deshabilitador=false;
        if (row.tipo_identificacion == 'NIT' || row.tipo_identificacion == 'RFC') {
             deshabilitador = true
        }else{
          deshabilitador=false;
        }
        if (row.pep == 0 || row.pep == '' || row.pep == null) {
          row.pep = false;
        }else{
          row.pep = true;
        }
        const socioForm = this.formBuilder.group({
          id: [row.id],
          primer_nombre:[row.primer_nombre],
          segundo_nombre: [{ value:row.segundo_nombre, disabled: deshabilitador}],
          primer_apellido:  [{ value:row.primer_apellido, disabled: deshabilitador}],
          segundo_apellido:  [{ value:row.segundo_apellido, disabled: deshabilitador}],
          tipo_identificacion: [row.tipo_identificacion] ,
          numero_identificacion: [row.numero_identificacion],
          pep:[{value:row.pep, disabled: deshabilitador}],
        });
        this.socios.push(socioForm);
      }
    } else {
      //console.log('else');
      const socioForm = this.formBuilder.group({
        id: [''],
        primer_nombre: [''],
        segundo_nombre: [''],
        primer_apellido: [''],
        segundo_apellido: [''],
        tipo_identificacion: [''],
        numero_identificacion: [''],
        pep:[''],
      });
      this.socios.push(socioForm);
      //console.log(this.socios);
    }

    this.mostrar_labels_socios=true;
  }

  removerSocio(indice) {
    var socioID = 'sin_guardar';
    if (this.socios.controls[indice].value.id) {
      socioID = this.socios.controls[indice].value.id;
    }
    if (socioID == 'sin_guardar') {
      this.socios.removeAt(indice);
    } else {
      this._clientesService.eliminarSocio(socioID,this.empresa).subscribe(
        response => {
          console.log(response);
          if (response.eliminacion == 1) {
            this.socios.removeAt(indice);
          } else {
            alert('No se pudo Eliminar Socio');
            return false
          }
        },
        error => {
          console.log(<any>error);
          let message;
          if(error.error.validacion){
            message = <any>error.error.validacion;
          } else if(error.error.message){
            message = <any>error.error.message;
          } else {
            message = <any>error.error;
          }
          alert(message);
          return false;
        }
      );
    }
  }

  guardarSocio(){
    console.log('guardar linea');
    console.log(this.registerPartner.value);
    console.log(this.registerPartner.value.socios);
  }

  onChangeCargoPublico(valor, estado){
    if (valor=='si' && estado==true) {
      this.clientes.ejerce_cargo_publico_rl = 'si';
      this.cargo_publico_check_no = false;
    } else if (valor=='no' && estado==true) {
      this.clientes.ejerce_cargo_publico_rl = 'no';
      this.cargo_publico_check_si = false;
      this.clientes.cargo_publico_rl='';
      this.clientes.fecha_cargo_publico_rl='';
    } else if (estado==false) {
      this.clientes.ejerce_cargo_publico_rl = '';
      this.clientes.cargo_publico_rl='';
      this.clientes.fecha_cargo_publico_rl='';

    }
    console.log(this.clientes.ejerce_cargo_publico_rl);
  }

  onChangeManejaRecursoPublico(valor, estado){
    if (valor=='si' && estado==true) {
      this.clientes.maneja_recurso_publico_rl = 'si';
      this.maneja_recurso_publico_check_no = false;
    } else if (valor=='no' && estado==true) {
      this.clientes.maneja_recurso_publico_rl = 'no';
      this.maneja_recurso_publico_check_si = false;
      this.clientes.cargo_recurso_publico_rl='';
      this.clientes.fecha_recurso_publico_rl='';
    } else if (estado==false) {
      this.clientes.maneja_recurso_publico_rl = '';
      this.clientes.cargo_recurso_publico_rl='';
      this.clientes.fecha_recurso_publico_rl='';

    }
    console.log(this.clientes.maneja_recurso_publico_rl);
  }

  onSubmit() {

    this.camposRequeridos();

    let socios = this.registerPartner.value;

    this._clientesService.create(this.clientes, 'registro', this.empresa, this.codigo_pais,this.procedimiento,socios).subscribe(
      response => {
        console.log(response);
        if (response.status = true) {
          this.success = true;
        }

      },
      error => {
        console.log(<any>error);
        let message;
        if (<any>error.error.validacion=='The firm name field is required.'){
          message = 'Ingrese la Razón Social/Nombre Persona Natural';
        } else if(error.error.validacion){
          message = <any>error.error.validacion;
        } else if(error.error.message){
          message = <any>error.error.message;
        } else {
          message = <any>error.error;
        }
        /*alert(<any>error);*/
        alert(message);
        return false;
      }
    );
    //form.reset();

  }

  getListados() {
    this._clientesService.getListas('ide_tributaria',this.empresa).subscribe(
      response => {
        this.tributarios = response;
      },
      error => {
        console.log(<any>error);
      }
    );

    this._clientesService.getListas('type_person',this.empresa).subscribe(
      response => {
        this.tipo_personas = response;
      },
      error => {
        console.log(<any>error);
      }
    );


    this._clientesService.getListas('regime_type_c',this.empresa).subscribe(
      response => {
        this.regimens = response;
      },
      error => {
        console.log(<any>error);
      }
    );

  this._clientesService.getListas('tipo_pep',this.empresa).subscribe(
      response => {
        this.pep = response;
      },
      error => {
        console.log(<any>error);
      }
    );



    this._clientesService.getListas('ide_contribuyente',this.empresa).subscribe(
      response => {
        this.contribuyentes = response;
      },
      error => {
        console.log(<any>error);
      }
    );



    this._clientesService.getListas('ide_vigencia_res_fac',this.empresa).subscribe(
      response => {
        this.vigencias = response;
      },
      error => {
        console.log(<any>error);
      }
    );

    this._clientesService.getListas('ide_autoretenedor',this.empresa).subscribe(
      response => {
        this.autoretenedores = response;
      },
      error => {
        console.log(<any>error);
      }
    );

    this._clientesService.getListas('tipo_cuenta',this.empresa).subscribe(
      response => {
        this.tipo_cuentas = response;
      },
      error => {
        console.log(<any>error);
      }
    );

    this._clientesService.getListas('tipo_identificacion',this.empresa).subscribe(
      response => {
        this.tipo_identificacion_original = response;
        //this.tipo_identificacion_persona = this.tipo_identificacion_original;
      },
      error => {
        console.log(<any>error);
      }
    );

  }

  getLocation() {
    /*this._clientesService.getLocations('general').subscribe(
      response => {
        this.data$ = response;
        console.log(this.data$);
      },
      error =>{
        console.log(<any>error);
      }
    );*/
    this.data$ = this._clientesService.getLocations('general');

  }

  onFocusAutoc(e){
    e.target.setAttribute('autocomplete', 'new-text');
  }



  selectEvent(event) {
    if (confirm('¿Esta seguro de escoger ' + event.name + ' como Ciudad?')) {
      this.clientes.billing_country_id = event.billing_country_id;
      this.clientes.billing_city_id = event.billing_city_id;
      this.clientes.nombres_localizacion = event.name;
      this.codigo_pais = event.codigo_pais;
      if (this.codigo_pais == 'CO'&& this.clientes.codigo_verificacion!='') {
        this.renderer.setStyle(this.informacion_inicial.nativeElement, "display", "");
      }
      if(this.codigo_pais!='CO'){
        this.tooltip_firm_name='Nombre de la sociedad';
        if(this.codigo_pais=='MX'){
          this.label_doc_rl='INE/Documento de Identificación RL*';
          this.label_doc_rut_internacional='RFC -CONSTANCIA DE SITUACIÓN FISCAL';
          this.label_razon_social='Razón Social/Persona Fisica';
          this.label_direccion='Domicilio Fiscal';
          this.pattern_numero_identificacion='[a-zA-z0-9]{2,15}';
          this.nombresLabels.splice(1, 1,   { value: '2', nombres: 'INE/No.de Identificación'})
        }
      }else{
       this.tooltip_firm_name='(Nombre de la sociedad registrado en la Cámara de Comercio / Nombre(s) y Apellido(s) de persona Natural)'
       this.label_doc_rl='Documento Representante Legal*';
       this.label_direccion='Dirección*';
      }
      this.procesarTipoIdentificacion();
      this.setearRequeridos();
      this.disabledcity = true;
    }
  }

  documentRUT(ev) {
    console.log(ev);
    let doc: any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    if (this.clientes.tributary_number_c == 0 && this.codigo_pais=='CO') {
      alert('Por favor digite el Número de Identificación Tributaria');
      return false;
    } else {
      this.renderer.setAttribute(this.tributary_number.nativeElement, "readonly", "true");
    }
    if (sizekiloByte > 2048) {
      alert("No se permiten Archivos con tamaño superior a 2 MB");
      return false;
    }
    if (doc.files.length > 0) {
      this.loader_crt_rut = true;
      let form = new FormData();
      var nit;
      if(this.codigo_pais=='CO'){
      nit = this.clientes.tributary_number_c;
      }else{
      nit=this.clientes.codigo_verificacion;
      }
      form.append('file0', doc.files[0]);
      let rutdoc: any = doc.files[0].name;
      this._clientesService.subirDocumento(form, nit, this.empresa).subscribe(
        resp => {
          this.loader_crt_rut = false;
          if (resp.status == 'success') {
            this.doc_rut = resp.documento;
            this.clientes.rut_doc = resp.documento;
            this.msn_crt_rut = rutdoc;
            this.renderer.removeClass(this.rut.nativeElement, "decorator");
            this.renderer.addClass(this.rut.nativeElement, "success");
          }
        },
        error => {
          this.loader_crt_rut = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }


  documentCamaraComercio(ev){

    let doc:any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);
    if (this.clientes.tributary_number_c==0  && this.codigo_pais=='CO') {
      alert('Por favor digite el Número de Identificación Tributaria');
      return false;
    } else {
      this.renderer.setAttribute(this.tributary_number.nativeElement, "readonly", "true");
    }
    if (sizekiloByte > 2048) {
      alert("No se permiten Archivos con tamaño superior a 2 MB");
      return false;
    }
    if(doc.files.length > 0){
      this.loader_crt_camara = true;
      let form = new FormData();
      var nit;
      if(this.codigo_pais=='CO'){
      nit = this.clientes.tributary_number_c;
      }else{
      nit=this.clientes.codigo_verificacion;
      }
      form.append('file0',doc.files[0]);
      let camdoc:any =doc.files[0].name;
      this._clientesService.subirDocumento(form,nit,this.empresa).subscribe(
        resp => {
          this.loader_crt_camara = false;
          if(resp.status == 'success'){
            this.doc_camara_comercio = resp.documento;
            this.clientes.camara_comercio_doc = resp.documento;
            this.msn_crt_camara = camdoc;
            this.renderer.removeClass(this.camara.nativeElement, "decorator");
            this.renderer.addClass(this.camara.nativeElement, "success");
          }
        },
        error => {
          this.loader_crt_camara = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }



  documentRepresentanteLegal(ev){

    let doc:any = ev.target;
    let sizeByte = doc.files[0].size;
    let sizekiloByte = sizeByte / 1024;
    console.log(sizeByte);
    if (this.clientes.tributary_number_c==0  && this.codigo_pais=='CO') {
      alert('Por favor digite el Número de Identificación Tributaria');
      return false;
    } else {
      this.renderer.setAttribute(this.tributary_number.nativeElement, "readonly", "true");
    }
    if (sizekiloByte > 2048) {
      alert("No se permiten Archivos con tamaño superior a 2 MB");
      return false;
    }
    if(doc.files.length > 0){
      this.loader_crt_rl = true;
      let form = new FormData();
      var nit;
      if(this.codigo_pais=='CO'){
      nit = this.clientes.tributary_number_c;
      }else{
      nit=this.clientes.codigo_verificacion;
      }
      form.append('file0',doc.files[0]);
      let rldoc:any =doc.files[0].name;
      this._clientesService.subirDocumento(form,nit,this.empresa).subscribe(
        resp => {
          this.loader_crt_rl = false;
          if(resp.status == 'success'){
            this.doc_representante_legal = resp.documento;
            this.clientes.representante_legal_doc = resp.documento;
            this.msn_crt_rl = rldoc;
            this.renderer.removeClass(this.representante.nativeElement, "decorator");
            this.renderer.addClass(this.representante.nativeElement, "success");
          }
        },
        error => {
          this.loader_crt_rl = false;
          alert('Por favor intente subir el archivo nuevamente. Fallo comunicación Servidor');
        }
      );

    }

  }





  onCountryChange(event) {
    console.log(event)
    this.indicativo_phone_pagaduria = "(+" + event.dialCode + ')';
    this.clientes.phone_pagaduria = this.indicativo_phone_pagaduria;

  }

  onCountryChange_1(event) {
    this.indicativo_phone_office = "(+" + event.dialCode + ')';
    this.clientes.phone_office = this.indicativo_phone_office;

  }


  onCountryChange_2(event) {
    this.indicativo_phone_alternate = "(+" + event.dialCode + ')';
    this.clientes.phone_alternate = this.indicativo_phone_alternate;
  }


  onCountryChange_3(event) {
    this.indicativo_phone_contabilidad = "(+" + event.dialCode + ')';
    this.clientes.phone_contabilidad = this.indicativo_phone_contabilidad;
  }
  precargarCliente(pre_id = '', empresa = 'brm') {
    this._route.params.subscribe(params => {
      let id = '';
      if (pre_id == '') {
        id = params['id'];
      } else {
        id = pre_id;
      }
      if (id != 'registro' ) {
        this.texto_spinner = 'Cargando Registro';
        this.spinner.show();
        this._clientesService.getAccounts(id, empresa).subscribe(
          response => {
            //console.log(response);
            if (response.status == 'success' && (this.procedimiento == 'edicion' || response.account.stage==6)) {
              this.mostrar_parrafo = true;
              //console.log(response);
              this.registro = response.account;
              this.account_cstm = response.account_cstm;
              this.dataSocios = response.dataSocios;
              //console.log(response.data_adicional);
              if (this.clientes.codigo_verificacion != '') {
                this.registro.codigo_verificacion = this.clientes.codigo_verificacion;
              }

              this.clientes = new Clientes(
                this.registro.id,//id,
                'registro',//registro_actualizacion,
                this.registro.name,
                this.registro.billing_address_street,//billing_address_street,
                this.registro.billing_country_id,//billing_country_id,
                this.registro.billing_city_id,//billing_city_id,
                this.registro.phone_office,//phone_office,
                this.registro.phone_alternate,//phone_alternate,
                this.registro.oc_factura,//oc_factura,
                this.account_cstm.tributary_id_c,//tributary_id_c,
                response.data_adicional.tributary_number_c, //tributary_number_c,
                this.account_cstm.person_type_c,//person_type_c,
                this.account_cstm.regime_type_c,//regime_type_c,
                this.account_cstm.autoholder_revenue_c,//autoholder_revenue_c,
                this.account_cstm.autoholder_iva_c,//autoholder_iva_c,
                this.account_cstm.closed_reception_invoices_c,//closed_reception_invoices_c,
                this.registro.rut_doc,//rut_doc,
                this.registro.camara_comercio_doc,//camara_comercio_doc
                response.data_adicional.nombres_localizacion,//nombres_localizacion,
                this.registro.email_contacto_pagaduria,//email_contacto_pagaduria,
                this.registro.gran_contribuyente,//gran_contribuyente,
                this.registro.gran_contribuyente_ica,//gran_contribuyente_ica,
                this.registro.conflicto_interes,//conflicto_interes,
                this.registro.primer_nombre_rl,//primer_nombre,
                this.registro.segundo_nombre_rl,//segundo_nombre,
                this.registro.primer_apellido_rl,//primer_apellido,
                this.registro.segundo_apellido_rl,//segundo_apellido,
                this.registro.tipo_identificacion,//tipo_identificacion,
                this.registro.numero_identificacion,//numero_identificacion,
                this.registro.codigo_verificacion,//codigo_verificacion,
                response.data_adicional.digito_verificacion,//digito_verificacion,
                this.registro.contacto_pagaduria,//contacto_pagaduria,
                this.registro.contacto_contabilidad,//contacto_contabilidad,
                this.registro.email_contacto_contabilidad,//email_contacto_contabilidad,
                this.registro.phone_contabilidad,//phone_contabilidad,
                this.registro.phone_pagaduria,//phone_pagaduria,
                this.registro.quien_realiza_registro,//quien_realiza_registro,
                this.registro.email_facturacion_electronica,//email_facturacion_electronica,
                this.registro.email_representante_legal,//email_representante_legal
                this.registro.pep_rl,//pep representante legal
                this.registro.representante_legal_doc,//documento representante legal
                this.registro.ejerce_cargo_publico_rl,
                this.registro.maneja_recurso_publico_rl,
                this.registro.cargo_publico_rl,
                this.registro.fecha_cargo_publico_rl,
                this.registro.cargo_recurso_publico_rl,
                this.registro.fecha_recurso_publico_rl,
              );

              if (this.registro.ejerce_cargo_publico_rl=='si') {
                this.cargo_publico_check_si = true;
                this.cargo_publico_check_no = false;
              } else if (this.registro.ejerce_cargo_publico_rl=='no') {
                this.cargo_publico_check_si = false;
                this.cargo_publico_check_no = true;
              } else {
                this.cargo_publico_check_si = false;
              }

              if (this.registro.maneja_recurso_publico_rl=='si') {
                this.maneja_recurso_publico_check_si = true;
                this.maneja_recurso_publico_check_no = false;
              } else if (this.registro.maneja_recurso_publico_rl=='no') {
                this.maneja_recurso_publico_check_si = false;
                this.maneja_recurso_publico_check_no = true;
              } else {
                this.maneja_recurso_publico_check_si = false;
              }

              this.valueCity = response.data_adicional.nombres_localizacion;
              console.log(this.codigo_pais);
              this.codigo_pais = response.codigo_pais;
              if (response.data_adicional.msn_crt_rut != '') {
                this.msn_crt_rut = response.data_adicional.msn_crt_rut;
                //Se comentarea para que pueda volver a subir otro doc diferente
                //this.renderer.removeClass(this.rut.nativeElement, "decorator");
                //this.renderer.addClass(this.rut.nativeElement, "success");
              }
              if(response.data_adicional.msn_crt_camara!=''){
                this.msn_crt_camara = response.data_adicional.msn_crt_camara;
                //this.renderer.removeClass(this.camara.nativeElement, "decorator");
                //this.renderer.addClass(this.camara.nativeElement, "success");
              }
              if(response.data_adicional.msn_crt_rl!=''){
                this.msn_crt_rl = response.data_adicional.msn_crt_rl;

              }
              if (this.codigo_pais == 'CO' && this.procedimiento !='creacion_rapida') {
                this.renderer.setStyle(this.div_clientes.nativeElement, "display", "");
               /* if(this.clientes.digito_verificacion!=''){*/
                this.renderer.addClass(this.dig_verifica.nativeElement, "deshabilitarClick");
                this.renderer.setAttribute(this.dig_verifica.nativeElement, "readonly", "true");
               /* }*/
                this.renderer.setAttribute(this.tributary_number.nativeElement, "readonly", "true");
                this.renderer.setStyle(this.informacion_inicial.nativeElement, "display", "");
              }
              
          //realizar if en este espacio si solicitan la informacion_inicial en el formulario de creacion rapida nacional cuando se precarga con la condicion de que sea nacional y procedimiento igual a creacion rapida//

              if(this.codigo_pais!='CO'){
                this.mostrar_internacionales=true;
                this.mostrar_parrafo=true;
                this.tooltip_firm_name='Nombre de la sociedad';
                //console.log(this.mostrar_internacionales);
                if(this.codigo_pais=='MX'){
                  this.label_doc_rl='INE/Documento de Identificación RL*';
                  this.label_doc_rut_internacional='RFC -CONSTANCIA DE SITUACIÓN FISCAL';
                  this.label_razon_social='Razón Social/Persona Fisica';
                  this.label_direccion='Domicilio Fiscal';
                  this.pattern_numero_identificacion='[a-zA-z0-9]{2,15}';
                  this.nombresLabels.splice(1, 1,   { value: '2', nombres: 'INE/No. de Identificación*' })
                }
              }
              if (Object.keys(this.dataSocios).length > 0) {
                this.agregarSocio();
              }
              this.procesarTipoIdentificacion();
              this.setearRequeridos();
              setTimeout(() => {
                this.spinner.hide();
                this.botones_finales = true;
              }, 2000);

            } else {
              alert('No se puede editar Cliente');
              this.texto_spinner = 'por favor Espere...';
              setTimeout(() => {
                this.spinner.hide();
              }, 2000);
              this._router.navigate(['clientes/registro/' + this.empresa]);
            }
          },
          error => {
            console.log(error.error);
            if (error.error.status == 'unauthorized' || error.error.status == 'error') {
              alert(error.error.message);
              this.spinner.hide();
              this._router.navigate(['clientes/registro/' + this.empresa]);
            } else {
              this.texto_spinner = 'Registro No Recuperable, por favor Espere...';
              alert('No se ha podido cargar la Informacion, consulte con soporte tecnico');
              setTimeout(() => {
                this.spinner.hide();
                this._router.navigate(['clientes/registro/' + this.empresa]);
              }, 5000);
            }
          }

        );
      } else {
        //this.clientes= new Clientes('1','', '','','','', '','', '', '', '', '', '', '', '', '', '', '', '', '', '', '','','','','','','','','','','','','','','','','','','','','','');
      }

    });

  }

  guardarBorrador() {
    let validar_campos = this.validarSociosBorrador();
    //console.log(validar_campos);
    if (validar_campos==0) {
        alert('va a guardar un borrador, posteriormente para que sea legalizado como cliente tiene que darle click en el botón enviar"')
        let guarda_borrador = 'si';
        if (this.codigo_pais == 'CO') {
          switch (this.clientes.tributary_id_c) {
            case 'nit':
              if (this.clientes.tributary_number_c == 0) {
                alert('No se ha guardado el cliente.El Campo:Número Identificación Tributaria / Fiscal* es requerido.')
                guarda_borrador = 'no';
              } else if (this.clientes.digito_verificacion == '') {
                alert('No se ha guardado el cliente.El Campo:Dígito de Verificación* es requerido.')
                guarda_borrador = 'no';
              }
              break;
            case 'cc':
              if (this.clientes.tributary_number_c == 0) {
                alert('No se ha guardado el cliente.El Campo:Número Identificación Tributaria / Fiscal* es requerido.')
                guarda_borrador = 'no';
              }
              this.clientes.digito_verificacion = '';
              break;
            case 'ce':
              if (this.clientes.tributary_number_c == 0) {
                alert('No se ha guardado el cliente.El Campo:Número Identificación Tributaria / Fiscal* es requerido.')
                guarda_borrador = 'no';
              }
              this.clientes.digito_verificacion = '';
              break;
            case '':
              alert('seleccione Identicación Tributaria (Tipo)*');
              guarda_borrador = 'no';
              break;
            case '- Ninguno -':
              alert('Seleccione Identicación Tributaria (Tipo)*');
              guarda_borrador = 'no';
              break;
          }
        }

        if (guarda_borrador == 'si') {
          let socios = this.registerPartner.value;
          //console.log(socios);
          this._clientesService.create(this.clientes, 'borrador', this.empresa, this.codigo_pais, this.procedimiento,socios).subscribe(
            response => {

              if (response.status == 'success') {
                this.clientes.id = response.message.id;
                //console.log(response.socios);
                for (let row of response.socios) {
                  //console.log(row);
                  for (var i = 0; i < 5; i++) {
                    if ( typeof this.socios.controls[i] != "undefined" && this.socios.controls[i].value.id.length == 0) {
                      console.log(this.socios.controls[i].value.numero_identificacion);
                      console.log(row.numero_identificacion);
                      if (this.socios.controls[i].value.numero_identificacion==row.numero_identificacion) {
                         //this.socios.controls[i].value.id = row.socio_id;
                         this.socios.at(i).get('id').setValue(row.socio_id);
                         console.log(row.socio_id);
                      }
                    }
                  }
                }
                if (this.codigo_pais == 'CO' && this.clientes.digito_verificacion != '' && this.clientes.tributary_id_c=='nit') {
                  this.renderer.addClass(this.dig_verifica.nativeElement, "deshabilitarClick");
                  this.renderer.setAttribute(this.dig_verifica.nativeElement, "readonly", "true");
                }
                alert('Borrador Guardado');
              }
            },
            error => {
              console.log(<any>error);
              let message;
              if (<any>error.error.validacion == 'The firm name field is required.') {
                message = 'Ingrese la Razón Social/Nombre Persona Natural';
              } else if (error.error.validacion) {
                message = <any>error.error.validacion;
              } else if (error.error.message) {
                message = <any>error.error.message;
              } else {
                message = <any>error.error;
              }
              alert(message);
              return false;
            }
          );
          //form.reset();
        }
    }

  }

  validarCliente(tipo = 0) {
    if (tipo == 1 && this.clientes.tributary_id_c == 'nit') {
      // code...No procesa
    } else if (this.clientes.tributary_id_c != '') {
      if (this.clientes.tributary_number_c == 0) {
        alert('Por favor digite el Número de Identificación Tributaria');
        return false;
      } else {
        this._clientesService.validarCliente(this.clientes.tributary_number_c, this.clientes.digito_verificacion, this.empresa).subscribe(
          resp => {
            console.log(resp);
            if (resp.status == 'success') {
              //Permite editar si esta en estado Borrador
              if (resp.estado == 6) {
                this.precargarCliente(resp.id, this.empresa);
                if (this.codigo_pais == 'CO' && this.procedimiento != "creacion_rapida") {
                  this.renderer.setStyle(this.div_clientes.nativeElement, "display", "");
                }
              } else {
                this.clientes.tributary_number_c = 0;
                this.clientes.codigo_verificacion = '';
                alert('Este cliente se encuentra registrado en el sistema, por lo tanto no se puede editar, por favor comuniquese con el area de Compras');
                return false;
              }
            } else {
              if (this.codigo_pais == 'CO' && this.procedimiento != "creacion_rapida") {
                this.renderer.setStyle(this.div_clientes.nativeElement, "display", "");
              }
            }
          },
          error => {
            console.log(error);
          }
        );
      }
    }
  }

  validarNumero() {

    let numero = this.clientes.tributary_number_c;
    if (isNaN(numero)) {
      this.clientes.tributary_number_c = 0;
      alert("El valor " + numero + " no es un número.");
    } else {
      if (numero % 1 == 0) {
        //console.log("Es un numero entero");
        this.clientes.tributary_number_c = numero;
        if (this.clientes.digito_verificacion != '') {
          this.validarCliente();
        }
      } else {
        this.clientes.tributary_number_c = 0;
        alert("El valor " + numero + " es un decimal, solo se admiten numeros enteros.");
      }
    }
  }

  obtenerEnlace() {
    this._clientesService.obtenerUrlTratamientoDatos(this.empresa).subscribe(
      response => {
        //console.log(response)
        if (response == 'no_aplica') {
          this.mostrar_parrafo = false;
        } else if (this.procedimiento != 'edicion') {
          this.mostrar_parrafo = true;
          this.enlace = <any>response;
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

   IsNumeric(expression)
{
    return (String(expression).search(/^\d+$/) != -1);
}

  validarCodigo(campo_codigo) {
    if (campo_codigo == 1){
      this._clientesService.validarCodigoVerificacion(this.clientes.codigo_verificacion, this.empresa).subscribe(
        resp => {
          if(this.codigo_pais!='CO' && (resp.status=='success' || resp.status=='no_encontrado')){
            this.mostrar_internacionales=true;
            this.botones_finales = true;
          }
          if(this.codigo_pais=='CO' && (resp.status=='success' || resp.status=='no_encontrado')){
            this.renderer.setStyle(this.informacion_inicial.nativeElement, "display", "");
            this.botones_finales = true;
          }
          //console.log(resp);
          if (resp.status == 'success') {

            //Permite editar si esta en estado Borrador
            if (resp.estado == 6) {
              this.precargarCliente(resp.id, this.empresa);
              if (this.codigo_pais=='CO' && this.procedimiento !="creacion_rapida") {
                this.renderer.setStyle(this.div_clientes.nativeElement, "display", "");
              }
            } else {
              this.clientes.codigo_verificacion = '';
              alert('Este cliente se encuentra registrado en el sistema, por lo tanto no se puede editar, por favor comuniquese con el area de Compras');
              return false;
            }
          } else if (resp.status == 'no_valido') {
            this.clientes.codigo_verificacion = '';
            alert('El codigo Ingresado no es valido, por favor ingrese el que se envio en el correo de invitacion');

            return false;
          }
        },
        error => {
          console.log(error);
        }
      );
    } else {
      if (this.clientes.codigo_verificacion == '') {
        this.clientes.name = '';
        alert('Por favor digite el Codigo de Verificacion que se envio en el correo de invitacion');
        return false;
      }
    }
  }

  camposRequeridos(enviarform = 0, validarSocios=0) {
    if (validarSocios==1 && this.procedimiento !='creacion_rapida') {
      let sociosObject = this.registerPartner.value;
      let sociosArray = sociosObject.socios;
      let requeridosTmp = this.messagesSocios;
      var requeridosGen = {};
      var msg = [];
      var contador = 1;
      for (let row of sociosArray) {
        var arrayNit = 0;
          for (const indice in requeridosTmp) {
            if (indice=='tipo_identificacion'&& row[indice] == 'NIT'||row[indice] == 'RFC') {
              arrayNit = 1;
            }
          }
          if (arrayNit==1) {
            requeridosGen = this.messagesSociosXNit;
          } else {
            requeridosGen = this.messagesSocios;
          }
          for (const indice in requeridosGen) {
            if (row[indice] == '' || row[indice] == 0) {
              msg.push('En Socio/Beneficiario Nº '+contador+' falta campo: <strong>'+requeridosTmp[indice]+'</strong>');
            }

          }
          contador++;
      }
      if(msg.length >0){
        this.titulo_modal_socios = 'Falta Informacion Socios';
        this.texto_modal_socios = msg.join('<br>');
        this.openModalSocios();
        return false;
      }
    }
    requeridosArray = [];
    if (this.codigo_pais == 'CO' && this.empresa!='brmmx' && this.procedimiento !='creacion_rapida') {
        var requeridos = ['codigo_verificacion', 'name', 'phone_office', 'phone_alternate', 'billing_address_street', 'contacto_pagaduria',
        'email_contacto_pagaduria','tributary_id_c', 'tributary_number_c', 'phone_pagaduria', 'contacto_contabilidad', 'email_contacto_contabilidad',
        'phone_contabilidad', 'closed_reception_invoices_c', 'email_facturacion_electronica', 'person_type_c', 'regime_type_c','gran_contribuyente',
        'gran_contribuyente_ica', 'autoholder_revenue_c','autoholder_iva_c','rut_doc','quien_realiza_registro','primer_nombre_rl','primer_apellido_rl','segundo_apellido_rl','tipo_identificacion', 'numero_identificacion','ejerce_cargo_publico_rl','maneja_recurso_publico_rl'];
                
        if(this.codigo_pais=='CO'  && this.clientes.quien_realiza_registro=="Delegado representante legal"){
          var requeridos = ['codigo_verificacion', 'name', 'phone_office',  'billing_address_street', 'contacto_pagaduria',
          'email_contacto_pagaduria','tributary_id_c', 'tributary_number_c', 'phone_pagaduria', 'contacto_contabilidad', 'email_contacto_contabilidad',
          'phone_contabilidad', 'closed_reception_invoices_c', 'email_facturacion_electronica', 'person_type_c', 'regime_type_c','gran_contribuyente',
          'gran_contribuyente_ica', 'autoholder_revenue_c','autoholder_iva_c','rut_doc','quien_realiza_registro','email_representante_legal','primer_nombre_rl','primer_apellido_rl', 'segundo_apellido_rl','tipo_identificacion', 'numero_identificacion','ejerce_cargo_publico_rl','maneja_recurso_publico_rl'];
          if (this.clientes.tributary_id_c=='nit') {
            requeridos.push('digito_verificacion');
          } else {
            if (requeridos.indexOf('digito_verificacion') !== -1) {
              var indice = requeridos.indexOf('digito_verificacion');
              delete requeridos[indice];
            }
          }         
        }
    } else if(this.procedimiento !='creacion_rapida') {
      if(this.codigo_pais=='MX' && this.empresa=='brmmx'){
        //var requeridos = ['codigo_verificacion','name','phone_office','billing_address_street','contacto_pagaduria','email_contacto_pagaduria','rut_doc','quien_realiza_registro','email_representante_legal','primer_nombre_rl','primer_apellido_rl','segundo_apellido_rl','tipo_identificacion', 'numero_identificacion'];
        var requeridos = ['codigo_verificacion','name','phone_office','billing_address_street','contacto_pagaduria','email_contacto_pagaduria','tributary_number_c','quien_realiza_registro','primer_nombre_rl','primer_apellido_rl','segundo_apellido_rl','tipo_identificacion', 'numero_identificacion','ejerce_cargo_publico_rl','maneja_recurso_publico_rl'];
        if(this.clientes.quien_realiza_registro=="Delegado representante legal"){
          var requeridos = ['codigo_verificacion','name','phone_office','billing_address_street','contacto_pagaduria','email_contacto_pagaduria','quien_realiza_registro','primer_nombre_rl','primer_apellido_rl','segundo_apellido_rl','tipo_identificacion', 'numero_identificacion', 'email_representante_legal','ejerce_cargo_publico_rl','maneja_recurso_publico_rl'];
        }
      } else {
        var requeridos = ['codigo_verificacion','name','phone_office','billing_address_street','contacto_pagaduria','email_contacto_pagaduria','quien_realiza_registro','primer_nombre_rl','primer_apellido_rl','segundo_apellido_rl','tipo_identificacion', 'numero_identificacion','ejerce_cargo_publico_rl','maneja_recurso_publico_rl'];
        if(this.codigo_pais!='CO'  && this.clientes.quien_realiza_registro=="Delegado representante legal"){
          var requeridos = ['codigo_verificacion','name','phone_office','billing_address_street','contacto_pagaduria','email_contacto_pagaduria','quien_realiza_registro','primer_nombre_rl','primer_apellido_rl','segundo_apellido_rl','tipo_identificacion', 'numero_identificacion', 'email_representante_legal','ejerce_cargo_publico_rl','maneja_recurso_publico_rl'];
        }
      }
    } else if(this.procedimiento =='creacion_rapida') {
      var requeridos = ['name','phone_office','billing_address_street','email_representante_legal','primer_nombre_rl','primer_apellido_rl','segundo_apellido_rl','tipo_identificacion', 'numero_identificacion','ejerce_cargo_publico_rl','maneja_recurso_publico_rl','rut_doc'/*,'representante_legal_doc'*/];
      if(this.codigo_pais=='CO'){
        if (this.clientes.tributary_id_c=='nit') {
          requeridos.push('digito_verificacion');
        } else {
          if (requeridos.indexOf('digito_verificacion') !== -1) {
            var indice = requeridos.indexOf('digito_verificacion');
            delete requeridos[indice];
          }
        } 
        requeridos.push('camara_comercio_doc');
        if (requeridos.indexOf('contacto_pagaduria') !== -1) {
          var indice = requeridos.indexOf('contacto_pagaduria');
          delete requeridos[indice];
        }
        if (requeridos.indexOf('email_contacto_pagaduria') !== -1) {
          var indice = requeridos.indexOf('email_contacto_pagaduria');
          delete requeridos[indice];
        }
        messages.rut_doc = 'Copia del RUT / Documento Fiscal';
      } else {
        if (requeridos.indexOf('camara_comercio_doc') !== -1) {
          var indice = requeridos.indexOf('camara_comercio_doc');
          delete requeridos[indice];
        }
        requeridos.push('contacto_pagaduria','email_contacto_pagaduria');
        messages.rut_doc = 'Certificado de Incorporación';
      }
    }

    if (this.clientes.ejerce_cargo_publico_rl=='si') {
      requeridos.push('cargo_publico_rl','fecha_cargo_publico_rl');
    } else {
      if (requeridos.indexOf('cargo_publico_rl') !== -1) {
        var indice = requeridos.indexOf('cargo_publico_rl');
        delete requeridos[indice];
      }
      if (requeridos.indexOf('fecha_cargo_publico_rl') !== -1) {
        var indice = requeridos.indexOf('fecha_cargo_publico_rl');
        delete requeridos[indice];
      }
    }
    if (this.clientes.maneja_recurso_publico_rl=='si') {
      requeridos.push('cargo_recurso_publico_rl','fecha_recurso_publico_rl');
    } else {
      if (requeridos.indexOf('cargo_recurso_publico_rl') !== -1) {
        var indice = requeridos.indexOf('cargo_recurso_publico_rl');
        delete requeridos[indice];
      }
      if (requeridos.indexOf('fecha_recurso_publico_rl') !== -1) {
        var indice = requeridos.indexOf('fecha_recurso_publico_rl');
        delete requeridos[indice];
      }
    }

    for (let item in this.clientes) {
      if (this.clientes[item] == '' || this.clientes[item] == null) {
        if( !this.IsNumeric(this.clientes[item])){
          requeridos.forEach(function (valor, indice, array) {
            if (valor == item) {
              requeridosArray.push(messages[valor]);
            }
          });
        }
      }
    }

    this.prueba = requeridosArray;
    console.log(this.prueba);
    if (Object.keys(this.prueba).length > 0) {
      this.mostrarcampos = true;
    } else {
      this.mostrarcampos = false;
      //this.enviar_internacionales=true;
      if (enviarform == 1) {
        this.onSubmit();
      }
    }

  }

  setearRequeridos() {
    if (this.codigo_pais == 'CO' && this.procedimiento !='creacion_registro') {
      this.codigo_verificacion_req = true;
      this.firm_name_req = true;
      this.phone_office_req = true;
      //this.phone_alternate_req = true;
      this.billing_address_street_req = true;
      this.contacto_pagaduria_req = true;
      this.email_contacto_pagaduria_req = true;
      this.tributary_id_c_req=true;
      this.tributary_number_c_req = true;
      this.digito_verificacion_req = true;
      this.phone_pagaduria_req = true;
      this.contacto_contabilidad_req = true;
      this.email_contacto_contabilidad_req = true;
      this.phone_contabilidad_req = true;
      this.closed_reception_invoices_c_req = true;
      this.email_facturacion_electronica_req = true;
      this.person_type_c_req = true;
      this.regime_type_c_req = true;
      this.gran_contribuyente_req = true;
      this.gran_contribuyente_ica_req = true;
      this.autoholder_revenue_c_req = true;
      this.autoholder_iva_c_req = true;
      this.rut_doc_req = true;
      this.primer_nombre_rl_req = true;
      this.primer_apellido_rl_req=true;
      this.segundo_apellido_rl_req=true;
      this.tipo_identificacion_req = true;
      this.numero_identificacion_req = true;
      this.quien_realiza_registro_req=true;
      if(this.clientes.quien_realiza_registro=="Delegado representante legal"){
        this.email_representante_legal_req=true;
      }

    }else if (this.codigo_pais == 'CO' && this.procedimiento =='creacion_registro') {
      this.codigo_verificacion_req = true;
      this.firm_name_req = true;
      this.phone_office_req = true;
      //this.phone_alternate_req = true;
      this.billing_address_street_req = true;
      this.contacto_pagaduria_req = true;
      this.email_contacto_pagaduria_req = true;
      this.tributary_id_c_req=true;
      this.tributary_number_c_req=true;
      this.digito_verificacion_req=true;
    }  else {
      this.codigo_verificacion_req = true;
      this.firm_name_req = true;
      this.phone_office_req = true;
     // this.phone_alternate_req = true;
      this.billing_address_street_req = true;
      this.contacto_pagaduria_req = true;
      this.email_contacto_pagaduria_req = true;
      this.primer_nombre_rl_req = true;
      this.segundo_nombre_rl_req=true;
      this.primer_apellido_rl_req=true;
      this.segundo_apellido_rl_req=true;/*habilitar segundo apellido requerido solicitud 28/09/2021 por: Victor Espinosa*/
      this.tipo_identificacion_req = true;
      this.numero_identificacion_req = true;
      this.quien_realiza_registro_req=true;
      if(this.clientes.quien_realiza_registro=="Delegado representante legal"){
        this.email_representante_legal_req=true;
      }
    }
  }

  openModalSocios() {
    let templateM = this.modalSocios;
    this.modalRef = this.modalService.show(templateM, { class: 'modal-lg' });
  }
  closeModalSocios() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }
  closeModal(modalId?: number){
    this.modalService.hide(modalId);
  }

  validarSociosBorrador(){
    let sociosObject = this.registerPartner.value;
    let sociosArray = sociosObject.socios;
    let campos = this.camposSocios;
    var validarCampos = 0;
    for (let row of sociosArray) {
        for (const indice in campos) {
          if (row[indice] == '' || row[indice] == 0 || row[indice] == null) {
          } else {
            validarCampos = 1;
          }
        }
    }
    console.log(validarCampos);
    if (validarCampos==1) {
      var msg = [];
      var contador = 1;
      var validarCampos = 0;
      var requeridosTmp = this.messagesSocios;
      var requeridosGen = {};
      for (let row of sociosArray) {
          var arrayNit = 0;
          for (const indice in requeridosTmp) {
            if (indice=='tipo_identificacion'&& row[indice] == 'NIT'|| row[indice] == 'RFC') {
              arrayNit = 1;
            }
          }
          if (arrayNit==1) {
            requeridosGen = this.messagesSociosXNit;
          } else {
            requeridosGen = this.messagesSocios;
          }
          for (const indice in requeridosGen) {
            if (row[indice] == '' || row[indice] == 0) {
              msg.push('En Socio/Beneficiario Nº '+contador+' falta campo: <strong>'+requeridosTmp[indice]+'</strong>');
            }
          }
          contador++;
      }
      if(msg.length >0){
        this.titulo_modal_socios = 'Falta Informacion Socios';
        this.texto_modal_socios = msg.join('<br>');
        this.openModalSocios();
        validarCampos = 1;
      }
      return validarCampos;
  } else {
    return validarCampos;
  }

  }

  socioTipoId(event,index){
    var nombreCampo=event.target.value;
      if(nombreCampo=='NIT' || nombreCampo=='RFC'){
        this.socios.at(index).get('segundo_nombre').disable();
        this.socios.at(index).get('primer_apellido').disable();
        this.socios.at(index).get('segundo_apellido').disable();
        this.socios.at(index).get('pep').disable();
      }else{
        this.socios.at(index).get('segundo_nombre').enable();
        this.socios.at(index).get('primer_apellido').enable();
        this.socios.at(index).get('segundo_apellido').enable();
        this.socios.at(index).get('pep').enable();
     }
  }

  validarNumeroIdentificacion(evt){
    // code Equivale a codigo ASCII.
    var code = (evt.which) ? evt.which : evt.keyCode;
    if (this.codigo_pais=='CO') {
      //solo Numeros
      if((code >= 48 && code <= 57) || // Números
          (code >= 65 && code <= 90) || // Letras mayúsculas
          (code >= 97 && code <= 122) || // Letras minúsculas
          (code == 45) || // Guiones
          (code == 8)) { // Retroceso
        return true;
      } else{ // otras teclas.
        return false;
      }
    } else {
      //Alfanumerico
      if((code >= 48 && code <= 57) || // Números
          (code >= 65 && code <= 90) || // Letras mayúsculas
          (code >= 97 && code <= 122) || // Letras minúsculas
          (code == 45) || // Guiones
          (code == 8)) { // Retroceso
        //letras numeros y retroceso
        return true;
      } else{ // otras teclas.
        return false;
      }
    }
  }

  procesarTipoIdentificacion(){
    //for (let row of this.tipo_identificacion_original) {
    for (const indice in this.tipo_identificacion_original) {
      if (this.codigo_pais==indice) {
          this.tipo_identificacion_persona = this.tipo_identificacion_original[indice];
      }
    }
    if (typeof this.tipo_identificacion_persona == "undefined") {
      this.tipo_identificacion_persona = this.tipo_identificacion_original['CO'];
    }
  }



  //Cierre clase

}


