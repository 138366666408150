import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ProvidersComponent } from './componentes/providers/providers.component';
import {ClientesComponent} from './componentes/clientes/clientes.component';
import {NovedadComponent} from './componentes/novedad/novedad.component';

import{FormsModule} from '@angular/forms';
import {HttpClientModule}  from '@angular/common/http';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {ReactiveFormsModule}from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalModule } from 'ngx-bootstrap/modal';
import { UploadRutComponent } from './componentes/upload-rut/upload-rut.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    ProvidersComponent,
    ClientesComponent,
    NovedadComponent,
    UploadRutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AutocompleteLibModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    Ng2TelInputModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
