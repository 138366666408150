export class Proveedor{


  constructor(
    public id: string,
    public registro_actualizacion:string,
    public firm_name:string,
    public compania_contratante:string,
    public phone_office:string,
    public phone_alternate:string,
    public billing_address_street:string,
    public billing_country_id:string,
    public billing_city_id:string,
    public nombres_localizacion:string,
    public contacto_comercial:string,
    public email_contacto_comercial:string,
    public tributary_id:string,
    public tributary_number:number,
    public person_type:string,
    public regimen_type:string,
    public ciudad_tributo_ica_id:string,
    public gran_contribuyente:string,
    public gran_contribuyente_ica:string,
    public autoholder_revenue:string,
    public autoholder_iva:string,
    public fecha_resolucion_fact:string,
    public vigencia_resolucion_fact:string,
    public id_actividad_principal:string,
    public id_actividad_secundaria :string,
    public banco_nacional:string,
    public banco_internacional:string,
    public tipo_cuenta:string,
    public numero_cuenta:string,
    public numero_swift:string,
    public conflicto_interes:string,
    public primer_nombre_rl:string,
    public segundo_nombre_rl:string,
    public primer_apellido_rl:string,
    public segundo_apellido_rl:string,
    public tipo_identificación_rl:string,
    public numero_identificacion_rl:string,
    public codigo_verificacion:string,
    public description:string,
    public tipo_identificacion_rl:string,
    public digito_verificacion:string,
    public certificacion_pci:string,
    public rut_doc:string,
    public certificado_representante_doc:string,
    public certificado_cuenta_doc:string,
    //public documento_rl_doc:string,
    public rfc_doc:string,
    public facturacion_vigente_doc:string,
    public quien_realiza_registro:string,
    public email_representante_legal:string,
    public pep_rl:string,
    public representante_legal_doc:string,
    public ejerce_cargo_publico_rl:string,
    public maneja_recurso_publico_rl:string,
    public cargo_publico_rl:string,
    public fecha_cargo_publico_rl:string,
    public cargo_recurso_publico_rl:string,
    public fecha_recurso_publico_rl:string,

  ){

  }
}

export class Requeridos {
  constructor(
    public phone_office:boolean,
    public phone_alternate:boolean,
    public billing_address_street: false,
    public email_contacto_comercial: false,
    public certificado_representante_doc: false,
    public certificado_cuenta_doc: false,
    public codigo_verificacion: false,
    public firm_name: false,
    public contacto_comercial: false,
    public numero_cuenta: false,
    public email_representante_legal:false,
    public quien_realiza_registro:false,
    public nombres_apellidos_rl: false,
    public numero_identificacion_rl: false,
    public tipo_identificacion_rl: false,
    public id_actividad_principal: false,
    public rut_doc: false,
    public tributary_number: false,
    public person_type: false,
    public regimen_type: false,
    public gran_contribuyente: false,
    public gran_contribuyente_ica: false,
    public autoholder_revenue: false,
    public autoholder_iva: false,
    public description: false,
    public banco_nacional: false,
    public tipo_cuenta: false
  ){

  }
}

