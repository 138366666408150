import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { global } from '../global';

@Injectable({
  providedIn: 'root'
})
export class UploadRutService {

  public url: string;

	constructor(public _http: HttpClient){
		this.url = global.url;
	}

  public guardarRut(data: any): any {
    return this._http.post<any>(this.url+'upload/rut/',data).pipe(map((data) => data));
  }
}
