<div>
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">{{texto_spinner}}</p>
  </ngx-spinner>
</div>
<div class="container-fluid" style="margin: 85px;">
  <nav class="navbar navbar-dark navbar-expand-lg fixed-top bg-white border-warning shadow pulse animated" style="
        filter: blur(0px) brightness(92%) contrast(111%) grayscale(11%)
          hue-rotate(0deg) invert(0%) saturate(107%) sepia(0%);
        opacity: 0.99;
      ">
    <div class="container-fluid">
      <a class="navbar-brand text-uppercase text-left text-light shadow-none visible"
        href="http://165.22.42.184:90/novedades/registro" style="font-family: 'Roboto Condensed', sans-serif;">
          <ul><img src="{{companiaLogo}}" width="200px" height="50px" alt=""
              class="d-inline-block align-top" loading="lazy"></ul>
      </a><button data-toggle="collapse" class="navbar-toggler" data-target="#navcol-1">
        <span class="sr-only">Toggle navigation</span><span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse text-success bg-danger border-success d-table-cell" id="navcol-1">
        <ul class="nav navbar-nav">
          <li class="nav-item" role="presentation"></li>
          <li class="nav-item" role="presentation"></li>
          <li class="nav-item" role="presentation"></li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<br />
<div class="container-fluid">
  <div class="card text-light shadow-lg col-12">
    <div
      class="card-body text-left text-body border rounded border-light flex-row visible"
    >
      <h3 class="text-uppercase text-left card-title">Registro<br /></h3>
      <hr />
      <h5 class=" text-left text-muted card-subtitle mb-2">
        Se incorpora la novedad y sus soportes <br /><br />
      </h5>
      <h6 class=" text-left text-muted card-subtitle mb-2">
        Para el registro asegurece que tiene el documento de identidad del
        empleado/trabajador en misión, asi como los soportes correspondientes.
        <br /><br />
      </h6>

      <form #novedadesForm="ngForm"  (ngSubmit)="onSubmit(novedadesForm)" autocomplete="off">
        <div class="form-row">
          <div class="form-group col-md-12">
            <h5>EMPLEADO</h5>
            <hr />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-0">

            <input
              id="compania"
              name="compania"
              class="form-control"
              type="hidden"
              value="value"
              #compania="ngModel"
              [(ngModel)]="novedades.compania"
              required
            >

          </div>
        </div>
        <div class="form-row">

          <div>

        </div>
        <div class="ng-autocomplete form-group col-md-2">
          <label class="text-center"><strong  >Numero de Documento*</strong><br></label>
          <ng-autocomplete
          [data]="data"
          [disabled]="solo_lectura"
          [searchKeyword]="keyword"
          [placeHolder]="valuedocument"
          (selected)='selectEvent($event)'
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate"
          tooltip='Se refiere al numero de documento de identidad del empleado o trabajador en misión.' >
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.cedula"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>

          <div class="form-group col-md-2">
            <label class="bmd-label-floating" class="text-center"
              ><strong>Contratante</strong><br
            /></label>
           <input
           class="form-control"
           readonly
           placeholder="{{contratante_label}}">        </div>
          <div class="form-group col-md-2">
            <label class="bmd-label-floating" class="text-center"
              ><strong>Tipo vinculación</strong><br
            /></label>
            <input
              id="tipo_vinculacion"
              name="tipo_vinculacion"
              class="form-control"
              type="text"
              tooltip="Si es empleado directo de la compañia señalada o Trabajador en misión"
              readonly
              #tipo_vinculacion="ngModel"
              [(ngModel)]="novedades.tipo_vinculacion"

            >
          </div>
          <div class="form-group col-md-2">
            <label class="bmd-label-floating" class="text-center"
              ><strong>Estado Contratacion</strong><br
            /></label>
            <input
              id="estado_contratacion"
              name="estado_contratacion"
              class="form-control"
              type="text"
              tooltip="Estado de Contratacion de empleado o Trabajador en misión"
              readonly
              #estado_contratacion="ngModel"
              [(ngModel)]="novedades.estado_contratacion">
          </div>

          <div class="form-group col-md-2">
            <label class="bmd-label-floating" class="text-center"
              ><strong>Centro de Costo</strong><br
            /></label>
            <input
              id="centro_costo"
              name="centro_costo"
              class="form-control"
              type="text"
              pattern="[0-9]{5,15}"
              tooltip="(Si es directo(N.A)si es temporal selecciona cual"
              readonly
              #centro_costo="ngModel"
              [(ngModel)]="novedades.centro_costo"

            >
          </div>
          <div class="form-group col-md-2">
            <label class="bmd-label-floating" class="text-center"
              ><strong>Subcentro de Costo</strong><br
            /></label>
            <input
              id="subcentrocosto"
              name="subcentrocosto"
              class="form-control"
              type="text"
              tooltip="Si es empleado directo de la compañia señalada o Trabajador en misión"
              readonly
              #scc="ngModel"
              [(ngModel)]="novedades.subcentro_costo"

            >
          </div>


        </div>
          <div class="form-row">
            <div class="form-group col-md-2">
              <label class="bmd-label-floating" class="text-center"
                ><strong>Campaña</strong><br
              /></label>
              <input
                id="campania"
                name="campania"
                class="form-control"
                type="text"
                tooltip="Estado de Contratacion de empleado o Trabajador en misión"
                readonly
                #campania="ngModel"
                [(ngModel)]="novedades.campania">
            </div>
            <div class="form-group col-md-2">
              <label class="bmd-label-floating" class="text-center"
                ><strong>Teléfono</strong><br
              /></label>
              <input
                id="telefono"
                name="telefono"
                class="form-control"
                type="text"
                tooltip="Telefono de empleado o Trabajador en misión"
                readonly
                #telefono="ngModel"
                [(ngModel)]="novedades.telefono">
            </div>

          </div>

        <h5> NOVEDAD</h5>
          <hr>
          <div class="form-row">
          <div class="form-group col-md-2"  [hidden]='desha_consecutivo_estado'>
            <label class="bmd-label-floating" class="text-center"
              ><strong>Consecutivo Novedad</strong><br
            /></label>
           <input
           class="form-control"
           readonly
           [hidden]='desha_consecutivo_estado'
           placeholder="{{consecutivo_novedad}}">        </div>

           <div class="form-group col-md-2"  [hidden]='desha_consecutivo_estado'>
            <label class="bmd-label-floating" class="text-center"
              ><strong>Estado Novedad</strong><br
            /></label>
           <input
           class="form-control"
           readonly
           [hidden]='desha_consecutivo_estado'
           placeholder="{{estado_novedad}}">        </div>
          </div>

          <div class="form-row">
          <div class="form-group col-md-3">
            <label class="bmd-label-floating" class="text-center"
              ><strong>Tipo Novedad*</strong><br
            /></label>
            <select
              id="tipo_novedad"
              name="tipo_novedad"
              class="form-control"
              type="text"
              (change)="validarNovedad()"
              #tipo_novedad="ngModel"
              [(ngModel)]="novedades.tipo_novedad"
              tooltip="Seleccione el Tipo de novedad, el sistema solo permite la seleccion  de un tipo de novedad,
              si requiere indicar otra adicional debera registrarla como una novedad adicional."
              required
              [disabled]="solo_lectura"
            > <option value="{{tipo.id}}" *ngFor="let tipo of tipos" >{{tipo.nombre}}</option></select>
            <span *ngIf="!tipo_novedad.valid && tipo_novedad.touched">El Campo: tipo_novedad es requerido.</span>
          </div>

          <div class="form-group col-md-2" *ngIf="novedades.tipo_novedad =='incapacidad_eg'|| novedades.tipo_novedad =='incapacidad_lm'||novedades.tipo_novedad =='licencia_paternidad'||novedades.tipo_novedad =='accidente_de_trabajo' ">
            <label class="bmd-label-floating" class="text-center"
              ><strong>EPS de incapacidad*</strong><br
            /></label>
            <select
             [disabled]="solo_lectura"
              id="eps"
              name="eps"
              class="form-control"
              type="text"
              #eps="ngModel"
              [(ngModel)]="novedades.eps"
              tooltip="Seleccione la Eps con la cual genero la incapacidad."
              required
            > <option value="{{salud.id_eps}}" *ngFor="let salud of tipo_eps" >{{salud.nombre}}</option></select>
            <span *ngIf="!eps.valid && eps.touched">El Campo: EPS de incapacidad es requerido.</span>
          </div>

          <div class="form-group col-md-2"  *ngIf="novedades.eps!=''">
            <label class="bmd-label-floating" class="text-center"
              ><strong>Número incapacidad*</strong><br
            /></label>
            <input
              [disabled]="solo_lectura"
              id="numero_incapacidad"
              name="numero_incapacidad"
              class="form-control"
              type="text"
              tooltip="Ingrese el Numero de incapacidad"
              #numero_incapacidad="ngModel"
              required
              [(ngModel)]="novedades.numero_incapacidad">
              <span *ngIf="!numero_incapacidad.valid && numero_incapacidad.touched">El Campo: Número incapacidad es requerido.</span>
          </div>

          <div class="form-group col-md-2" *ngIf="novedades.eps!=''" >
            <label class="bmd-label-floating" class="text-center" class="bmd-label-floating"><strong> Inicial o Prórroga*</strong><br></label>
            <select  id="incapacidad_prorroga"
              name="incapacidad_prorroga"
              class="form-control"
              (change)="ValidarProrroga()"
              #incapacidad_prorroga="ngModel"
              required
              [disabled]="solo_lectura"
              [(ngModel)]="novedades.incapacidad_prorroga">
              <option value="{{estado.value}}" *ngFor="let estado of inicial_prorroga">{{estado.nombre}}</option>
            </select>
            <span *ngIf="!incapacidad_prorroga.valid && incapacidad_prorroga.touched">El Campo: Inicial o Prórroga es requerido.</span>
        </div>


        <div class="form-group col-md-3" *ngIf="novedades.incapacidad_prorroga=='prorroga'" >
          <label class="bmd-label-floating" class="text-center"
            ><strong>Número inicial incapacidad*</strong><br
          /></label>
          <input
            id="numero_incapacidad_prorroga"
            name="numero_incapacidad_prorroga"
            class="form-control"
            type="text"
            [disabled]="solo_lectura"
            tooltip="Ingrese el Numero de incapacidad"
            #numero_incapacidad_prorroga="ngModel"
            [(ngModel)]="novedades.numero_incapacidad_prorroga">
            <span *ngIf="!numero_incapacidad_prorroga.valid && numero_incapacidad_prorroga.touched">El Campo: Número inicial incapacidad es requerido.</span>
        </div>


          <div class="form-group col-md-2">
            <label class="text-center"
              ><strong>Fecha Inicial Novedad*</strong><br
            /></label>
            <input
              id="inicio_novedad"
              name="inicio_novedad"
              class="form-control"
              type="date"
              (change)="ValidarFechaInicial()"
              [disabled]="solo_lectura"
              #inicio_novedad="ngModel"
              [(ngModel)]="novedades.inicio_novedad"
              tooltip="Fecha en la cual se produjo la Novedad"
            required
            />
            <span *ngIf="!inicio_novedad.valid && inicio_novedad.touched">El Campo: Fecha Inicial Novedad es requerido.</span>
          </div>
          <div class="form-group col-md-2" >
            <label class="text-center"
              ><strong>Fecha Final Novedad*</strong><br
            /></label>
            <input
              id="final_novedad"
              name="final_novedad"
              class="form-control"
              type="date"
              (change)="ValidarFechaFinal()"
              [disabled]="solo_lectura"

              #final_novedad="ngModel"
              [(ngModel)]="novedades.final_novedad"
              required
            />
            <span *ngIf="!final_novedad.valid && final_novedad.touched">El Campo: Fecha Final Novedad es requerido.</span>
          </div>

          <div class="form-group col-md-2">
            <label class="text-center"
              ><strong>Fecha Radicación Novedad</strong><br
            /></label>
            <input
              id="radicacion_novedad"
              name="radicacion_novedad"
              class="form-control"
              type="date"
              [disabled]="solo_lectura"
              #radicacion_novedad="ngModel"
              [(ngModel)]="novedades.radicacion_novedad"
            />
          </div>


          <div class="form-group col-md-3">
            <label class="bmd-label-floating" class="text-center"><strong>Diagnostico</strong><br></label>
            <textarea id="diagnostico"
            name="diagnostico"
            class="form-control"
            type="text"
            [disabled]="solo_lectura"
            maxlength="250"
            #diagnostico="ngModel"
            [(ngModel)]="novedades.diagnostico"
             tooltip="Texto Incluido en el soporte de la incapacidad "></textarea>
        </div>

        </div>
        <div   class="form-row">
        <!--el campo prorroga se oculto, pero Debe  guardar el valor del campo determinado por el campo inicial o prorroga  datos en BD-->
        <div hidden class="form-group col-md-0">
          <label class="text-center"><strong>Prórroga</strong><br /></label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="prorroga"
              id="prorroga"
              value="1"
              [disabled]="solo_lectura"
              #prorroga="ngModel"
              [(ngModel)]="novedades.prorroga"

            />
            <label class="form-check-label" for="exampleRadios1">
              Si
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="prorroga"
              id="prorroga"
              value="0"
              [disabled]="solo_lectura"
              #prorroga="ngModel"
              [(ngModel)]="novedades.prorroga"
            />
            <label class="form-check-label" for="exampleRadios2">
              No
            </label>
          </div>
          <small id="passwordHelpInline" class="text-muted">
            Indique si desea prorrogar de acuerdo al tipo de novedad.
          </small>
        </div>

        <div class="form-group col-md-3">
          <label class="text-center"><strong>Kactus</strong><br /></label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              [disabled]="solo_lectura"
              name="registro_kactus"
              id="registro_kactus"
              value="1"
              #kactus="ngModel"
              [(ngModel)]="novedades.registro_kactus"

            />
            <label class="form-check-label" for="exampleRadios1">
              Si
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              [disabled]="solo_lectura"
              name="registro_kactus"
              id="registro_kactus"
              value="0"
              #kactus="ngModel"
              [(ngModel)]="novedades.registro_kactus"
            />
            <label class="form-check-label" for="exampleRadios2">
              No
            </label>
          </div>
          <small id="passwordHelpInline" class="text-muted">
            Indique si la novedad fue o no registrada en Kactus.
          </small>
        </div>

          <div    class="form-group col-md-3">
            <label><strong>Soporte (1)*</strong><br /></label><br />
            <div *ngIf="solo_lectura">
              <a href="{{enlace_soporte1}}" target="blank">{{msn_crt_nov}}</a>
            </div>
            <div *ngIf="solo_lectura && msn_crt_nov==''">
              <p>Documento no disponible</p>
            </div>
            <div  [hidden]="solo_lectura" class="">
              <input
                type="file"
                name="file1"
                id="file1"
                [hidden]="solo_lectura"
                (change)="soporteNovedad($event)"
                accept=".doc,.docx,.pdf"
                *ngIf="!loader_crt_nov"
                class="inputfile"
              />
              <div *ngIf="loader_crt_nov ">
                <img src="../../assets/img/30.gif" />
              </div>
              <label
                for="file1"
                class="decorator"
                tooltip="Con fecha de expedición posterior al primero de enero de 2.013. Formato PDF"
                placement="right"
                #novedad
              >{{msn_crt_nov}}</label>
              <small id="passwordHelpInline" class="text-muted">
                Documento anexo que soporta la novedad (obligatorio).
              </small>
            </div>
            <input
              id="soporte_novedad"
              name="soporte_novedad"
              class="form-control"
              type="hidden"
              #soporte_novedad="ngModel"
              value="{{soporte_doc1}}"
              [(ngModel)]="novedades.soporte_novedad">
          </div>

          <div   class="form-group col-md-3">
            <label><strong>Soporte (2)</strong><br /></label><br />
            <div *ngIf="solo_lectura && msn_crt_nov2!=''">
                <a href="{{enlace_soporte2}}" target="blank">{{msn_crt_nov2}}</a>
          </div>
          <div *ngIf="solo_lectura && msn_crt_nov2==''">
            <p>Documento no disponible</p>
          </div>
            <div  [hidden]="solo_lectura" class="">
              <input
              [disabled]="solo_lectura"
                type="file"
                name="file2"
                id="file2"
                (change)="soporteNovedadAdicional($event)"
                accept=".doc,.docx,.pdf"
                *ngIf="!loader_crt_nov2"
                class="inputfile"
              />
              <div *ngIf="loader_crt_nov2">
                <img src="../../assets/img/30.gif" />
              </div>
              <label
                for="file2"
                class="decorator"
                tooltip="Con fecha de expedición posterior al primero de enero de 2.013. Formato PDF"
                placement="right"
                #novedad2
              >{{msn_crt_nov2}}</label>
              <small id="passwordHelpInline" class="text-muted">
                Documento anexo ADICIONAL que soporta la novedad (opcional).
              </small>
            </div>
            <input
              id="soporte_novedad_adicional"
              name="soporte_novedad_adicional"
              class="form-control"
              type="hidden"

              #soporte_novedad_adicional="ngModel"
              value="{{soporte_doc2}}"
              [(ngModel)]="novedades.soporte_novedad_adicional">

          </div>



            <div class="form-group col-md-3">
                <label class="bmd-label-floating" class="text-center"><strong>Observaciones</strong><br></label>
                <textarea id="observaciones"
                name="observaciones"
                class="form-control"
                type="text"
                maxlength="250"
                [disabled]="solo_lectura"
                #observaciones="ngModel"
                [(ngModel)]="novedades.observaciones"
                tooltip="Texto Incluido en el soporte de la incapacidad "></textarea>
            </div>
        </div>
        <hr>
        <div class="form-row">
          <div *ngIf="!success"  class="col-md-2">
            <button
              class="{{color_boton_enviar}} active text-white border rounded"  value="novedadesForm" type="submit"
              [disabled]="novedadesForm.valid"
              [disabled]="solo_lectura">{{submi}}
            </button>
          </div>
           <div *ngIf="!success && !ocultarBoton" class="col-md-2">
            <button
              [disabled]="solo_lectura"
              class="btn btn-primary active text-white border rounded"
              value="novedadesForm" (click)="guardarBorrador()"
              type="button">Guardar Borrador
            </button>
          </div>
        </div>



        <div
          class="alert alert-success"
          *ngIf="success"
          role="alert"
        >
          <h4 class="alert-heading">Registro Exitoso</h4>
          <p>
           La Novedad se ha registrado Correctamente.
          </p>
        </div>

        <!--*[disabled]="providerForm.invalid"*/-->
      </form>
    </div>
  </div>
</div>
