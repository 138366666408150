<div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">{{texto_spinner}}</p>
  </ngx-spinner>
</div>
<div class="container-fluid" style="margin: 85px;">
  <nav class="navbar navbar-dark navbar-expand-lg fixed-top bg-white border-warning shadow pulse animated"
    style="filter: blur(0px) brightness(92%) contrast(111%) grayscale(11%) hue-rotate(0deg) invert(0%) saturate(107%) sepia(0%);opacity: 0.99;">
    <div class="container-fluid"><a class="navbar-brand text-uppercase text-left text-light shadow-none visible"
        href="#" style="font-family: 'Roboto Condensed', sans-serif;">
        <ul [ngSwitch]='empresa'>
          <ul *ngSwitchCase="'brm'"><img src="../../assets/img/company_logo.jpg" width="212px" height="45px" alt=""
              class="d-inline-block align-top" loading="lazy"></ul>
          <ul *ngSwitchCase="'art'"><img src="../../assets/img/company_logo_complement.png" width="250px" height="50px"
              alt="" loading="lazy"></ul>
          <ul *ngSwitchCase="'heart'"><img src="../../assets/img/company_logo_heart.png" width="200px" height="73px"
              alt="" loading="lazy"></ul>
          <ul *ngSwitchCase="'dar'"><img src="../../assets/img/company_logo_dar.png" width="200px" height="75px" alt=""
              loading="lazy"></ul>
          <ul *ngSwitchCase="'preferente'"><img src="../../assets/img/company_logo_preferente.png" width="200px"
              height="50" alt="" loading="lazy"></ul>
          <ul *ngSwitchCase="'deeploy'"><img src="../../assets/img/company_logo_deeploy.png" width="237px" height="39"
              alt="" loading="lazy"></ul>
          <ul *ngSwitchCase="'brmmx'"><img src="../../assets/img/company_logo_brm-mx.png" width="194px" height="38"
              alt="" loading="lazy"></ul>

        </ul>
      </a><button data-toggle="collapse" class="navbar-toggler" data-target="#navcol-1"><span class="sr-only">Toggle
          navigation</span><span class="navbar-toggler-icon"></span></button>
      <div class="collapse navbar-collapse text-success bg-danger border-success d-table-cell" id="navcol-1">
        <ul class="nav navbar-nav">
          <li class="nav-item" role="presentation"></li>
          <li class="nav-item" role="presentation"></li>
          <li class="nav-item" role="presentation"></li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<br>
<div class="container-fluid">
  <div class="card text-light shadow-lg col-12">
    <div class="card-body text-left text-body border rounded border-light flex-row visible">
      <h3 class="text-uppercase text-left card-title">{{titulo_Formulario}}<br></h3>
      <h6 class="text-capitalize text-left text-muted card-subtitle mb-2">Actualización (1) Vigencia: 01/07/2019
        Elaborado: Gerente Procesos Revisado: Representante Sistema
        Aprobado: Presidente<br><br></h6>

      <form #clienteForm="ngForm" (ngSubmit)="onSubmit()" autocomplete="rutjfkde">
        <div class="form-row">
          <div class="form-group col-md-12">
            <h5>INFORMACIÓN GENERAL</h5>
            <hr>
          </div>
        </div>
        <div class="form-row">
          <div class="ng-autocomplete form-group col-md-3">
            <label class="text-center"><strong>Ciudad*</strong><br></label>
            <ng-autocomplete [data]="data$ | async" [searchKeyword]="keyword" [placeHolder]="valueCity"
              (selected)='selectEvent($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
              [disabled]="disabledcity" (inputFocused)='onFocusAutoc($event)' placeholder="Digita la Ciudad"
              tooltip='Ingrese la Ciudad donde se encuentra registrada la Compañia'>
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <input id="billing_country_id" name="billing_country_id" class="form-control" type="hidden"
                #billing_country_id="ngModel" value="{{pais}}" [(ngModel)]="clientes.billing_country_id">
              <input id="nombres_localizacion" name="nombres_localizacion" class="form-control" type="hidden"
                #nombres_localizacion="ngModel" value="{{concatedNames}}" [(ngModel)]="clientes.nombres_localizacion">
              <input id="billing_city_id" name="billing_city_id" class="form-control" type="hidden"
                #billing_city_id="ngModel" value="{{ciudad}}" [(ngModel)]="clientes.billing_city_id">
            </div>
          </div>


          <!-- <div class="form-group col-md-3" >
                    <label><strong>Registro / Actualización*</strong><br></label>
                    <select id="registro_actualizacion"
                        name="registro_actualizacion"
                        class="bmd-label-floating"
                        class="form-control"
                        #registro_actualizacion="ngModel"
                        [(ngModel)]="clientes.registro_actualizacion"  tooltip="Por favor indique si es registro (nuevo) o actualización de información" required>
                        <option value="registro" selected="">Registro</option>
                        <option value="actualizacion">Actualización</option>
                    </select>
                    <span *ngIf="registro_actualizacion.invalid && registro_actualizacion.touched">Falta el campo requerido:Registro / Actualización. </span>
                  </div>-->
          <div class="form-group col-md-2" [hidden]="procedimiento == 'creacion_rapida'">
            <label class="bmd-label-floating" class="text-center"><strong>Código de Verificacion*</strong><br></label>
            <input id="codigo_verificacion" name="codigo_verificacion" class="form-control" type="text"
              (change)="validarCodigo(1)" #codigo_verificacion="ngModel" [(ngModel)]="clientes.codigo_verificacion"
              maxlength="250"
              tooltip='Este código confirmará que el representante legal ha registrado los datos y realizado las declaraciones, o ha delegado a un funcionario que lo ejecutó en su nombre y responsabilidad.'
              required='{{codigo_verificacion_req}}'>
            <span *ngIf="codigo_verificacion.invalid && codigo_verificacion.touched">El Campo: Código es
              requerido</span>
          </div>


          <div class="form-group col-md-3">
            <label class="text-center"><strong>{{label_razon_social}}</strong><br></label>
            <input id="firm_name" name="firm_name" class="form-control" type="text" (change)="validarCodigo(0)"
              #name="ngModel" [(ngModel)]="clientes.name" maxlength="250"
              tooltip='{{tooltip_firm_name}}'
              required='{{firm_name_req}}'>
            <span *ngIf="name.invalid && name.touched">Falta el campo requerido:Razón Social/Nombre Persona
              Natural.</span>
          </div>
          <div class="form-group col-md-2">
            <label class="text-center"><strong>Teléfono (1)*</strong><br></label><br>
            <input id="phone_office" name="phone_office" class="form-control alter-padding" type="tel" ng2TelInput
              (countryChange)="onCountryChange_1($event)" #phone_office="ngModel" [(ngModel)]="clientes.phone_office"
              pattern="[A-Za-z,.'-, ' ',0-9,-]{4,20}" tooltip='Hace referencia al contacto comercial'
              required='{{phone_office_req}}'><br>
            <span *ngIf="!phone_office.valid && phone_office.touched">Formato de telefono Invalido.</span>
          </div>
          <div class="form-group col-md-2">
            <label class="text-center"><strong>Teléfono (2)*</strong><br></label><br>
            <input id="phone_alternate" name="phone_alternate" class="form-control alter-padding" type="tel" ng2TelInput
              (countryChange)="onCountryChange_2($event)" #phone_alternate="ngModel"
              [(ngModel)]="clientes.phone_alternate" pattern="[A-Za-z,.'-, ' ',0-9,-]{4,20}"
              tooltip='Teléfono adicional en caso de no localizar al comercial' required='{{phone_alternate_req}}'><br>
            <span *ngIf="phone_alternate.invalid && phone_alternate.touched">El numero de Teléfono es requerido.</span>
          </div>

        </div>
        <div class="form-row">

          <div class="form-group col-md-3">
            <label class="text-center"><strong>{{label_direccion}}</strong><br></label>
            <input id="billing_address_street" name="billing_address_street" class="form-control" type="text"
              #billing_address_street="ngModel" [(ngModel)]="clientes.billing_address_street"
              tooltip='La registrada en la Cámara de Comercio (Persona Jurídica) o de correspondencia (Persona Natural)'
              required='{{billing_address_street_req}}'>
            <span *ngIf="!billing_address_street.valid && billing_address_street.touched">El campo:Dirección es
              requerido.</span>
          </div>
          <div class="form-group col-md-2" *ngIf="codigo_pais!='CO'">
            <label class="bmd-label-floating" class="text-center"><strong>Contacto (Comercial)*</strong><br></label>
            <input id="contacto_pagaduria" name="contacto_pagaduria" class="form-control" type="text"
              #contacto_pagaduria="ngModel" [(ngModel)]="clientes.contacto_pagaduria"
              pattern="[A-Za-z,.'-, ' ',0-9,-]{4,64}" tooltip='Con que funcionario debemos contactarnos en su compañía'
              required='{{contacto_pagaduria_req}}'>
            <span *ngIf="!contacto_pagaduria.valid && contacto_pagaduria.touched">Falta el campo requerido:Contacto
              pagaduría-tesorería </span>
          </div>


          <div class="form-group col-md-3" *ngIf="codigo_pais!='CO' ">
            <label class="bmd-label-floating" class="text-center"><strong>Correo electrónico (Contacto
                Comercial)*</strong><br></label>
            <input id="email_contacto_pagaduria" name="email_contacto_pagaduria" #email_contacto_pagaduria="ngModel"
              [(ngModel)]="clientes.email_contacto_pagaduria" class="form-control" type="email"
              pattern="^[_A-Za-z0-9-]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9-]+)*(.[A-Za-z]{2,4})$"
              tooltip='Se refiere al Email del Contacto Comercial' required='{{email_contacto_pagaduria_req}}'>
            <span *ngIf="email_contacto_pagaduria.invalid && email_contacto_pagaduria.touched">El Campo Email es
              requerido.</span>
          </div>

          <div class="form-group col-md-2" *ngIf="empresa =='brmmx' && codigo_pais=='MX'">
            <label class="bmd-label-floating" class="text-center" ><strong>RFC</strong><br></label>
            <input #tributary_number
              id="tributary_number_c" input-lg
              name="tributary_number_c"
              class="form-control"
              type="text"
              tooltip='Registro Federal de Contribuyentes'
              (change)="camposRequeridos();"
              #tributary_number_c="ngModel"
              [(ngModel)]="clientes.tributary_number_c" minlength='4' maxlength='13' required="{{tributary_id_c_req}}">
              <span *ngIf="!tributary_number_c.valid && tributary_number_c.touched">El Campo: RFC es requerido.</span>

      </div>

          <span *ngIf="!billing_city_id.valid && billing_city_id.touched">El campo:Ingrese Ciudad es requerido.</span>
        </div>
        <br>



        <div #informacion_inicial style="display: none;">
          <div class="form-row">
            <div class="form-group col-md-12">
              <h5>INFORMACIÓN INICIAL</h5>
              <hr>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-4">
              <label class="bmd-label-floating" class="text-center" class="bmd-label-floating"><strong>Identicación
                  Tributaria (Tipo)*</strong><br></label>
              <select id="tributary_id_c" name="tributary_id_c" class="form-control" #tributary_id_c="ngModel"
                [(ngModel)]="clientes.tributary_id_c" required='{{tributary_id_c_req}}'>
                <option>- Ninguno -</option>
                <option value="{{tipo_identificacion.id_2}}" *ngFor="let tipo_identificacion of tipo_identificacion_persona">{{tipo_identificacion.nombre}}</option>
              </select>
            </div>
            <div class="form-group col-md-4" *ngIf="codigo_pais!='MX'">
              <label class="bmd-label-floating" class="text-center"><strong>Número Identificación Tributaria /
                  Fiscal*</strong><br></label>
              <input #tributary_number id="tributary_number_c" name="tributary_number_c" class="form-control"
                type="text" (change)="validarNumero();validarCliente(1);" #tributary_number_c="ngModel"
                [(ngModel)]="clientes.tributary_number_c" pattern="[0-9]{5,15}"
                tooltip='Número único colombiano que asigna la DIAN (Dirección de Impuestos y Aduanas Nacionales de Colombia) por una sola vez cuando el obligado se inscribe en el RUT (Registro Único Tributario).'
                required={{tributary_id_c_req}}>
              <span *ngIf="tributary_number_c.invalid && tributary_number_c.touched">El Campo: Número Identificación
                Tributaria es requerido.</span>
            </div>
            <div class="form-group col-md-3" [hidden]="clientes.tributary_id_c !='nit'">
              <label class="bmd-label-floating" class="text-center"><strong>Dígito de Verificación*</strong><br></label>
              <select class="form-control" id="digito_verificacion" name="digito_verificacion"
                (change)="validarCliente(0)" #dig_verifica [(ngModel)]="clientes.digito_verificacion"
                tooltip='Si es Cliente extranjero por favor seleccione:0' required="{{digito_verificacion_req}}">
                <option value="{{digito.value}}" *ngFor="let digito of digito_verificacion">{{digito.nombre}}</option>
                <span *ngIf="!digito_verificacion.valid && digito_verificacion.touched">El Campo: Digito de Verificación
                  es requerido.</span>
              </select>
            </div>
        </div>
        <br>



        </div>

        <div class="form-group col-md-6" *ngIf="codigo_pais!='CO' && mostrar_internacionales||codigo_pais!='CO' && procedimiento == 'creacion_rapida'">
        <label><strong>{{label_doc_rut_internacional}}</strong><br></label><br>
        <div class="">
          <input type="file" name="file1" id="file1" (change)="documentRUT($event)" accept=".doc,.docx,.pdf"
            *ngIf="!loader_crt_rut" class="inputfile" />
          <div *ngIf="loader_crt_rut">
            <img src="../../assets/img/30.gif" />
          </div>
          <label for="file1" class="decorator" tooltip='Documento que certifica la razón social de la compañía.'
            placement='right' #rut>{{msn_crt_rut}}</label>
        </div>
        <br>
        <br>
        <input id="rut_doc" name="rut_doc" class="form-control" type="hidden" #rut_doc="ngModel" value="{{doc_rut}}"
          [(ngModel)]="clientes.rut_doc">
      </div>


      <div class="form-group col-md-12" *ngIf="codigo_pais=='CO' && procedimiento == 'creacion_rapida'">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label><strong>COPIA DEL RUT / CERTIFICADO DE INCORPORACIÓN*</strong><br></label><br>
            <div class="">
              <input type="file" name="file1" id="file1" (change)="documentRUT($event)" accept=".doc,.docx,.pdf"
                *ngIf="!loader_crt_rut" class="inputfile" />
              <div *ngIf="loader_crt_rut">
                <img src="../../assets/img/30.gif" />
              </div>
              <label for="file1" class="decorator"
                tooltip='Con fecha de expedición posterior al primero de enero de 2.013. Formato PDF'
                placement='right' #rut>{{msn_crt_rut}}</label>
            </div>
            <br>
            <br>
            <input id="rut_doc" name="rut_doc" class="form-control" type="hidden" #rut_doc="ngModel"
              value="{{doc_rut}}" [(ngModel)]="clientes.rut_doc">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label><strong>CAMARA DE COMERCIO*</strong><br></label><br>
            <div class="">
              <input type="file" name="file2" id="file2" (change)="documentCamaraComercio($event)"
                accept=".doc,.docx,.pdf" *ngIf="!loader_crt_camara" class="inputfile" />
              <div *ngIf="loader_crt_camara">
                <img src="../../assets/img/30.gif" />
              </div>
              <label for="file2" class="decorator" tooltip='Con fecha de expedición No mayor a 30 dias. Formato PDF'
                placement='right' #camara>{{msn_crt_camara}}</label>
            </div>
            <br>
            <br>
            <input id="camara_comercio_doc" name="camara_comercio_doc" class="form-control" type="hidden"
              #camara_comercio_doc="ngModel" value="{{doc_camara_comercio}}"
              [(ngModel)]="clientes.camara_comercio_doc">
          </div>
        </div>
      </div>

      <div class="container-fluid" *ngIf="codigo_pais!='CO'&& mostrar_internacionales && procedimiento!='creacion_rapida'">




        <div [formGroup]="registerPartner">
          <div *ngIf="mostrar_labels_socios">
          <h4 class="col-md-12 text-center">Socio / Beneficiario Final</h4>
          <hr>
          <div class="form-row">
            <p class="text-justify">Por favor registrar la información de los BENEFICIARIOS FINALES de la entidad con participación igual o mayor al 5% (Si no los registra en este espacio, debe adjuntar una certificación con la lista que contenga nombre, tipo y número de identificación y participación (%) participación, suscrita por el Representante Legal)
            </p>
            <p   *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="text-justify"><strong class="circulo">•</strong>Personas Expuestas Políticamente
              De conformidad con las leyes Colombianas (Persona que ocupa uno de los cargos públicos establecidos en  Decreto 1674 de 2016).En caso de aplicar por favor marcar la casilla correspondiente.
            </p>
          </div>
          <hr style="background-color: #dcdcdd">
          <div class="form-row">
          <div class="form-group col" *ngFor="let nombre of nombresLabels">
            <label class="bmd-label-floating text-align: center;"><strong>{{nombre.nombres}}</strong><br></label>
          </div>
          <div  *ngIf="codigo_pais!='MX' && empresa!='brmmx'"  class="form-group col" >
            <label class="bmd-label-floating  text-align: center;"><strong class="circulo">•</strong><strong>PEP</strong><br></label>
        </div>
          </div>
          </div>
          <div formArrayName="socios" *ngFor="let socio of socios.controls.slice(0, 5); let i = index">
            <div class="form-row" >
              <div class="form-group col" *ngIf="i>0">
                <button type="button" class="close pull-right" aria-label="Close" (click)="removerSocio(i)">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div class="form-row" [formGroupName]="i">
            <div class="form-group col">
              <select class="form-control" formControlName="tipo_identificacion"  (change)="socioTipoId($event,i)">
                <option value="{{tipo_identificacion.id}}"
                  *ngFor="let tipo_identificacion of tipo_identificacion_persona">{{tipo_identificacion.nombre}}
                </option>
              </select>
            </div>
            <div class="form-group col">
              <input class="form-control" type="text" formControlName="numero_identificacion" tooltip='Ingrese Numero de Identificacion sin caracteres especiales.' (keypress)="validarNumeroIdentificacion($event)">
            </div>
              <div class="form-group col">
                <input class="form-control" type="text" formControlName="primer_nombre">
              </div>
              <div class="form-group col">
                <input class="form-control" type="text" formControlName="segundo_nombre" >
              </div>
              <div class="form-group col">
                <input class="form-control" type="text" formControlName="primer_apellido" >
              </div>
              <div class="form-group col">
                <input class="form-control" type="text" formControlName="segundo_apellido" >
              </div>
              <div *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="form-group col" >
                <div class="form-check  text-align: center;">
                  <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  formControlName="pep"
                  >
                </div>
              </div>
            </div>
            <hr style="background-color: #dcdcdd">
          </div>
          <div class="form-row" *ngIf="boton_agregar_socio">
            <div class="adicionar-socio" class="col-md-3">
                <button class="btn btn-danger active text-white border rounded" value="agregaSocio" (click)="agregarSocio(1)" type="button">Agregar
                  </button>
            </div>
            <!--<div class="guardar-socio" class="col-md-3">
                <button class="btn btn-success active text-white border rounded" value="guardaSocio" (click)="guardarSocio()" type="button">Guardar Socio
                  </button>
            </div>-->
          </div>
        </div>
        <hr>
      </div>


      <div class="form-row">
        <div class="container-fluid" *ngIf="mostrar_parrafo && codigo_pais!='CO'&& mostrar_internacionales">

          <p class="text-center"> <strong>DECLARACIÓN</strong></p>
          <p class="text-justify"> En calidad de Representante Legal y/o apoderado y/o autorizado de la Sociedad
            arriba suscrita,
             declaro y manifiesto expresamente que toda la información consignada en el
            presente
            registro es veraz, cierta y autentica. Igualmente, declaro:<br></p>
          <p class="text-justify"> <strong>• RESPECTO A POTENCIALES CONFLICTOS DE INTERÉS.
            </strong></p>
          <p class="text-justify">Declaro que ningún empleado, directivo, miembro de junta directiva o socio de la
            sociedad a
            quien represento, ni sostienen vínculo alguno de cónyuge o compañero permanente, familiar hasta el cuarto
            grado de
            consanguinidad, segundo de afinidad y primero civil con el personal administrativo y directivo de
            {{texto_empresa}}.; ni
            ninguno de ellos dará, ofrecerá o prometerá, directa o indirectamente, ninguna dádiva, dinero, beneficio o
            ventaja,
            a los empleados, asesores o directivos de {{texto_empresa}}. De igual forma, en caso de configurarse una
            situación de
            conflicto de intereses, conforme con las declaraciones aquí expuestas, {{texto_empresa}}. se encuentra en
            la facultad de dar
            por terminada la relación comercial y/o contrato, sin que se cause derecho de indemnización alguna en
            favor del
            cliente y/o proveedor, y en todo caso, en el evento de presentarse alguna de las situaciones mencionadas
            anteriormente, me comprometo a informar por escrito de manera detallada el conflicto de interés, mediante
            carta
            dirigida al representante legal de {{texto_empresa}}.
            <br>
          </p>
          <p class="text-justify"> <strong>• RESPECTO AL ORIGEN DE FONDOS.

            </strong></p>
          <p class="text-justify">Declaro igualmente, bajo la gravedad del juramento que los ingresos de la sociedad
            provienen
            de actividades lícitas, que ni la sociedad, ni los socios o directivos se encuentran con registro negativo
            en
            listados de prevención de lavado de activos nacionales o internacionales, ni tampoco se encuentran dentro
            de una de
            las dos categorías de lavado de activos (conversión o movimiento) y que en consecuencia, mantendrán
            indemne a {{texto_empresa}}.
            frente a cualquier controversia, conflicto, perjuicio o requerimiento que ocurriese por origen de los
            fondos de
            los mencionados. Será causa de terminación anticipada de cualquier contrato, convenio, acuerdo que exista
            con {{texto_empresa}}.
            , sin el reconocimiento o pago de penas a favor de la contraparte, el engaño, la información falsa o
            presentación
            de documentos falsificados que no permitan conocer la vinculación de la sociedad con actividades ilícitas
            y/o
            financiación terrorismo. De igual forma, aplicara lo mismo, si la sociedad, sus filiales, subordinadas,
            socios o sus
            representantes legales fueren investigados o condenados mediante sentencia por un juez por cualquier
            delito
            vinculado con la producción, tenencia, tráfico, proselitismo y otros actos en materia de narcóticos,
            encubrimiento
            y/u operaciones con recursos de procedencia ilícita, terrorismo y/o delincuencia organizada en el
            territorio
            nacional o en cualquier país del mundo, con el que Colombia tenga firmado tratados internacionales
            referentes a lo
            establecido en el presente párrafo o bien es mencionado en las Listas OFAC (Office Foreign Assets Control)
            o
            cualquier otra lista de naturaleza similar. Igualmente, en calidad de Representante Legal de la Sociedad
            arriba
            descrita, autorizo de manera expresa a {{texto_empresa}}.. a consignar los recursos por los servicios que
            prestamos, conforme a
            la información suministrada.

            <br>
          </p>
          <p class="text-justify"> <strong>• RESPECTO AL TRATAMIENTO DE DATOS PERSONALES POR PARTE DE
              {{texto_empresa}}.

            </strong></p>
          <p class="text-justify">Declaro y manifiesto que: De conformidad y en cumplimiento de la Ley 1581 de 2012 y
            el Decreto
            1377 del 2013; he sido informado sobre las medidas de seguridad implementadas para garantizar la
            conservación y
            confidencialidad sobre los datos personales de la Sociedad, de mi como Representante Legal y como persona
            natural y
            sobre los derechos que como titular de los mismos puedo ejercer: Los derechos de acceso, rectificación,
            prueba de
            autorización, reserva de responder preguntas sobre datos sensibles o información de menor de edad,
            oposición y
            supresión (este último cuando no medie un deber legal o contractual que lo impida), en la forma
            establecida en la
            Política de Tratamiento de Datos Personales de {{texto_empresa}}., disponible a solicitud del interesado
            en la página web de
            {{texto_empresa}}. De esta manera, autorizo de forma previa, consciente, expresa e informada a la
            {{texto_empresa}}. en calidad de
            Responsable y/o Encargado del tratamiento la información mencionada, en virtud de la relación comercial
            existente,
            para que utilice los datos personales recopilados en una base de datos de su titularidad, con las
            siguientes
            finalidades: 1. Gestión contable, fiscal y administrativa; 2. Registro como cliente y/o proveedor, así
            como su
            evaluación; 3. Actualización de datos en los sistemas de información de la Compañía Contratante; 4.
            Análisis del
            riesgo financiero y operativo; 5. Dar cumplimiento y seguimiento a las obligaciones contraídas por el
            proveedor y/o
            el cliente; 6. Consultar en cualquier momento en cualquier entidad financiera, los datos y toda la
            información
            relevante para la presente vinculación, conocer su desempeño como deudor y su capacidad de pago, valorar
            el riesgo
            futuro y verificar el cumplimiento de sus deberes; 7. Reportar los datos de la Sociedad en las entidades
            financieras
            y de riesgos, directamente o por intermedio de las autoridades de vigilancia y control, datos tratados o
            sin tratar,
            referidos a la información mencionada anteriormente; 8. Gestionar el cobro de las obligaciones
            comerciales,
            económicas, financieras adquiridas por parte de la Sociedad: 9. Todas las demás finalidades que
            correspondan para el
            desarrollo de la relación comercial y aquellas indicadas en la Política de Tratamiento de Datos Personales
            disponible en el siguiente link:<a href="{{enlace}}" target="blank"> Manual tratamiento de datos.</a>

            <br>
          </p>

        </div><br>
        <!-- <div class="form-row">

                     <div class="form-group col-md-12"><label class="bmd-label-floating" class="text-center"><strong>Conflicto de Interés*</strong><br></label>
                          <textarea id="conflicto_interes"
                              name="conflicto_interes"
                              class="form-control form-control-lg col-md-12"
                              #conflicto_interes="ngModel"
                              [(ngModel)]="clientes.conflicto_interes" tooltip='Aquí podrá indicar que conflicto ha identificado'></textarea>
                      </div>

                  </div>-->

      <div class="form-row" *ngIf=" codigo_pais!='CO'&& mostrar_internacionales || procedimiento==='creacion_rapida'">


      <div class="form-group col-md-12" *ngIf=" procedimiento==='creacion_rapida'">
        <h5>INFORMACIÓN REPRESENTANTE LEGAL</h5>
        <hr>
        <br>
      </div>

      <div class='container-fluid'>
      <hr>
      <div class="form-row ">
      <div class="form-group col-md-2"[hidden]="deshabilitar_delegado" >
        <label class="text-center"><strong>CALIDAD DE QUIEN REALIZA EL REGISTRO*</strong><br></label>
        <select id="quien_realiza_registro" name="quien_realiza_registro" class="form-control"
          #quien_realiza_registro="ngModel" [(ngModel)]="clientes.quien_realiza_registro"
          required="{{quien_realiza_registro_req}}" >
          <option value="Representante legal">Representante Legal</option>
          <option value="Delegado representante legal">Delegado Representante Legal</option>
          <!--<option value="{{autoretenedor.value}}" *ngFor="let autoretenedor of quien_realiza_registro">{{autoretenedor.nombre}}</option>-->
        </select>
        <!-- <span *ngIf="quien_realiza_registro.value==='Delegado Representante Legal'">El Campo: AUTORETENEDOR - RENTA es requerido</span>-->
      </div>

      <div class="form-group col-md-2" *ngIf="clientes.quien_realiza_registro ==='Delegado representante legal'">
        <label class="bmd-label-floating" class="text-center"><strong>Correo Electronico Representante
            Legal*</strong><br></label>
        <input id="email_representante_legal" name="email_representante_legal" class="form-control" type="text"
          #email_representante_legal="ngModel" [(ngModel)]="clientes.email_representante_legal"
          pattern="^[_A-Za-z0-9-]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9-]+)*(.[A-Za-z]{2,4})$"
          tooltip='Se refiere al Email del Representante Legal' required>
        <span *ngIf="!email_representante_legal.valid && email_representante_legal.touched">El Campo: Correo
          Electronico Representante Legal es requerido</span>
      </div>

      </div>
      <hr>

      <div class="form-row">
        <h6 style="color:rgb(148, 17, 17)">DATOS DE LA PERSONA NATURAL O REPRESENTANTE LEGAL DE LA SOCIEDAD</h6>
        <p  *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="text-justify"><strong class="circulo">•</strong>Personas Expuestas Políticamente
          De conformidad con las leyes Colombianas (Persona que ocupa uno de los cargos públicos establecidos en  Decreto 1674 de 2016).En caso de aplicar por favor marcar la casilla correspondiente.
        </p>
    </div>
    <br>
 <div class="form-row">
      <div class="form-group col-md-2">
        <label class="bmd-label-floating" class="text-center"><strong>PRIMER NOMBRE*</strong><br></label>
        <input id="primer_nombre_rl" name="primer_nombre_rl" class="form-control" type="text"
          #primer_nombre_rl="ngModel" [(ngModel)]="clientes.primer_nombre_rl" tooltip='Primer Nombre'
          required="{{primer_nombre_rl_req}}">
        <span *ngIf="!primer_nombre_rl.valid && primer_nombre_rl.touched">El Campo: PRIMER NOMBRE es
          requerido</span>
      </div>

      <div class="form-group col-md-2">
        <label class="bmd-label-floating" class="text-center"><strong>SEGUNDO NOMBRE</strong><br></label>
        <input id="segundo_nombre_rl" name="segundo_nombre_rl" class="form-control" type="text"
          #segundo_nombre_rl="ngModel" [(ngModel)]="clientes.segundo_nombre_rl" tooltip='Segundo Nombre'
          required="{{segundo_nombre_rl_req}}">
        <span *ngIf="!segundo_nombre_rl.valid && segundo_nombre_rl.touched">El Campo: SEGUNDO NOMBRE es
          requerido</span>
      </div>
      <div class="form-group col-md-2">
        <label class="bmd-label-floating" class="text-center"><strong>PRIMER APELLIDO*</strong><br></label>
        <input id="primer_apellido_rl" name="primer_apellido_rl" class="form-control" type="text"
          #primer_apellido_rl="ngModel" [(ngModel)]="clientes.primer_apellido_rl" tooltip='Primer Apellido'
          required="{{primer_apellido_rl_req}}">
        <span *ngIf="!primer_nombre_rl.valid && primer_nombre_rl.touched">El Campo: PRIMER APELLIDO es
          requerido</span>
      </div>
      <div class="form-group col-md-2">
        <label class="bmd-label-floating" class="text-center"><strong>SEGUNDO APELLIDO*</strong><br></label>
        <input id="segundo_apellido_rl" name="segundo_apellido_rl" class="form-control" type="text"
          #segundo_apellido_rl="ngModel" [(ngModel)]="clientes.segundo_apellido_rl" tooltip='Segundo Apellido'
          required="{{segundo_apellido_rl_req}}">
        <span *ngIf="!segundo_apellido_rl.valid && segundo_apellido_rl.touched">El Campo: SEGUNDO APELLIDO es
          requerido</span>
      </div>
      <div class="form-group col-md-2"><label class="bmd-label-floating" class="text-center"><strong>Tipo de
            Identificación*</strong></label>
        <select id="tipo_identificacion" name="tipo_identificacion" class="form-control"
          #tipo_identificacion="ngModel" [(ngModel)]="clientes.tipo_identificacion"
          required="{{tipo_identificacion_req}}">
          <option value="{{tipo_identificacion.id}}"
            *ngFor="let tipo_identificacion of tipo_identificacion_persona">{{tipo_identificacion.nombre}}
          </option>
        </select>
        <span *ngIf="tipo_identificacion.invalid && tipo_identificacion.touched">El Campo: Tipo de Identificación
          es requerido</span>
      </div>


      <!--tipo_identificación_rl-->
      <div class="form-group col-md-2">
        <label class="bmd-label-floating" class="text-center"><strong>Numero Identificación*
            (Personal)*</strong><br></label>
        <input id="numero_identificacion" name="numero_identificacion" class="form-control" type="text"
          #numero_identificacion="ngModel" [(ngModel)]="clientes.numero_identificacion"
          required="{{numero_identificacion_req}}" [pattern]="pattern_numero_identificacion"
          tooltip='Corresponde al numero de identificacion.' (keypress)="validarNumeroIdentificacion($event)">
        <span *ngIf="numero_identificacion.invalid && numero_identificacion.touched">El Campo: Numero
          Identificación (Personal) es requerido</span>
      </div>

      <div  *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="form-group col-md-2 text-center" >
        <label class="bmd-label-floating" ><strong class="circulo">•</strong><strong>PEP
         </strong><br></label>
         <br>
         <br>
        <div class="form-check text-center">
          <input
          id="pep_rl"
          name="pep_rl"
          class="form-control"
          class="form-check-input"
          type="checkbox"
          #pep_rl="ngModel"
          [(ngModel)]="clientes.pep_rl"
          value=""
          tooltip='Por favor indique si es una Persona Políticamente expuesta (PEP)'
          id="defaultCheck1">
        </div>
        </div>

      <div class="form-group col-md-6">
        <label><strong>{{label_doc_rl}}</strong><br></label><br>
        <div class="">
          <input type="file" name="file3" id="file3" (change)="documentRepresentanteLegal($event)"
            accept=".doc,.docx,.pdf" *ngIf="!loader_crt_rl" class="inputfile" />
          <div *ngIf="loader_crt_rl">
            <img src="../../assets/img/30.gif"/>
          </div>
          <label for="file3" class="decorator" tooltip='Documento de identificacion Representante Legal. Formato PDF'
            placement='right' #representante>{{msn_crt_rl}}</label>
        </div>
        <br>
        <br>
        <input id="representante_legal_doc" name="representante_legal_doc" class="form-control" type="hidden"
          #representante_legal_doc="ngModel" value="{{doc_representante_legal}}"
          [(ngModel)]="clientes.representante_legal_doc">
      </div>


 </div>
                  <div class="form-row">
                        <div class="col-md-3" style="display: flex;align-items: center;">
                          <p>Desempeña y/o ha desempeñado un cargo publico <i>(Have you ever held a public position)</i></p>
                        </div>
                        <div class="col-md-2 text-center">
                          <table width="100%" cellpadding="0" cellspacing="0" border="0">
                            <tr>
                              <td><p style="font-size: 1rem;padding-top: 11px;">Si (Yes)</p></td>
                              <td>
                                <input
                                    id="cargo_publico"
                                    name="cargo_publico"
                                    type="checkbox"
                                    [(ngModel)]="cargo_publico_check_si"
                                    (change)="onChangeCargoPublico($event.target.value, $event.target.checked)"
                                    value="si">
                              </td>
                            </tr>
                            <tr>
                              <td><p style="font-size: 1rem;padding-top: 11px;">No</p></td>
                              <td>
                                <input
                                    id="cargo_publico_no"
                                    name="cargo_publico_no"
                                    type="checkbox"
                                    [(ngModel)]="cargo_publico_check_no"
                                    (change)="onChangeCargoPublico($event.target.value, $event.target.checked)"
                                    value="no">
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div class="col-md-6">
                          <p style="padding-left: 11px;">Si la respuesta es <strong>SI</strong>, indicar cargo y la fecha desde la cual lo desempeña o la fecha desde la cual dejó de desempeñarlo <i>(if you answered YES, indicate the time periodo in which you held the position)</i></p>
                          <div class="col-md-12" style="display: flex;" *ngIf="clientes.ejerce_cargo_publico_rl == 'si'">
                            <div class="col-md-6">
                                <label class="text-center">Cargo</label>
                                <input id="cargo_publico_rl"
                                    name="cargo_publico_rl"
                                    class="form-control"
                                    type="text"
                                    maxlength='50'
                                    #cargo_publico_rl="ngModel"
                                    [(ngModel)]="clientes.cargo_publico_rl">
                            </div>
                            <div class="col-md-6">
                                <label class="text-center">Fecha Desempeño</label>
                                <input id="fecha_cargo_publico_rl"
                                    name="fecha_cargo_publico_rl"
                                    class="form-control"
                                    type="date"
                                    #fecha_cargo_publico_rl="ngModel"
                                    [(ngModel)]="clientes.fecha_cargo_publico_rl">
                            </div>
                          </div>
                        </div>
                  </div>
                  <div class="form-row" style="padding-top: 20px;">
                        <div class="col-md-3" style="display: flex;align-items: center;">
                          <p>Maneja y/o ha manejado recursos publicos <i>(Have you ever managed or do you currently manage public founds?)</i></p>
                        </div>
                        <div class="col-md-2 text-center">
                          <table width="100%" cellpadding="0" cellspacing="0" border="0">
                            <tr>
                              <td><p style="font-size: 1rem;padding-top: 11px;">Si (Yes)</p></td>
                              <td>
                                <input
                                    id="maneja_recurso_publico_si"
                                    name="maneja_recurso_publico_si"
                                    type="checkbox"
                                    [(ngModel)]="maneja_recurso_publico_check_si"
                                    (change)="onChangeManejaRecursoPublico($event.target.value, $event.target.checked)"
                                    value="si">
                              </td>
                            </tr>
                            <tr>
                              <td><p style="font-size: 1rem;padding-top: 10px;">No</p></td>
                              <td>
                                <input
                                    id="maneja_recurso_publico_no"
                                    name="maneja_recurso_publico_no"
                                    type="checkbox"
                                    [(ngModel)]="maneja_recurso_publico_check_no"
                                    (change)="onChangeManejaRecursoPublico($event.target.value, $event.target.checked)"
                                    value="no">
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div class="col-md-6">
                          <p style="padding-left: 15px;">Si la respuesta es <strong>SI</strong>, indicar cargo desde el cual maneja los recursos publicos y la fecha desde la cual lo desempeña o la fecha desde la cual dejo de desempeñar el cargo y manejar los recursos publicos <i>(If the you answered YES, indicate from which position you manage(d) the public funds).</i></p>
                          <div class="col-md-12" style="display: flex;" *ngIf="clientes.maneja_recurso_publico_rl == 'si'">
                            <div class="col-md-6">
                                <label class="text-center">Cargo</label>
                                <input id="cargo_recurso_publico_rl"
                                    name="cargo_recurso_publico_rl"
                                    class="form-control"
                                    type="text"
                                    maxlength='50'
                                    #cargo_recurso_publico_rl="ngModel"
                                    [(ngModel)]="clientes.cargo_recurso_publico_rl">
                            </div>
                            <div class="col-md-6">
                                <label class="text-center">Fecha Desempeño</label>
                                <input id="fecha_recurso_publico_rl"
                                    name="fecha_recurso_publico_rl"
                                    class="form-control"
                                    type="date"
                                    #fecha_recurso_publico_rl="ngModel"
                                    [(ngModel)]="clientes.fecha_recurso_publico_rl">
                            </div>
                          </div>
                        </div>
                  </div>
                </div>
      </div>
  </div>

  <br>

    <div class="alert alert-success" role="alert" *ngIf=" success && procedimiento =='creacion_rapida' && codigo_pais =='CO'">
      <h4 class="alert-heading">Registro Exitoso</h4>
      <p>Su informacion ya fue almacenada, si necesitamos mas informacion adicional nos estaremos comunicando
        posteriormente</p>
    </div>


    <div class="form-row" *ngIf="procedimiento=='creacion_rapida' || codigo_pais!='CO'">
      <div *ngIf='mostrarcampos==true'>
        <h6>Faltan los campos requeridos:</h6>
        <ul *ngFor="let item of prueba">
          <li class="validacion">{{item}}</li>
        </ul>
      </div>
    </div>


        <!--<div class="form-row" *ngIf="codigo_pais!='CO'">
          <div *ngIf='mostrarcampos==true'>
            <h6>Faltan los campos requeridos:</h6>
            <ul *ngFor="let item of prueba">
              <li class="validacion">{{item}}</li>
            </ul>
          </div>
        </div>-->


        <div class="form-row">
          <div class="form-group col-md-2" *ngIf="procedimiento == 'creacion_rapida' && codigo_pais =='CO'">
            <div *ngIf="!success" class="col-md-2">
              <button class="btn btn-danger active text-white border rounded" value="clienteForm" type="button"
                (click)="camposRequeridos(1,1)">Envíar</button>
            </div>
          </div>
          <div class="form-group col-md-2" *ngIf="botones_finales">
            <div *ngIf="!success" class="col-md-2">
              <button class="btn btn-danger active text-white border rounded" value="clienteForm" type="button"
                (click)="camposRequeridos(1,1)">Envíar</button>
            </div>
          </div>
          <div class="form-group col-md-4" *ngIf="botones_finales">
            <div *ngIf="!success" class="col-md-3">
              <button class="btn btn-danger active text-white border rounded" value="clienteForm"
                (click)="guardarBorrador()" type="button">Guardar Borrador
              </button>
            </div>
          </div>
        </div>
        <div class="alert alert-success" role="alert"
          *ngIf=" success &&  codigo_pais!='CO' ||success && procedimiento !='creacion_rapida' &&  codigo_pais!='CO'">
          <h4 class="alert-heading">Registro Exitoso</h4>
          <p>Su informacion ya fue almacenada, si necesitamos mas informacion adicional nos estaremos comunicando
            posteriormente</p>
        </div>
        <br>
        <br>
        <br>
        <div class="seccion_2" #div_clientes style="display: none;">

          <!--<div class="form-group  col-md-6">
                      <label><strong>Compañía Contratante*</strong><br></label>
                      <select  id="compania_contratante"
                          name="compania_contratante"
                          class="form-control"
                          #compania_contratante="ngModel"
                          [(ngModel)]="proveedor.compania_contratante" tooltip='Elija la(s) compañía(s) a la(s) cual(es) le prestará servicios' required>
                          <option value="12" >Brm</option>
                          <option value="13">Articulacion</option>
                          <option value="14">Deeploy</option>
                          <option value="15">Heart</option>
                          <option value="16">Giving</option>
                      </select>
                      <span *ngIf="compania_contratante.invalid && compania_contratante.touched">Falta el campo requerido:Compañía Contratante. </span>
                   </div>
                   </div>-->

          <section>
            <div class="form-row">
              <div class="form-group col-md-12">
                <h5>INFORMACIÓN CONTACTO</h5>
                <hr>
                <br>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-4" *ngIf="procedimiento!='creacion_rapida'">
                <label class="bmd-label-floating" class="text-center"><strong>Contacto
                    pagaduría-tesorería*</strong><br></label>
                <input id="contacto_pagaduria" name="contacto_pagaduria" class="form-control" type="text"
                  #contacto_pagaduria="ngModel" [(ngModel)]="clientes.contacto_pagaduria"
                  pattern="[A-Za-z,.'-, ' ',0-9,-]{4,64}"
                  tooltip='Con que funcionario debemos contactarnos en su compañía'
                  required="{{contacto_pagaduria_req}}">
                <span *ngIf="!contacto_pagaduria.valid && contacto_pagaduria.touched">Falta el campo requerido:Contacto
                  pagaduría-tesorería </span>
              </div>


              <div class="form-group col-md-4" *ngIf="procedimiento!='creacion_rapida'">
                <label class="bmd-label-floating" class="text-center"><strong>Correo electrónico - Contacto Pagaduria -
                    Tesoreria*</strong><br></label>
                <input id="email_contacto_pagaduria" name="email_contacto_pagaduria" #email_contacto_pagaduria="ngModel"
                  [(ngModel)]="clientes.email_contacto_pagaduria" class="form-control" type="email"
                  pattern="^[_A-Za-z0-9-]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9-]+)*(.[A-Za-z]{2,4})$"
                  tooltip='Se refiere al Email del Contacto Comercial' required="{{email_contacto_pagaduria}}">
                <span *ngIf="email_contacto_pagaduria.invalid && email_contacto_pagaduria.touched">El Campo Email es
                  requerido.</span>
              </div>




              <div class="form-group col-md-4">
                <label class="text-center"><strong>Teléfono Contacto Pagaduría - Tesorería*</strong><br></label><br>
                <input id="phone_pagaduria" name="phone_opagaduria" class="form-control" type="tel" ng2TelInput
                  (countryChange)="onCountryChange($event)" #phone_pagaduria="ngModel"
                  [(ngModel)]="clientes.phone_pagaduria" pattern="[A-Za-z,.'-, ' ',0-9,-]{4,20}"
                  tooltip='Hace referencia al contacto comercial' required="{{phone_pagaduria_req}}"><br>
                <span *ngIf="!phone_pagaduria.valid && phone_pagaduria.touched">Formato de telefono Invalido.</span>
              </div>
            </div>
            <div class="form-row">

              <div class="form-group col-md-4">
                <label class="bmd-label-floating" class="text-center"><strong>Contacto Contabilidad -
                    Impuestos*</strong><br></label>
                <input id="contacto_contabilidad" name="contacto_contabilidad" class="form-control" type="text"
                  #contacto_contabilidad="ngModel" [(ngModel)]="clientes.contacto_contabilidad"
                  pattern="[A-Za-z,.'-, ' ',0-9,-]{4,64}"
                  tooltip='Cargo o nombre de persona a quien se le podrá solicitar información'
                  required="{{contacto_contabilidad_req}}">
                <span *ngIf="!contacto_contabilidad.valid && contacto_contabilidad.touched">Falta el campo
                  requerido:Contacto pagaduría-tesorería </span>
              </div>
              <div class="form-group col-md-4">
                <label class="bmd-label-floating" class="text-center"><strong>Correo electrónico - Contacto Contabilidad
                    - Impuestos*</strong><br></label>
                <input id="email_contacto_contabilidad" name="email_contacto_contabilidad"
                  #email_contacto_contabilidad="ngModel" [(ngModel)]="clientes.email_contacto_contabilidad"
                  class="form-control" type="email"
                  pattern="^[_A-Za-z0-9-]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9-]+)*(.[A-Za-z]{2,4})$"
                  tooltip='Se refiere al Email del Contacto Comercial' required="{{email_contacto_contabilidad_req}}">
                <span *ngIf="email_contacto_contabilidad.invalid && email_contacto_contabilidad.touched">El Campo Email
                  es requerido.</span>
              </div>


              <div class="form-group col-md-4">
                <label class="text-center"><strong>Teléfono Contacto Contabilidad - Impuestos.
                    Fecha*</strong><br></label><br>
                <input id="phone_contabilidad" name="phone_contabilidad" class="form-control" type="tel" minlength='4'
                  ng2TelInput (countryChange)="onCountryChange_3($event)" #phone_contabilidad="ngModel"
                  [(ngModel)]="clientes.phone_contabilidad" pattern="[A-Za-z,.'-, ' ',0-9,-]{4,20}"
                  tooltip='Hace referencia al contacto de Contabilidad' required="{{phone_contabilidad_req}}"><br>
                <span *ngIf="!phone_contabilidad.valid && phone_contabilidad.touched">Formato de telefono
                  Invalido.</span>
              </div>

            </div>
          </section>

          <section>
            <div class="form-row">
              <div class="form-group col-md-12">
                <h5>INFORMACIÓN TRIBUTARIA:</h5>
                <hr>
                <br>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label class="text-center"><strong>Fecha limite recepción de facturas (día):*</strong><br></label>
                <input id="closed_reception_invoices_c" name="closed_reception_invoices_c" class="form-control"
                  type="text" #closed_reception_invoices_c="ngModel" [(ngModel)]="clientes.closed_reception_invoices_c"
                  required="{{closed_reception_invoices_c_req}}">
                <span *ngIf="!closed_reception_invoices_c.valid && closed_reception_invoices_c.touched">El Campo: fecha
                  limite recepcion facturas(dia) es requerido.</span>
              </div>

              <div class="form-group col-md-4">
                <label class="bmd-label-floating" class="text-center"><strong>Correo electrónico - para envío de
                    facturación electrónica*</strong><br></label>
                <input id="email_facturacion_electronica" name="email_facturacion_electronica"
                  #email_facturacion_electronica="ngModel" [(ngModel)]="clientes.email_facturacion_electronica"
                  class="form-control" type="email"
                  pattern="^[_A-Za-z0-9-]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9-]+)*(.[A-Za-z]{2,4})$"
                  tooltip='Se refiere al Email para recibir la facturación electrónica'
                  required="{{email_facturacion_electronica_req}}">
                <span *ngIf="!email_facturacion_electronica.valid && email_facturacion_electronica.touched">El Campo
                  Email es requerido.</span>
              </div>

              <div class="form-group col-md-4">
                <label class="bmd-label-floating" class="text-center"><strong>REQUIERE ORDEN DE
                    COMPRA?*</strong><br></label>
                <select class="form-control" id="oc_factura" name="oc_factura" [(ngModel)]="clientes.oc_factura">
                  <option value="{{requiere.value}}" *ngFor="let requiere of oc_factura">{{requiere.nombre}}</option>
                </select>
              </div>

              <div class="form-group col-md-4">
                <label class="bmd-label-floating" class="text-center"><strong>Tipo de Persona*</strong><br></label>
                <select id="person_type_c" name="person_type_c" class="form-control" #person_type_c="ngModel"
                  [(ngModel)]="clientes.person_type_c" required="{{person_type_c_req}}">
                  <option value="{{tipo_persona.id}}" *ngFor="let tipo_persona of tipo_personas">{{tipo_persona.nombre}}
                  </option>
                </select>
                <span *ngIf="!person_type_c.valid && person_type_c.touched">El Campo: Tipo de Persona es
                  requerido.</span>
              </div>

              <div class="form-group col-md-4">
                <label class="bmd-label-floating" class="text-center"><strong>Régimen*</strong><br></label>
                <select id="regime_type_c" name="regime_type_c" class="form-control" #regime_type_c="ngModel"
                  [(ngModel)]="clientes.regime_type_c" required="{{regime_type_c_req}}">
                  <option value="">- Ninguno -</option>
                  <option value="{{regimen.id}}" *ngFor="let regimen of regimens">{{regimen.nombre}}</option>
                </select>
                <span *ngIf="!regime_type_c.valid && regime_type_c.touched">El Campo: Régimen es requerido.</span>
              </div>



              <div class="form-group col-md-4">
                <label class="bmd-label-floating" class="text-center"><strong>Gran Contribuyente*</strong><br></label>
                <select id="gran_contribuyente" name="gran_contribuyente" class="form-control"
                  #gran_contribuyente="ngModel" [(ngModel)]="clientes.gran_contribuyente"
                  required="{{gran_contribuyente_req}}">
                  <option value="{{contribuyente.id}}" *ngFor="let contribuyente of contribuyentes">
                    {{contribuyente.nombre}}</option>
                </select>
                <span *ngIf="gran_contribuyente.invalid && gran_contribuyente.touched">El Campo: Gran Contribuyente es
                  requerido</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label><strong>GRAN CONTRIBUYENTE - ICA*</strong><br></label>
                <select id="gran_contribuyente_ica" name="gran_contribuyente_ica" class="form-control"
                  #gran_contribuyente_ica="ngModel" [(ngModel)]="clientes.gran_contribuyente_ica"
                  required="{{gran_contribuyente_ica_req}}">
                  <option value="{{contribuyente.id}}" *ngFor="let contribuyente of contribuyentes">
                    {{contribuyente.nombre}}</option>
                </select>
                <span *ngIf="gran_contribuyente_ica.invalid && gran_contribuyente_ica.touched">El Campo:GRAN
                  CONTRIBUYENTE-ICA es requerido</span>
              </div>



              <div class="form-group col-md-4">
                <label class="text-center"><strong>AUTORETENEDOR - RENTA*</strong><br></label>
                <select id="autoholder_revenue_c" name="autoholder_revenue_c" class="form-control"
                  #autoholder_revenue_c="ngModel" [(ngModel)]="clientes.autoholder_revenue_c"
                  required="{{autoholder_revenue_c_req}}">
                  <option value="{{autoretenedor.id}}" *ngFor="let autoretenedor of autoretenedores">
                    {{autoretenedor.nombre}}</option>
                </select>
                <span *ngIf="autoholder_revenue_c.invalid && autoholder_revenue_c.touched">El Campo: AUTORETENEDOR -
                  RENTA es requerido</span>
              </div>
              <div class="form-group col-md-4">
                <label><strong>RETENEDOR - IVA_*</strong><br></label>
                <select id="autoholder_iva_c" name="autoholder_iva_c" class="form-control" #autoholder_iva_c="ngModel"
                  [(ngModel)]="clientes.autoholder_iva_c" required="{{autoholder_iva_c_req}}">
                  <option value="{{autoretenedor.id}}" *ngFor="let autoretenedor of autoretenedores">
                    {{autoretenedor.nombre}}</option>
                </select>
                <span *ngIf="autoholder_iva_c.invalid && autoholder_iva_c.touched">El Campo: RESPONSABLE - IVA es
                  requerido</span>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label><strong>COPIA DEL RUT / CERTIFICADO DE INCORPORACIÓN*</strong><br></label><br>
                <div class="">
                  <input type="file" name="file1" id="file1" (change)="documentRUT($event)" accept=".doc,.docx,.pdf"
                    *ngIf="!loader_crt_rut" class="inputfile" />
                  <div *ngIf="loader_crt_rut">
                    <img src="../../assets/img/30.gif" />
                  </div>
                  <label for="file1" class="decorator"
                    tooltip='Con fecha de expedición posterior al primero de enero de 2.013. Formato PDF'
                    placement='right' #rut>{{msn_crt_rut}}</label>
                </div>
                <br>
                <br>
                <input id="rut_doc" name="rut_doc" class="form-control" type="hidden" #rut_doc="ngModel"
                  value="{{doc_rut}}" [(ngModel)]="clientes.rut_doc">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label><strong>CAMARA DE COMERCIO*</strong><br></label><br>
                <div class="">
                  <input type="file" name="file2" id="file2" (change)="documentCamaraComercio($event)"
                    accept=".doc,.docx,.pdf" *ngIf="!loader_crt_camara" class="inputfile" />
                  <div *ngIf="loader_crt_camara">
                    <img src="../../assets/img/30.gif" />
                  </div>
                  <label for="file2" class="decorator" tooltip='Con fecha de expedición No mayor a 30 dias. Formato PDF'
                    placement='right' #camara>{{msn_crt_camara}}</label>
                </div>
                <br>
                <br>
                <input id="camara_comercio_doc" name="camara_comercio_doc" class="form-control" type="hidden"
                  #camara_comercio_doc="ngModel" value="{{doc_camara_comercio}}"
                  [(ngModel)]="clientes.camara_comercio_doc">
              </div>
            </div>
          </section>



          <div [formGroup]="registerPartner">
            <div *ngIf="mostrar_labels_socios">
            <h4 class="col-md-12 text-center">Socio / Beneficiario Final</h4>
            <hr>
            <p class="text-justify">Por favor registrar la información de los BENEFICIARIOS FINALES de la entidad con participación igual o mayor al 5% (Si no los registra en este espacio, debe adjuntar una certificación con la lista que contenga nombre, tipo y número de identificación y participación (%) participación, suscrita por el Representante Legal)
            </p>
            <p   *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="text-justify"><strong class="circulo">•</strong>Personas Expuestas Políticamente
              De conformidad con las leyes Colombianas (Persona que ocupa uno de los cargos públicos establecidos en  Decreto 1674 de 2016).En caso de aplicar por favor marcar la casilla correspondiente.
            </p>
           <hr style="background-color: #dcdcdd">
            <div class="form-row">
            <div class="form-group col" *ngFor="let nombre of nombresLabels">
              <label class="bmd-label-floating text-align: center;" ><strong>{{nombre.nombres}}</strong><br></label>
            </div>
            <div  *ngIf="codigo_pais!='MX' && empresa!='brmmx'"  class="form-group col" >
              <label class="bmd-label-floating  text-align: center;"><strong class="circulo">•</strong><strong>PEP</strong><br></label>
          </div>
            </div>
            </div>
            <div formArrayName="socios" *ngFor="let socio of socios.controls.slice(0, 5); let i = index">
              <div class="form-row" >
                <div class="form-group col" *ngIf="i>0">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="removerSocio(i)">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div class="form-row" [formGroupName]="i">
                <div class="form-group col">
                  <select class="form-control" formControlName="tipo_identificacion"  (change)="socioTipoId($event,i)">
                    <option value="{{tipo_identificacion.id}}"
                      *ngFor="let tipo_identificacion of tipo_identificacion_persona">{{tipo_identificacion.nombre}}
                    </option>
                  </select>
                </div>
                <div class="form-group col">
                  <input class="form-control" type="text" formControlName="numero_identificacion" tooltip='Ingrese Numero de Identificacion sin caracteres especiales.' (keypress)="validarNumeroIdentificacion($event)">
                </div>
                <div class="form-group col">
                  <input class="form-control" type="text" formControlName="primer_nombre">
                </div>
                <div class="form-group col">
                  <input class="form-control" type="text" formControlName="segundo_nombre"  >
                </div>
                <div class="form-group col">
                  <input class="form-control" type="text" formControlName="primer_apellido" >
                </div>
                <div class="form-group col">
                  <input class="form-control" type="text" formControlName="segundo_apellido">
                </div>

                <div  *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="form-group col">
                  <div class="form-check text-align: center;">
                    <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    formControlName="pep"
                    >
                  </div>
                </div>
              </div>
              <hr  style="background-color: #dcdcdd">
            </div>
            <div class="form-row" *ngIf="boton_agregar_socio">
              <div class="adicionar-socio" class="col-md-3">
                  <button class="btn btn-danger active text-white border rounded" value="agregaSocio" (click)="agregarSocio(1)" type="button">Agregar
                    </button>
              </div>
              <!--<div class="guardar-socio" class="col-md-3">
                  <button class="btn btn-success active text-white border rounded" value="guardaSocio" (click)="guardarSocio()" type="button">Guardar Socio
                    </button>
              </div>-->
            </div>
          </div>
          <hr>

          <div class="container-fluid" *ngIf="mostrar_parrafo">
            <p class="text-center"> <strong>DECLARACIÓN</strong></p>
            <p class="text-justify"> En calidad de Representante Legal y/o apoderado y/o autorizado de la Sociedad
              arriba suscrita,
               declaro y manifiesto expresamente que toda la información consignada en el
              presente
              registro es veraz, cierta y autentica. Igualmente, declaro:<br></p>
            <p class="text-justify"> <strong>• RESPECTO A POTENCIALES CONFLICTOS DE INTERÉS.
              </strong></p>
            <p class="text-justify">Declaro que ningún empleado, directivo, miembro de junta directiva o socio de la
              sociedad a
              quien represento, ni sostienen vínculo alguno de cónyuge o compañero permanente, familiar hasta el cuarto
              grado de
              consanguinidad, segundo de afinidad y primero civil con el personal administrativo y directivo de
              {{texto_empresa}}.; ni
              ninguno de ellos dará, ofrecerá o prometerá, directa o indirectamente, ninguna dádiva, dinero, beneficio o
              ventaja,
              a los empleados, asesores o directivos de {{texto_empresa}}. De igual forma, en caso de configurarse una
              situación de
              conflicto de intereses, conforme con las declaraciones aquí expuestas, {{texto_empresa}}. se encuentra en
              la facultad de dar
              por terminada la relación comercial y/o contrato, sin que se cause derecho de indemnización alguna en
              favor del
              cliente y/o proveedor, y en todo caso, en el evento de presentarse alguna de las situaciones mencionadas
              anteriormente, me comprometo a informar por escrito de manera detallada el conflicto de interés, mediante
              carta
              dirigida al representante legal de {{texto_empresa}}.
              <br>
            </p>
            <p class="text-justify"> <strong>• RESPECTO AL ORIGEN DE FONDOS.

              </strong></p>
            <p class="text-justify">Declaro igualmente, bajo la gravedad del juramento que los ingresos de la sociedad
              provienen
              de actividades lícitas, que ni la sociedad, ni los socios o directivos se encuentran con registro negativo
              en
              listados de prevención de lavado de activos nacionales o internacionales, ni tampoco se encuentran dentro
              de una de
              las dos categorías de lavado de activos (conversión o movimiento) y que en consecuencia, mantendrán
              indemne a {{texto_empresa}}.
              frente a cualquier controversia, conflicto, perjuicio o requerimiento que ocurriese por origen de los
              fondos de
              los mencionados. Será causa de terminación anticipada de cualquier contrato, convenio, acuerdo que exista
              con {{texto_empresa}}.
              , sin el reconocimiento o pago de penas a favor de la contraparte, el engaño, la información falsa o
              presentación
              de documentos falsificados que no permitan conocer la vinculación de la sociedad con actividades ilícitas
              y/o
              financiación terrorismo. De igual forma, aplicara lo mismo, si la sociedad, sus filiales, subordinadas,
              socios o sus
              representantes legales fueren investigados o condenados mediante sentencia por un juez por cualquier
              delito
              vinculado con la producción, tenencia, tráfico, proselitismo y otros actos en materia de narcóticos,
              encubrimiento
              y/u operaciones con recursos de procedencia ilícita, terrorismo y/o delincuencia organizada en el
              territorio
              nacional o en cualquier país del mundo, con el que Colombia tenga firmado tratados internacionales
              referentes a lo
              establecido en el presente párrafo o bien es mencionado en las Listas OFAC (Office Foreign Assets Control)
              o
              cualquier otra lista de naturaleza similar. Igualmente, en calidad de Representante Legal de la Sociedad
              arriba
              descrita, autorizo de manera expresa a {{texto_empresa}}. a consignar los recursos por los servicios que
              prestamos, conforme a
              la información suministrada.

              <br>
            </p>
            <p class="text-justify"> <strong>• RESPECTO AL TRATAMIENTO DE DATOS PERSONALES POR PARTE DE
                {{texto_empresa}}.

              </strong></p>
            <p class="text-justify">Declaro y manifiesto que: De conformidad y en cumplimiento de la Ley 1581 de 2012 y
              el Decreto
              1377 del 2013; he sido informado sobre las medidas de seguridad implementadas para garantizar la
              conservación y
              confidencialidad sobre los datos personales de la Sociedad, de mi como Representante Legal y como persona
              natural y
              sobre los derechos que como titular de los mismos puedo ejercer: Los derechos de acceso, rectificación,
              prueba de
              autorización, reserva de responder preguntas sobre datos sensibles o información de menor de edad,
              oposición y
              supresión (este último cuando no medie un deber legal o contractual que lo impida), en la forma
              establecida en la
              Política de Tratamiento de Datos Personales de {{texto_empresa}}., disponible a solicitud del interesado
              en la página web de
              {{texto_empresa}}. De esta manera, autorizo de forma previa, consciente, expresa e informada a la
              {{texto_empresa}}. en calidad de
              Responsable y/o Encargado del tratamiento la información mencionada, en virtud de la relación comercial
              existente,
              para que utilice los datos personales recopilados en una base de datos de su titularidad, con las
              siguientes
              finalidades: 1. Gestión contable, fiscal y administrativa; 2. Registro como cliente y/o proveedor, así
              como su
              evaluación; 3. Actualización de datos en los sistemas de información de la Compañía Contratante; 4.
              Análisis del
              riesgo financiero y operativo; 5. Dar cumplimiento y seguimiento a las obligaciones contraídas por el
              proveedor y/o
              el cliente; 6. Consultar en cualquier momento en cualquier entidad financiera, los datos y toda la
              información
              relevante para la presente vinculación, conocer su desempeño como deudor y su capacidad de pago, valorar
              el riesgo
              futuro y verificar el cumplimiento de sus deberes; 7. Reportar los datos de la Sociedad en las entidades
              financieras
              y de riesgos, directamente o por intermedio de las autoridades de vigilancia y control, datos tratados o
              sin tratar,
              referidos a la información mencionada anteriormente; 8. Gestionar el cobro de las obligaciones
              comerciales,
              económicas, financieras adquiridas por parte de la Sociedad: 9. Todas las demás finalidades que
              correspondan para el
              desarrollo de la relación comercial y aquellas indicadas en la Política de Tratamiento de Datos Personales
              disponible en el siguiente link:<a href="{{enlace}}" target="blank"> Manual tratamiento de datos.</a>

              <br>
            </p>


          </div><br>
          <!-- <div class="form-row">

                         <div class="form-group col-md-12"><label class="bmd-label-floating" class="text-center"><strong>Conflicto de Interés*</strong><br></label>
                              <textarea id="conflicto_interes"
                                  name="conflicto_interes"
                                  class="form-control form-control-lg col-md-12"
                                  #conflicto_interes="ngModel"
                                  [(ngModel)]="clientes.conflicto_interes" tooltip='Aquí podrá indicar que conflicto ha identificado'></textarea>
                          </div>

                      </div>-->

                      <hr>
                      <div class="form-row">
                      <div class="form-group col-md-2">
                        <label class="text-center"><strong>CALIDAD DE QUIEN REALIZA EL REGISTRO*</strong><br></label>
                        <select id="quien_realiza_registro" name="quien_realiza_registro" class="form-control"
                          #quien_realiza_registro="ngModel" [(ngModel)]="clientes.quien_realiza_registro"
                          required="{{quien_realiza_registro_req}}">
                          <option value="Representante legal">Representante Legal</option>
                          <option value="Delegado representante legal">Delegado Representante Legal</option>
                          <!--<option value="{{autoretenedor.value}}" *ngFor="let autoretenedor of quien_realiza_registro">{{autoretenedor.nombre}}</option>-->
                        </select>
                        <!-- <span *ngIf="quien_realiza_registro.value==='Delegado Representante Legal'">El Campo: AUTORETENEDOR - RENTA es requerido</span>-->
                      </div>
                      <div class="form-group col-md-2" *ngIf="clientes.quien_realiza_registro ==='Delegado representante legal'">
                        <label class="bmd-label-floating" class="text-center"><strong>Correo Electronico Representante
                            Legal*</strong><br></label>
                        <input id="email_representante_legal" name="email_representante_legal" class="form-control" type="text"
                          #email_representante_legal="ngModel" [(ngModel)]="clientes.email_representante_legal"
                          pattern="^[_A-Za-z0-9-]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9-]+)*(.[A-Za-z]{2,4})$"
                          tooltip='Se refiere al Email del Representante Legal' required>
                        <span *ngIf="!email_representante_legal.valid && email_representante_legal.touched">El Campo: Correo
                          Electronico Representante Legal es requerido</span>
                      </div>
                    </div>
                    <hr>

                <div class="form-row">
                    <h6 style="color:rgb(148, 17, 17)">DATOS DE LA PERSONA NATURAL O REPRESENTANTE LEGAL DE LA SOCIEDAD</h6>
                    <p *ngIf="codigo_pais!='MX' && empresa!='brmmx'"  class="text-justify"><strong class="circulo">•</strong>Personas Expuestas Políticamente
                      De conformidad con las leyes Colombianas (Persona que ocupa uno de los cargos públicos establecidos en  Decreto 1674 de 2016).En caso de aplicar por favor marcar la casilla correspondiente.
                    </p>
                </div>
                <br>
          <div class="form-row">
            <div class="form-group col-md-2">
              <label class="bmd-label-floating" class="text-center"><strong>PRIMER NOMBRE*</strong><br></label>
              <input id="primer_nombre_rl" name="primer_nombre_rl" class="form-control" type="text"
                #primer_nombre_rl="ngModel" [(ngModel)]="clientes.primer_nombre_rl" tooltip='Primer Nombre'
                required="{{primer_nombre_rl_req}}">
              <span *ngIf="!primer_nombre_rl.valid && primer_nombre_rl.touched">El Campo: NOMBRES Y APELLIDOS es
                requerido</span>
            </div>
            <div class="form-group col-md-2">
              <label class="bmd-label-floating" class="text-center"><strong>SEGUNDO NOMBRE</strong><br></label>
              <input id="segundo_nombre_rl" name="segundo_nombre_rl" class="form-control" type="text"
                #segundo_nombre_rl="ngModel" [(ngModel)]="clientes.segundo_nombre_rl" tooltip='Segundo Nombre'
                required="{{segundo_nombre_rl_req}}">
              <span *ngIf="!segundo_nombre_rl.valid && segundo_nombre_rl.touched">El Campo: SEGUNDO NOMBRE es
                requerido</span>
            </div>
            <div class="form-group col-md-2">
              <label class="bmd-label-floating" class="text-center"><strong>PRIMER APELLIDO*</strong><br></label>
              <input id="primer_apellido_rl" name="primer_apellido_rl" class="form-control" type="text"
                #primer_apellido_rl="ngModel" [(ngModel)]="clientes.primer_apellido_rl" tooltip='Primer Apellido'
                required="{{primer_apellido_rl_req}}">
              <span *ngIf="!primer_apellido_rl.valid && primer_apellido_rl.touched">El Campo: PRIMER APELLIDO es
                requerido</span>
            </div>
            <div class="form-group col-md-2">
              <label class="bmd-label-floating" class="text-center"><strong>SEGUNDO APELLIDO*</strong><br></label>
              <input id="segundo_apellido_rl" name="segundo_apellido_rl" class="form-control" type="text"
                #segundo_apellido_rl="ngModel" [(ngModel)]="clientes.segundo_apellido_rl" tooltip='Segundo Apellido'
                required="{{segundo_apellido_rl_req}}">
              <span *ngIf="!segundo_apellido_rl.valid && segundo_apellido_rl.touched">El Campo: SEGUNDO APELLIDO es
                requerido</span>
            </div>


            <div class="form-group col-md-2"><label class="bmd-label-floating" class="text-center"><strong>Tipo de
                  Identificación*</strong></label>
              <select id="tipo_identificacion" name="tipo_identificacion" class="form-control"
                #tipo_identificacion="ngModel" [(ngModel)]="clientes.tipo_identificacion"
                required="{{tipo_identificacion_req}}">
                <option value="{{tipo_identificacion.id}}"
                  *ngFor="let tipo_identificacion of tipo_identificacion_persona">{{tipo_identificacion.nombre}}
                </option>
              </select>
              <span *ngIf="tipo_identificacion.invalid && tipo_identificacion.touched">El Campo: Tipo de Identificación
                es requerido</span>
            </div>


            <!--tipo_identificación_rl-->
            <div class="form-group col-md-2">
              <label class="bmd-label-floating" class="text-center"><strong>Numero Identificación*
                  (Personal)*</strong><br></label>
              <input id="numero_identificacion" name="numero_identificacion" class="form-control" type="text"
                #numero_identificacion="ngModel" [(ngModel)]="clientes.numero_identificacion"
                required="{{numero_identificacion_req}}" [pattern]="pattern_numero_identificacion"
                tooltip='Corresponde al numero de identificacion.' (keypress)="validarNumeroIdentificacion($event)">
              <span *ngIf="numero_identificacion.invalid && numero_identificacion.touched">El Campo: Numero
                Identificación (Personal) es requerido</span>
            </div>

            <div *ngIf="codigo_pais!='MX' && empresa!='brmmx'" class="form-group col-md-2 text-center" >
              <label class="bmd-label-floating" ><strong class="circulo">•</strong><strong>PEP
               </strong><br></label>
               <br>
               <br>
              <div class="form-check text-center">
                <input
                id="pep_rl"
                name="pep_rl"
                class="form-control"
                class="form-check-input"
                type="checkbox"
                #pep_rl="ngModel"
                [(ngModel)]="clientes.pep_rl"
                value=""
                tooltip='Por favor indique si es una Persona Políticamente expuesta (PEP)'
                id="defaultCheck1">
              </div>
              </div>


                <div class="form-group col-md-6">
                  <label><strong>{{label_doc_rl}}</strong><br></label><br>
                  <div class="">
                    <input type="file" name="file3" id="file3" (change)="documentRepresentanteLegal($event)"
                      accept=".doc,.docx,.pdf" *ngIf="!loader_crt_rl" class="inputfile" />
                    <div *ngIf="loader_crt_rl">
                      <img src="../../assets/img/30.gif"/>
                    </div>
                    <label for="file3" class="decorator" tooltip='Documento de identificacion Representante Legal. Formato PDF'
                      placement='right' #representante>{{msn_crt_rl}}</label>
                  </div>
                  <br>
                  <br>
                  <input id="representante_legal_doc" name="representante_legal_doc" class="form-control" type="hidden"
                    #representante_legal_doc="ngModel" value="{{doc_representante_legal}}"
                    [(ngModel)]="clientes.representante_legal_doc">
                </div>



          </div>

                  <div class="form-row">
                        <div class="col-md-3" style="display: flex;align-items: center;">
                          <p>Desempeña y/o ha desempeñado un cargo publico <i>(Have you ever held a public position)</i></p>
                        </div>
                        <div class="col-md-2 text-center">
                          <table width="100%" cellpadding="0" cellspacing="0" border="0">
                            <tr>
                              <td><p style="font-size: 1rem;padding-top: 11px;">Si (Yes)</p></td>
                              <td>
                                <input
                                    id="cargo_publico"
                                    name="cargo_publico"
                                    type="checkbox"
                                    [(ngModel)]="cargo_publico_check_si"
                                    (change)="onChangeCargoPublico($event.target.value, $event.target.checked)"
                                    value="si">
                              </td>
                            </tr>
                            <tr>
                              <td><p style="font-size: 1rem;padding-top: 11px;">No</p></td>
                              <td>
                                <input
                                    id="cargo_publico_no"
                                    name="cargo_publico_no"
                                    type="checkbox"
                                    [(ngModel)]="cargo_publico_check_no"
                                    (change)="onChangeCargoPublico($event.target.value, $event.target.checked)"
                                    value="no">
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div class="col-md-6">
                          <p style="padding-left: 11px;">Si la respuesta es <strong>SI</strong>, indicar cargo y la fecha desde la cual lo desempeña o la fecha desde la cual dejó de desempeñarlo <i>(if you answered YES, indicate the time periodo in which you held the position)</i></p>
                          <div class="col-md-12" style="display: flex;" *ngIf="clientes.ejerce_cargo_publico_rl == 'si'">
                            <div class="col-md-6">
                                <label class="text-center">Cargo</label>
                                <input id="cargo_publico_rl"
                                    name="cargo_publico_rl"
                                    class="form-control"
                                    type="text"
                                    maxlength='50'
                                    #cargo_publico_rl="ngModel"
                                    [(ngModel)]="clientes.cargo_publico_rl">
                            </div>
                            <div class="col-md-6">
                                <label class="text-center">Fecha Desempeño</label>
                                <input id="fecha_cargo_publico_rl"
                                    name="fecha_cargo_publico_rl"
                                    class="form-control"
                                    type="date"
                                    #fecha_cargo_publico_rl="ngModel"
                                    [(ngModel)]="clientes.fecha_cargo_publico_rl">
                            </div>
                          </div>
                        </div>
                  </div>
                  <div class="form-row" style="padding-top: 20px;">
                        <div class="col-md-3" style="display: flex;align-items: center;">
                          <p>Maneja y/o ha manejado recursos publicos <i>(Have you ever managed or do you currently manage public founds?)</i></p>
                        </div>
                        <div class="col-md-2 text-center">
                          <table width="100%" cellpadding="0" cellspacing="0" border="0">
                            <tr>
                              <td><p style="font-size: 1rem;padding-top: 11px;">Si (Yes)</p></td>
                              <td>
                                <input
                                    id="maneja_recurso_publico_si"
                                    name="maneja_recurso_publico_si"
                                    type="checkbox"
                                    [(ngModel)]="maneja_recurso_publico_check_si"
                                    (change)="onChangeManejaRecursoPublico($event.target.value, $event.target.checked)"
                                    value="si">
                              </td>
                            </tr>
                            <tr>
                              <td><p style="font-size: 1rem;padding-top: 10px;">No</p></td>
                              <td>
                                <input
                                    id="maneja_recurso_publico_no"
                                    name="maneja_recurso_publico_no"
                                    type="checkbox"
                                    [(ngModel)]="maneja_recurso_publico_check_no"
                                    (change)="onChangeManejaRecursoPublico($event.target.value, $event.target.checked)"
                                    value="no">
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div class="col-md-6">
                          <p style="padding-left: 15px;">Si la respuesta es <strong>SI</strong>, indicar cargo desde el cual maneja los recursos publicos y la fecha desde la cual lo desempeña o la fecha desde la cual dejo de desempeñar el cargo y manejar los recursos publicos <i>(If the you answered YES, indicate from which position you manage(d) the public funds).</i></p>
                          <div class="col-md-12" style="display: flex;" *ngIf="clientes.maneja_recurso_publico_rl == 'si'">
                            <div class="col-md-6">
                                <label class="text-center">Cargo</label>
                                <input id="cargo_recurso_publico_rl"
                                    name="cargo_recurso_publico_rl"
                                    class="form-control"
                                    type="text"
                                    maxlength='50'
                                    #cargo_recurso_publico_rl="ngModel"
                                    [(ngModel)]="clientes.cargo_recurso_publico_rl">
                            </div>
                            <div class="col-md-6">
                                <label class="text-center">Fecha Desempeño</label>
                                <input id="fecha_recurso_publico_rl"
                                    name="fecha_recurso_publico_rl"
                                    class="form-control"
                                    type="date"
                                    #fecha_recurso_publico_rl="ngModel"
                                    [(ngModel)]="clientes.fecha_recurso_publico_rl">
                            </div>
                          </div>
                        </div>
                  </div>
                  <br>
                  <br>

          <div class="form-row" *ngIf="codigo_pais=='CO'">
            <div *ngIf='mostrarcampos==true'>
              <h6>Faltan los campos requeridos:</h6>
              <ul *ngFor="let item of prueba">
                <li class="validacion">{{item}}</li>
              </ul>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-2">
              <div *ngIf="!success" class="col-md-2">
                <button class="btn btn-danger active text-white border rounded" value="clienteForm" type="button"
                  (click)="camposRequeridos(1,1)">Envíar</button>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div *ngIf="!success" class="col-md-3">
                <button class="btn btn-danger active text-white border rounded" value="clienteForm"
                  (click)="guardarBorrador()" type="button">Guardar Borrador
                </button>
              </div>
            </div>
          </div>

          <div class="alert alert-success" role="alert" *ngIf="success">
            <h4 class="alert-heading">Registro Exitoso</h4>
            <p>Su informacion ya fue almacenada, si necesitamos mas informacion adicional nos estaremos comunicando
              posteriormente</p>
          </div>


          <!--*[disabled]="providerForm.invalid"*/-->

        </div>
      </form>
    </div>
  </div>
  <ng-template #modalSocios>
        <div class="modal-header">
          <h4 class="modal-title pull-left">{{titulo_modal_socios}}</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalSocios()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p [innerHTML]="texto_modal_socios"></p>
        </div>
      </ng-template>
